import * as yup from "yup";

interface ApiEducationalTrack {
  id: string;
  name: string;
  description?: string;
  sort: number;
  visible: boolean;
  educational_program: string;
  can_be_deleted: boolean;
}

export const ApiTrackConfigFormSchema = yup.object().shape({
  name: yup.string().required(),
});

export const ApiTrackConfigPartialSchema = yup.object().shape({
  name: yup.string().required(),
  visible: yup.boolean().required(),
  educational_program: yup.string().required(),
});

export default ApiEducationalTrack;