import AxiosApiInstance, {APIBase} from "../../../../AxiosApiInstance";
import ApiNotificationsConfig from "./ApiNotificationsConfig";
import {AxiosResponse} from "axios";

// это апи-метод по новому типу
// вместо обязательных параметров он выбрасывает ошибку, если чего-то не хватает

const ApiBackCampaignsConfigsNotificationsIdPatch
  = (payload: ApiNotificationsConfig)
  :Promise<AxiosResponse<ApiNotificationsConfig>> => {
    if (!payload.id) throw new Error("ApiBackCampaignsConfigsNotificationsIdPatch: no-id error");

    return AxiosApiInstance.patch(`${APIBase}back/campaigns/${payload.campaign}/configs/notifications/${payload.id}/`, payload);
  };

export default ApiBackCampaignsConfigsNotificationsIdPatch;