import React from "react";
import {Form} from "react-bootstrap";
import ReactHtmlParser from "react-html-parser";

const SkFieldDescriptionBlock:React.FC<{
  description?: string;
  isInvalid: boolean;
  errorText?: string;
}> = ({
  errorText,
  isInvalid,
  description,
}) => <>
  {description && !isInvalid && <Form.Text className="text-muted">
    {ReactHtmlParser(description)}
  </Form.Text>}
  {isInvalid && errorText && <Form.Text className="text-danger">
    {errorText}
  </Form.Text>}
</>;

export default SkFieldDescriptionBlock;