import ApiCampaignModel from "./ApiCampaignModel";
import AxiosApiInstance, {APIBase} from "../AxiosApiInstance";
import {AxiosResponse} from "axios";
import DefaultGetRequest from "../DefaultGetRequest";
import DefaultGetResponse from "../DefaultGetResponse";

export const ApiCampaignsGet: (params?: DefaultGetRequest) => Promise<AxiosResponse<DefaultGetResponse<ApiCampaignModel>>> = (params) => {
  return AxiosApiInstance.get(APIBase + "campaigns/", {
    params: params
  });
};