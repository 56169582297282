import ApiBackReportModel, {ApiBackReportModelSchemaPartial} from "../../../services/api/back/campaigns/reports/ApiBackReportModel";
import {computed, makeObservable, observable, runInAction} from "mobx";
import {ApiBackCampaignsReportGet} from "../../../services/api/back/campaigns/reports/ApiBackCampaignsReportGet";
import BackReportsPageStore from "../pages/BackReportsPageStore";

class ReportStore {
  constructor(private root: BackReportsPageStore, public model: ApiBackReportModel) {
    makeObservable(this, {
      model: observable,
      updating: computed,
    });
  }

  get updating() {
    return this.model.status !== "finished";
  }

  private async update() {
    if (!this.root.backAppStore.campaignId) throw Error("No campaign selected!");
    const model:ApiBackReportModel = (await ApiBackCampaignsReportGet(this.root.backAppStore.campaignId, this.model.id)).data;

    if (await ApiBackReportModelSchemaPartial.isValid(model)) {
      runInAction(() => this.model = model);
    }
  }

  async processUpdate() {
    try {
      if (this.updating) {
        await this.update();
      }
    } catch (e) {
      this.root.log.error(`Error updating report ${this.model.id}`, e);
    }
  }
}

export default ReportStore;