import AxiosApiInstance, {APIBase} from "../../../../AxiosApiInstance";
import {AxiosResponse} from "axios";
import {LimitOffsetGetRequest} from "../../../../DefaultGetRequest";
import DefaultGetResponse from "../../../../DefaultGetResponse";
import ApiNotificationsConfig from "./ApiNotificationsConfig";

const ApiBackCampaignsConfigsNotificationsGet
  = (campaign_pk: string, params?: LimitOffsetGetRequest)
  :Promise<AxiosResponse<DefaultGetResponse<ApiNotificationsConfig>>> => {
    return AxiosApiInstance.get(`${APIBase}back/campaigns/${campaign_pk}/configs/notifications/`, {
      params: params
    });
  };

export default ApiBackCampaignsConfigsNotificationsGet;