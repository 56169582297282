import React, {useEffect, useRef, useState} from "react";
import {Button, ButtonGroup, ButtonToolbar, Form, FormControlProps, InputGroup} from "react-bootstrap";
import {ArrowCounterclockwise, Check} from "react-bootstrap-icons";

interface Props extends Omit<React.HTMLProps<HTMLInputElement>, "onChange"> {
  onChange: (arg: {
    value: string;
    setValue: (val: string) => void;
  }) => void;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const FormControlClosure:React.FC<Omit<FormControlProps, "onChange"> & Props> = (
  {
    onChange,
    value,
    onFocus,
    onBlur,
    ...rest
  }) => {
  const [v, setV] = useState(value);
  const [f, setF] = useState(false);
  const ref = useRef<HTMLInputElement>();

  // refresh after reset or other side-effects
  useEffect(() => setV(value), [value]);

  const formControl = <Form.Control
    ref={ref}
    {...rest}
    value={v}
    onChange={(e) => {
      setV(e.target.value);
    }}
    // setF call a redraw SOOOO we need to do focus again in microtask to get focus and do callbacks
    onFocus={(e: React.FocusEvent<HTMLInputElement>) => {
      if (!f) {
        setF(true);
        queueMicrotask(() => ref.current?.focus());
      } else {
        onFocus && onFocus(e);
      }
    }}
    onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
      if (value === v) {
        setF(false);
      }
      onBlur && onBlur(e);
    }}
  />;

  return <>
    {!f ? (
      <>
        {formControl}
      </>
    ) : (
      <>
        <ButtonToolbar>
          <InputGroup className={"mr-3 flex-grow-1"}>
            {formControl}
          </InputGroup>
          <ButtonGroup>
            <Button
              variant={"success"}
              onClick={() => {
                ref.current?.blur();
                onChange({
                  value: `${v}`,
                  setValue: setV,
                });
                setF(false);
              }}
              onMouseDown={e => e.preventDefault()}
            >
              <Check/> Save
            </Button>
            <Button
              variant={"outline-secondary"}
              onMouseDown={e => e.preventDefault()}
              onClick={() => {
                ref.current?.blur();
                setV(`${value}`);
                setF(false);
              }}
            >
              <ArrowCounterclockwise/> Cancel
            </Button>
          </ButtonGroup>
        </ButtonToolbar>
      </>
    )}
  </>;
};

export default FormControlClosure;