import AxiosApiInstance, {APIBase} from "../../../../AxiosApiInstance";
import {AxiosResponse} from "axios";
import {LimitOffsetGetRequest} from "../../../../DefaultGetRequest";
import DefaultGetResponse from "../../../../DefaultGetResponse";
import ApiEducationalTrack from "./ApiEducationalTrack";

const ApiBackCampaignsConfigsTracksGet
  = (campaign_pk: string, params?: LimitOffsetGetRequest)
  :Promise<AxiosResponse<DefaultGetResponse<ApiEducationalTrack>>> => {
    return AxiosApiInstance.get(`${APIBase}back/campaigns/${campaign_pk}/configs/tracks/`, {
      params: params
    });
  };

export default ApiBackCampaignsConfigsTracksGet;