import axios, {AxiosResponse, CancelTokenSource} from "axios";
import DefaultGetResponse from "../../../DefaultGetResponse";
import AxiosApiInstance, {APIBase} from "../../../AxiosApiInstance";
import ApiBackReportModel from "./ApiBackReportModel";

interface ApiBackCampaignsReportsGetParams {
  limit?: number;
  offset?: number;
}

export let ApiBackCampaignsReportsGetSource: CancelTokenSource | undefined;

export const ApiBackCampaignsReportsGet: (campaign_pk: string, params?: ApiBackCampaignsReportsGetParams) => Promise<AxiosResponse<DefaultGetResponse<ApiBackReportModel>>> = (campaign_pk, params) => {
  ApiBackCampaignsReportsGetSource = axios.CancelToken.source();

  return AxiosApiInstance.get(`${APIBase}back/campaigns/${campaign_pk}/reports/`, {
    params: params
  });
};