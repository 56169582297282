import React, {HTMLProps, useState} from "react";
import {observer} from "mobx-react-lite";
import TrackConfigItemStore from "../../../stores/backoffice/configs/TrackConfigItemStore";
import {useToastsStore} from "../../../stores/singletones/ToastsStore";
import {Button, ButtonGroup, Card, Form, Modal, OverlayTrigger, Spinner, Tooltip} from "react-bootstrap";
import {runInAction} from "mobx";
import {Eye, EyeSlash, InfoCircle, Plus, Trash} from "react-bootstrap-icons";
import TracksConfigsStore from "../../../stores/backoffice/configs/TracksConfigsStore";
import FormControlClosure from "../../FormControlClosure";
import { Formik } from "formik";
import {ApiTrackConfigFormSchema} from "../../../services/api/back/campaigns/configs/tracks/ApiEducationalTrack";

const TrackSettings:React.FC<{
  item: TrackConfigItemStore;
}> = observer(({item}) => {
  const toastsStore = useToastsStore();
  const [showDelete, setShowDelete] = useState(false);

  return <>
    <div className={"d-flex align-items-baseline mt-3"}>
      <ButtonGroup className={"mr-4"}>
        <Button
          variant={!item.data.visible ? "light" : "light"}
          active={!item.data.visible}
          onClick={() => {
            runInAction(() => item.data.visible = false);
          }}
        >
          <EyeSlash/> Hidden
        </Button>
        <Button
          variant={!item.data.visible ? "light" : "primary"}
          active={item.data.visible}
          onClick={() => {
            runInAction(() => item.data.visible = true);
          }}
        >
          <Eye/> Visible
        </Button>
      </ButtonGroup>

      <div className={"flex-grow-1"}/>

      {item.data.can_be_deleted ? (
        <Button variant={"outline-danger"} onClick={() => setShowDelete(true)}>
          <Trash/> Delete track
        </Button>
      ) : (
        <OverlayTrigger
          placement="bottom"
          delay={{ show: 100, hide: 0 }}
          overlay={
            <Tooltip id={`${item.data.id}_delete_tooltip`}>
              You can't delete educational track with applications in it. Please make sure to move all applications to other tracks before deleting.
            </Tooltip>
          }
        >
          <div>
            <Button variant={"outline-danger"} disabled style={{ pointerEvents: "none" }}><Trash/> Delete track</Button>
          </div>
        </OverlayTrigger>
      )}

    </div>
    <Modal
      show={showDelete && item.data.can_be_deleted}
      onHide={() => setShowDelete(false)}
      centered
    >
      <Modal.Body>
        Do you really want to delete educational track "{item.data.name}"?
      </Modal.Body>
      <Modal.Footer>
        <Button variant={"danger"} onClick={() => {
          setShowDelete(false);
          runInAction(() => item.state = "pending");
          item.delete().catch(e => {
            console.error(e);
            runInAction(() => item.state = "ok");
            toastsStore.addToast({
              props: {
                autohide: true,
                delay: 4300,
              },
              body: "An error occured during educational track delete",
              variant: "danger",
            });
          });
        }}>
          <Trash/> Delete
        </Button>
        <Button onClick={() => setShowDelete(false)}>Cancel</Button>
      </Modal.Footer>
    </Modal>
  </>;
});

interface Props extends HTMLProps<HTMLDivElement> {
  store: TracksConfigsStore,
  educationalProgramId: string,
}

const TracksConfigs:React.FC<Props> = observer(({store, educationalProgramId}) => {
  const formikDefaults = {
    name: "",
  };

  const tracks = store.tracksConfigs.filter(i => i.state !== "deleted").filter(i => i.data.educational_program === educationalProgramId);

  return <div>
    {tracks.map((item, i) => {
      const className = `mb-4 card-loading-animation ${item.state === "pending" ? "card-loading" : ""}`;

      return <React.Fragment key={i}>
        <Card className={className}>
          <Card.Body>
            <FormControlClosure
              as={"input"}
              name={"text"}
              value={item.data.name}
              onChange={({value, setValue}) => {
                // TODO такая себе валидация
                if (!value) {
                  setValue(item.data.name);
                } else {
                  runInAction(() => item.data.name = value);
                }
              }}
            />
            <div className={"mt-3 mb-3"}>
              <FormControlClosure
                as={"textarea"}
                name={"text"}
                value={item.data.description}
                onChange={({value, setValue}) => {
                  // TODO такая себе валидация
                  if (!value) {
                    setValue(item.data.description || "");
                  } else {
                    runInAction(() => item.data.description = value);
                  }
                }}
              />
            </div>
            <TrackSettings item={item} key={"edit"}/>
          </Card.Body>
        </Card>
      </React.Fragment>;
    })}
    <div>
      <Formik
        initialValues={formikDefaults}
        validationSchema={ApiTrackConfigFormSchema}
        onSubmit={async (values, helpers) => {
          store.tracksConfigs.push(new TrackConfigItemStore(store, {
            name: values["name"],
            visible: false,
            educational_program: educationalProgramId,
            can_be_deleted: true,
            sort: 0,
          }));

          helpers.resetForm();
        }}
      >
        {(formik) => <Form onSubmit={formik.handleSubmit}>
          <h5 className={"mt-4"}>Add {tracks.length ? "new" : ""} educational Track</h5>

          <Form.Group controlId={"newNotificationText"}>
            <Form.Control
              as={"input"}
              name={"name"}
              value={formik.values["name"]}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              isInvalid={!!formik.errors["name"] && formik.submitCount > 0}
              disabled={formik.isSubmitting}
              placeholder={"Track name"}
            />
          </Form.Group>

          <div className={"d-flex align-items-baseline mt-3"}>
            <div className={"flex-grow-1"}/>
            <div className={"mr-3 text-muted"}><InfoCircle/> All educational tracks are created hidden</div>
            <Button
              variant="primary"
              type="submit" disabled={formik.isSubmitting}
            >
              Add educational track
              {formik.isSubmitting ? <Spinner as="span" animation="border" role="status" aria-hidden="true"/> : <Plus/>}
            </Button>
          </div>
        </Form>}
      </Formik>
    </div>
  </div>;
});

export default TracksConfigs;