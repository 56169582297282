import React, {forwardRef} from "react";
import {Button} from "react-bootstrap";
import {ThreeDots} from "react-bootstrap-icons";

const ApplicationDropdownToggle = forwardRef<HTMLButtonElement, {onClick: (e: React.MouseEvent) => void}>((props , ref) => (
  <Button
    variant={"link"}
    size={"sm"}
    className={"sk-application-options"}
    onClick={e => {
      e.stopPropagation();
      props.onClick(e);
    }}
    ref={ref}
  >
    <ThreeDots/>
  </Button>
));
ApplicationDropdownToggle.displayName = "ApplicationDropdownToggle";

export default ApplicationDropdownToggle;