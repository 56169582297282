
import React, {useMemo} from "react";

export function s2positivei(s: string | undefined, nilvalue = 0):number {
  if (s) {
    const n = parseInt(s);

    if (isNaN(n)) return nilvalue;

    if (n < 0) return nilvalue;

    return n;
  } else {
    return nilvalue;
  }
}

export function cpyear() {
  const year = `${s2positivei(process.env.REACT_APP_BACK_YEAR, 2021)}`;
  const curYear = `${(new Date()).getFullYear()}`;

  if (curYear !== year) {
    return `${year} — ${curYear}`;
  } else {
    return year;
  }
}

export const useCPYear = () => useMemo(() => cpyear(), []);

export function appName():string {
  return process.env.REACT_APP_COMPANY_NAME ? process.env.REACT_APP_COMPANY_NAME : "Skolkovo University";
}

export const useAppName = () => useMemo(() => appName(), []);

export const filterObject = <T>(object: {
  [key: string]: T;
}, predicate: (key: string) => boolean) => {
  const result: {
    [key: string]: T;
  } = {};

  Object.entries(object).forEach(([key, value]) => {
    if (predicate(key)) result[key] = value;
  });

  return result;
};

export function setFormikEmailValue(e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>, setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void, fieldName = "email") {
  setFieldValue(fieldName, e.target.value.toLowerCase());
}
