import React from "react";
import {PageState} from "../../stores/BasePageStore";
import {OverlayTrigger, Tooltip} from "react-bootstrap";
import {ArrowRepeat} from "react-bootstrap-icons";

interface Props extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLHeadingElement>, HTMLHeadingElement> {
  icon: React.ReactElement;
  name: string;
  pageState: PageState;
  message?: string;
}

const PageName:React.FC<Props> = ({icon, pageState, className,message,name,...props}) => {
  const refresh = pageState === "refresh";

  return <>
    <h2 {...props} className={className || "mr-4 mb-0"}>
      {!refresh ? icon : <OverlayTrigger
        placement={"top"}
        overlay={
          <Tooltip id={"refreshing"}>{message || "Refreshing..."}</Tooltip>
        }
      >
        <ArrowRepeat className={"animation-spin"}/>
      </OverlayTrigger>} {name}
    </h2>
  </>;
};

export default PageName;