import React, {useContext, useState} from "react";
import {observer} from "mobx-react-lite";
import ProgramConfigItemStore from "../../../stores/backoffice/configs/ProgramConfigItemStore";
import {Button, ButtonGroup, Card, Dropdown, Form, Modal, OverlayTrigger, Spinner, Tooltip} from "react-bootstrap";
import {runInAction} from "mobx";
import {Eye, EyeSlash, InfoCircle, Plus, Trash} from "react-bootstrap-icons";
import {useToastsStore} from "../../../stores/singletones/ToastsStore";
import ProgramsConfigsStore from "../../../stores/backoffice/configs/ProgramsConfigsStore";
import FormControlClosure from "../../FormControlClosure";
import {Formik} from "formik";
import {ApiProgramConfigSchema} from "../../../services/api/back/campaigns/configs/programs/ApiProgramConfigGet";
import TracksConfigs from "./TracksConfigs";
import {settingsPageStoreContext} from "../../../pages/backoffice/BackSettingsPage";
import {ApiEducationTypeToVariant} from "../../../services/api/campaigns/ApiCampaignModel";

const ProgramSettings:React.FC<{
  item: ProgramConfigItemStore;
  educationTypeVariants: ApiEducationTypeToVariant[];
}> = observer(({item, educationTypeVariants}) => {
  const toastsStore = useToastsStore();
  const [showDelete, setShowDelete] = useState(false);

  return <>
    <div className={"d-flex align-items-baseline mt-3"}>
      <ButtonGroup className={"mr-4"}>
        <Button
          variant={!item.data.visible ? "light" : "light"}
          active={!item.data.visible}
          onClick={() => {
            runInAction(() => item.data.visible = false);
          }}
        >
          <EyeSlash/> Hidden
        </Button>
        <Button
          variant={!item.data.visible ? "light" : "primary"}
          active={item.data.visible}
          onClick={() => {
            runInAction(() => item.data.visible = true);
          }}
        >
          <Eye/> Visible
        </Button>
      </ButtonGroup>

      <div className={"mr-2"}>Education Type</div>
      <Dropdown className={"mr-3"}>
        <Dropdown.Toggle id="new-program-education-type">
          {educationTypeVariants.find(v => v.variant_id === item.data.education_type)?.education_type_name}
        </Dropdown.Toggle>
        <Dropdown.Menu>
          {educationTypeVariants.map((v: ApiEducationTypeToVariant) =>
            <Dropdown.Item key={v.variant_id} href="#" onClick={e => {
              e.preventDefault();
              runInAction(() => item.data.education_type = v.variant_id);
            }}>{v.education_type_name}</Dropdown.Item>
          )}
        </Dropdown.Menu>
      </Dropdown>

      <div className={"flex-grow-1"}/>

      {item.data.can_be_deleted ? (
        <Button variant={"outline-danger"} onClick={() => setShowDelete(true)}>
          <Trash/> Delete program
        </Button>
      ) : (
        <OverlayTrigger
          placement="bottom"
          delay={{show: 100, hide: 0}}
          overlay={
            <Tooltip id={`${item.data.id}_delete_tooltip`}>
              You can't delete educational program with applications or with nested educational tracks. Please make sure to move all applications to other programs and delete all nested educational tracks before deleting program.
            </Tooltip>
          }
        >
          <div>
            <Button variant={"outline-danger"} disabled style={{ pointerEvents: "none" }}><Trash/> Delete program</Button>
          </div>
        </OverlayTrigger>
      )}
    </div>
    <Modal
      show={showDelete && item.data.can_be_deleted}
      onHide={() => setShowDelete(false)}
      centered
    >
      <Modal.Body>
        Do you really want to delete educational program "{item.data.name}"?
      </Modal.Body>
      <Modal.Footer>
        <Button variant={"danger"} onClick={() => {
          setShowDelete(false);
          runInAction(() => item.state = "pending");
          item.delete().catch(e => {
            console.error(e);
            runInAction(() => item.state = "ok");
            toastsStore.addToast({
              props: {
                autohide: true,
                delay: 4300,
              },
              body: "An error occured during program delete",
              variant: "danger",
            });
          });
        }}>
          <Trash/> Delete program
        </Button>
        <Button onClick={() => setShowDelete(false)}>Cancel</Button>
      </Modal.Footer>
    </Modal>
  </>;
});

const ProgramsConfigs:React.FC<{
  store: ProgramsConfigsStore
}> = observer(({store}) => {
  const settingsPageStore = useContext(settingsPageStoreContext);

  const formikDefaults = {
    name: "",
    education_type: store.defaultEducationTypeVariant?.variant_id || "",
  };

  return <div className={"mt-5 mb-5"}>
    {store.programsConfigs.filter(i => i.state !== "deleted").map((item: ProgramConfigItemStore, i) => {
      const className = `mb-4 skeduprogram card-loading-animation ${item.state === "pending" ? "card-loading" : ""}`;

      return <React.Fragment key={i}>
        <Card className={className}>
          <Card.Body>
            <FormControlClosure
              as={"input"}
              name={"text"}
              value={item.data.name}
              onChange={({value, setValue}) => {
                // TODO такая себе валидация
                if (!value) {
                  setValue(item.data.name);
                } else {
                  runInAction(() => item.data.name = value);
                }
              }}
            />
            <div className={"mt-3"}>
              <div className={"d-flex align-items-center"}>
                <div className={"mr-2"}>
                  Exam date
                </div>
                <div>
                  <FormControlClosure
                    as={"input"}
                    type={"date"}
                    placeholder={"yyyy-mm-dd"}
                    name={"text"}
                    value={item.data.exam_date || ""}
                    onChange={({value}) => {
                      if (!value) {
                        runInAction(() => item.data.exam_date = null);
                      } else {
                        runInAction(() => item.data.exam_date = value);
                      }
                    }}
                  />
                </div>
              </div>
            </div>
            <ProgramSettings item={item} educationTypeVariants={store.educationTypeVariants || []} key={"edit"}/>
            {item.tracksLoading ? <h5 className={"mt-5 mb-3"}>Educational Tracks loading <Spinner animation={"grow"}/></h5> : null}
            {item.hasTracks && item.data.id ? <h5 className={"mt-5 mb-3"}>Educational Tracks</h5> : null}
            {item.data.id ? <TracksConfigs store={settingsPageStore?.tracksConfigsStore} educationalProgramId={item.data.id}/> : null}
          </Card.Body>
        </Card>
      </React.Fragment>;
    })}
    <div className={"mb-4"}>
      <Formik
        initialValues={formikDefaults}
        validationSchema={ApiProgramConfigSchema}
        onSubmit={async (values, helpers) => {
          store.programsConfigs.push(new ProgramConfigItemStore(store, {
            name: values["name"],
            visible: false,
            educational_tracks: [],
            education_type: values["education_type"],
            can_be_deleted: true,
            exam_date: null,
            sort: 0
          }));

          helpers.resetForm();
        }}
      >
        {(formik) => {
          const selectedEducationVariant = store.educationTypeVariants?.find((v: ApiEducationTypeToVariant) => v.variant_id === formik.values["education_type"]);

          return <Form onSubmit={formik.handleSubmit}>
            <h3 className={"mb-3 mt-5"}>Add new educational program</h3>

            <Form.Group controlId={"newNotificationText"}>
              <Form.Control
                as={"input"}
                name={"name"}
                value={formik.values["name"]}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                isInvalid={!!formik.errors["name"] && formik.submitCount > 0}
                disabled={formik.isSubmitting}
                placeholder={"Program name"}
              />
            </Form.Group>

            <div className={"d-flex align-items-baseline mt-3 mb-3"}>

              {(!!formik.errors["education_type"] && formik.submitCount > 0) ? (
                <div className={"mr-2 text-danger"}>Education Type</div>
              ) : (
                <div className={"mr-2"}>Education Type</div>
              )}
              <Dropdown className={"mr-3"}>
                <Dropdown.Toggle id="new-program-education-type">
                  {selectedEducationVariant ? selectedEducationVariant.education_type_name : "..."}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  {store.educationTypeVariants ? store.educationTypeVariants.map((v: ApiEducationTypeToVariant) =>
                    <Dropdown.Item key={v.variant_id} href="#" onClick={e => {
                      e.preventDefault();
                      formik.setFieldValue("education_type", v.variant_id);
                    }}>{v.education_type_name}</Dropdown.Item>
                  ) : null}
                </Dropdown.Menu>
              </Dropdown>

              <div className={"flex-grow-1"}/>
              <div className={"mr-3 text-muted"}><InfoCircle/> All educational programs are created hidden</div>
              <Button
                variant="primary"
                type="submit" disabled={formik.isSubmitting}
              >
                Add educational program
                {formik.isSubmitting ? <Spinner as="span" animation="border" role="status" aria-hidden="true"/> : <Plus/>}
              </Button>
            </div>
          </Form>;
        }}
      </Formik>
    </div>
  </div>;
});

export default ProgramsConfigs;