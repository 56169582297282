import * as yup from "yup";

interface ApiApplicationModel {
  id: string; // id заявки
  applicant: string; // id заявителя
  campaign: string; // id кампании
}

export const ApiApplicationModelSchema = yup.object().shape({
  id: yup.string().required(),
  applicant: yup.string().required(),
  campaign: yup.string().required(),
});

export default ApiApplicationModel;