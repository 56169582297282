import AxiosApiInstance, {APIBase} from "../../../../AxiosApiInstance";
import ApiNotificationsConfig from "./ApiNotificationsConfig";
import PartialBy from "../../../../../../helpers/types/PartialBy";
import {AxiosResponse} from "axios";

type ApiBackCampaignsConfigsNotificationsIdPostRequest = PartialBy<ApiNotificationsConfig, "id">

const ApiBackCampaignsConfigsNotificationsIdPost
  = (payload: ApiBackCampaignsConfigsNotificationsIdPostRequest)
  :Promise<AxiosResponse<ApiNotificationsConfig>> => {
    return AxiosApiInstance.post(`${APIBase}back/campaigns/${payload.campaign}/configs/notifications/`, payload);
  };

export default ApiBackCampaignsConfigsNotificationsIdPost;