import AxiosApiInstance, {APIBase} from "../AxiosApiInstance";
import {AxiosResponse} from "axios";

type ApiApplicationIdVariablesPostResponseRequest = {
  name: string;
  value: string;
}[];

export const ApiApplicationIdVariablesPost: (id:string, payload: ApiApplicationIdVariablesPostResponseRequest) => Promise<AxiosResponse<ApiApplicationIdVariablesPostResponseRequest>> = (id, payload) => {
  return AxiosApiInstance.post(APIBase + `applications/${id}/variables/`, payload);
};