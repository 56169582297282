import {action, autorun, computed, makeObservable, observable, runInAction} from "mobx";
import {ApiCampaignShortModel, ApiEducationTypeModel} from "../../../services/api/campaigns/ApiCampaignModel";
import ApiBackCampaignsGet from "../../../services/api/back/campaigns/ApiBackCampaignsGet";
import {Log} from "../../../helpers/log";

class BackAppStore {
  log = new Log("BackAppStore");
  error: unknown;
  state: "pending" | "ok" | "error" = "ok";

  constructor(public campaignId?: string, public educationTypeId?: string | "all") {

    makeObservable(this, {
      campaignId: observable,
      educationTypeId: observable,
      campaign: observable,
      error: observable,
      state: observable,
      educationType: computed,
      clearSelection: action,
    });

    autorun(() => {
      if (this.campaignId) {
        this.log.log("campaignId changed, runing fetchCampaign");
        this.fetchCampaign(this.campaignId);
      }
    });
  }

  campaign?:ApiCampaignShortModel;

  async fetchCampaign(campaignId: string) {
    runInAction(() => this.state = "pending");
    try {
      const campaign:ApiCampaignShortModel = (await ApiBackCampaignsGet(campaignId, this.educationTypeId !== "all" ? this.educationTypeId : undefined)).data;
      runInAction(() => {
        this.campaign = campaign;
        this.state = "ok";
      });
    } catch (e) {
      runInAction(() => {
        this.state = "error";
        this.error = e;
        this.clearSelection();
      });
    }
  }

  get educationType():ApiEducationTypeModel|undefined {
    return this.campaign?.education_types?.find(e => e.id === this.educationTypeId);
  }

  clearSelection(cb?: () => void) {
    this.campaignId = undefined;
    this.educationTypeId = undefined;
    cb && cb();
  }
}

export default BackAppStore;