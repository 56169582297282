import AxiosApiInstance, {APIBase} from "../AxiosApiInstance";
import {AxiosResponse} from "axios";
import ApiUploadModel from "./ApiUploadModel";

export type ApiUploadsPostResponseError = string[];

export const ApiUploadsPost: (formData: FormData, onUploadProgress?: (percentCompleted: number) => void) => Promise<AxiosResponse<ApiUploadModel>> = (formData, onUploadProgress) => {
  return AxiosApiInstance.post(APIBase + "uploads/", formData, onUploadProgress ? {
    onUploadProgress: (progressEvent) => {
      onUploadProgress(Math.round((progressEvent.loaded * 100) / progressEvent.total));
    }
  } : {});
};