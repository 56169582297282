import React, {ReactNode} from "react";
import {observer} from "mobx-react-lite";
import {Alert} from "react-bootstrap";
import {ApiQuestionRenderedModel} from "../../services/api/questions/ApiQuestionModel";

const NovariantsMessage: React.FC<{
  values: {
    [key: string]: string
  };
  noValuesMessage?: ReactNode;
  question: ApiQuestionRenderedModel;
  questions?: ApiQuestionRenderedModel[];
}> = observer((props) => <>
  {!Object.keys(props.values).length ? (props.noValuesMessage || (props.question.variants_depend_on && <Alert variant={"warning"} className={"mt-2"}>
      Please, fill out
      "{props.questions?.find(qf => qf.id === props.question.variants_depend_on)?.name || "previous fields"}" first
  </Alert>)) : null}
</>);

export default NovariantsMessage;