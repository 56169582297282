import React, {Suspense} from "react";
import {useAuth} from "../../stores/AuthStore";
import {Redirect, Route, Router, Switch} from "react-router-dom";
import LoadingPage from "../../pages/LoadingPage";
import LoginPage from "../../pages/LoginPage";
import ApplicationPage from "../../pages/ApplicationPage";
import {observer} from "mobx-react-lite";
import {useLog} from "../../helpers/log";
import ApplyPage from "../../pages/ApplyPage";
import RestorePage from "../../pages/RestorePage";
import Loading from "../../pages/Loading";
import AuthRoute from "./AuthRoute";
import NotAuthRoute from "./NotAuthRoute";
import ErrorBoundary from "../ErrorBoundary";
import RecommendationForRecommenderPage from "../../pages/RecommendationForRecommenderPage";
import {SkApplyHistory} from "../../const/SkApplyHistory";

export const SkApplyFrontRouter:React.FC = observer(() => {
  const auth = useAuth();
  const log = useLog("SkApplyFrontRouter");

  if (auth.state === "init") {
    log.log("initializing...");

    return  (
      <LoadingPage />
    );

  } else {

    console.log("SkApplyFrontRouter: router is active");

    if (auth.user?.backofficeAccess) {
      const BackApp = React.lazy(() => import("../../pages/backoffice/BackApp"));

      return (
        <ErrorBoundary errorLevel={"SkApplyFrontRouter-backapp-wrapper"}>
          <Router history={SkApplyHistory}>
            <Suspense fallback={<Loading loading={true}/>}>
              <BackApp/>
            </Suspense>
          </Router>
        </ErrorBoundary>
      );
    } else {
      return (
        <ErrorBoundary errorLevel={"SkApplyFrontRouter"}>
          <Router history={SkApplyHistory}>
            <Switch>
              <NotAuthRoute path="/restore" redirectTo={"/"}>
                <RestorePage />
              </NotAuthRoute>

              <NotAuthRoute path="/login" redirectTo={"/"}>
                <LoginPage />
              </NotAuthRoute>

              <NotAuthRoute path="/apply" redirectTo={"/"}>
                <ApplyPage />
              </NotAuthRoute>

              <AuthRoute path="/protected">
                <ApplicationPage />
              </AuthRoute>

              <Route path="/recommendation/:id">
                <RecommendationForRecommenderPage/>
              </Route>

              <Route path="/">
                {auth.isAuthenticated ? (
                  <ApplicationPage />
                ) : (
                  <ApplyPage />
                )}
              </Route>

              <Route path="*">
                <Redirect to={"/"} />
              </Route>
            </Switch>
          </Router>
        </ErrorBoundary>
      );
    }
  }

});
