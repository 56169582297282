import React, {useCallback, useContext, useMemo} from "react";
import {observer} from "mobx-react-lite";
import {Alert, Button, Card, Modal, Spinner as BootstrapSpinner} from "react-bootstrap";
import ApplicationActionConfirmationModalStore
  from "../../../../stores/backoffice/modals/applications/ApplicationActionConfirmationModalStore";
import Spinner from "../../../Spinner";
import {runInAction, toJS} from "mobx";
import SkForm, {SkFormValues} from "../../../SkForms/SkForm";
import {FormikHelpers} from "formik/dist/types";
import SkSubmitButtonProps from "../../../SkForms/SkSubmitButtonProps";
import Icon from "../../../../helpers/components/Icon";
import {
  ApiBackCampaignsActionsPostResponse,
} from "../../../../services/api/back/campaigns/actions/ApiBackCampaignsActionsPost";
import {useToastsStore} from "../../../../stores/singletones/ToastsStore";
import AnswersFromFormValues from "../../../../helpers/factory/AnswersFromFormValues";
import ApiAnswerPostModel from "../../../../services/api/applications/ApiAnswerModel";
import xss from "xss";
import {filterObject} from "../../../../helpers/utils";
import AnswersDiff from "../../../../helpers/AnswersDiff";
import {SkFormMetaContext, SkFormPropsContext} from "../../../SkForms/SkFormContexts";
import {InfoCircleFill} from "react-bootstrap-icons";
import processErrors from "../../../SkForms/processErrors";
import {UTMTable} from "../../../UTMTable";

const ApplicationActionConfirmationSubmitButton:React.FC<SkSubmitButtonProps> = (props) => {
  const context = useContext(applicationActionConfirmationModalContext);
  return <>

    <div className={"text-right"}>
      <Button
        variant="secondary"
        onClick={context?.handleCancel}
        className={"mr-2"}
      >
        {context?.store.actionStore?.reject_button || "Cancel"}
      </Button>
      <Button
        type="submit"
        disabled={props.disabled || props.formikProps.isSubmitting}
        variant="primary"
      >
        {props.formikProps.isSubmitting
          ? "Submitting..."
          : context?.store.actionStore?.confirm_button || context?.store.applicationAction.name
        }
      </Button>
    </div>
  </>;
};

const applicationActionConfirmationModalContext = React.createContext<{
  store: ApplicationActionConfirmationModalStore;
  handleCancel: () => void;
  handleYes: () => void;
    } | null>(null);

const ApplicationActionConfirmationModal:React.FC<{
  store: ApplicationActionConfirmationModalStore;
  hideModal: () => void;
}> = observer(({
  store,
  hideModal,
}) => {
  const toastsStore = useToastsStore();

  const handleCancel = useCallback(() => {
    if (store.state !== "posting") {
      hideModal();
    }
  }, []);

  const handleYes = useCallback((answers: ApiAnswerPostModel[] = [], formikHelpers?: FormikHelpers<SkFormValues>) => {
    store.processPostAction(answers).then((axiosResponse) => {
      if (axiosResponse && axiosResponse.data) {
        const response: ApiBackCampaignsActionsPostResponse = axiosResponse.data;

        // обрабатываем ошибку для форм и остальное
        if (store.applicationAction.type === "form" && response.status === "error") {
          const errorMessage = response.message;

          try {
            const [, fieldErrors] = processErrors({response: axiosResponse});
            formikHelpers?.setErrors(fieldErrors);
          } catch (e) {
            console.error(e);
          }

          runInAction(() => {
            store.error = errorMessage;
          });
        } else {
          if (response.message_format === "html") {
            store.setActionResponse(response);
          } else {
            toastsStore.addToast({
              props: {
                animation: true,
                autohide: true,
                delay: 8000,
              },
              ...(
                response.status === "error"
                  ? {variant: "danger"}
                  : response.status === "success"
                    ? {variant: "success"}
                    : response.status === "warning"
                      ? {variant: "warning"}
                      : {}
              ),
              body: response.message,
              header: store.applicationAction.name
            });

            hideModal();
          }

        }

      } else {
        formikHelpers?.setSubmitting(false);
      }
    }, () => formikHelpers?.setSubmitting(false)).finally(() => {
      if (formikHelpers) formikHelpers.setSubmitting(false);
    });
  }, [store.actionStore?.status]);

  const onFormSubmit = (formId: string, values: SkFormValues, formikHelpers: FormikHelpers<SkFormValues>) => {
    let unitedAnswers: ApiAnswerPostModel[] = [];

    // для режима rendered_application и обычного с одной формой — разный формат подсчёта ответов
    // для rendered_application нужно заморачиваться и склеивать ответы из разных форм
    if (store.renderedFormsMode) {
      store.questionIdsByFormId?.forEach(tuple => {
        const questionIds: string[] = tuple[1];
        const filteredValues = filterObject(values, valueId => questionIds.some(qid => qid === valueId));

        unitedAnswers = unitedAnswers.concat(AnswersFromFormValues(filteredValues, store.firstApplicationId));
      }, []);
    } else {
      unitedAnswers = AnswersFromFormValues(values, store.firstApplicationId);
    }

    handleYes(AnswersDiff(unitedAnswers, store.actionStore?.rendered_application?.answers), formikHelpers);
  };

  const forms = useMemo(() => {
    if (store.actionStore?.forms?.length) {
      return store.actionStore?.forms.map(form => <SkFormPropsContext.Provider key={form.id} value={{
        questions: toJS(form.questions),
        questionsDisabled: [],
        reactiveInitialValues: toJS(form.initialValues),
      }}>
        <SkFormMetaContext.Provider value={{
          form: form.id,
          application: store.firstApplicationId,
        }}>
          <SkForm
            onSubmit={(v: SkFormValues, fh: FormikHelpers<SkFormValues>) => onFormSubmit(form.id, v, fh)}
            submitComponent={ApplicationActionConfirmationSubmitButton}
          />
        </SkFormMetaContext.Provider>
      </SkFormPropsContext.Provider>);
    } else if (store.renderedFormsMode && store.renderedUniForm) {
      return <SkFormPropsContext.Provider value={{
        questions: toJS(store.renderedUniForm.questions),
        questionsDisabled: store.renderedUniForm.root.questions_readonly,
        reactiveInitialValues: toJS(store.renderedUniForm.initialValues),
      }}>
        <SkFormMetaContext.Provider value={{
          form: store.renderedUniForm.id,
          application: store.firstApplicationId,
        }}>
          <SkForm
            key={toJS(store.renderedUniForm.id)}
            questionBlocks={toJS(store.renderedUniForm.questionsBlocks)}
            onSubmit={(v: SkFormValues, fh: FormikHelpers<SkFormValues>) => onFormSubmit("uniform", v, fh)}
            beforeSubmitRender={<UTMTable utms={store.actionStore?.rendered_application?.utms}/>}
            submitComponent={ApplicationActionConfirmationSubmitButton}
            mandatory={"noone"}
          />
        </SkFormMetaContext.Provider>
      </SkFormPropsContext.Provider>;
    }
  }, [store.actionStore?.forms?.length, store.renderedFormsMode, store.renderedUniForm]);

  const dialogClassName = store.renderedFormsMode ? "modal-sk-sideform" : "";

  const showLoading = useMemo(() => store.state === "init" || store.state === "posting", [store.state]);

  return <>
    <Modal show={true} onHide={handleCancel} centered size="lg" dialogClassName={dialogClassName}>
      <Modal.Header closeButton>
        <Modal.Title>
          <Icon icon={store.applicationAction.icon} className={"mr-2"}/> {store.applicationAction.name}
        </Modal.Title>
      </Modal.Header>

      {showLoading ? <div className={"d-flex align-items-center justify-content-center mt-4 mb-4"}>
        <Spinner/>
      </div> : null}

      <div className={showLoading ? "d-none" : ""}>

        {store.error ? (
          <Modal.Body>
            <Alert dismissible onClose={() => store.clearErrors()} variant={"danger"}>
              {`${store.error}`}
            </Alert>
          </Modal.Body>
        ) : null}

        {/*Здесь можно добавлять или удалять варианты отображения действий*/}
        {/* Правило — сначала определяем тип действия, а потом оперируем состоянием store.state и прочими параметрами */}

        {store.applicationAction.type === "download" ?
          store.state === "ok" ? <>

            <Modal.Body>
              <div className={"d-flex align-items-center justify-content-center"}>
                <span>Downloading file</span>
                <BootstrapSpinner animation={"grow"} className={"ml-2"}/>
              </div>
            </Modal.Body>

          </> : <>

            <Modal.Body>
              <Alert variant={"danger"}>Something goes wrong with file request. Please, close this message and try again.</Alert>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleCancel}>Close</Button>
            </Modal.Footer>

          </> : null}

        {/* Это используется для окна Info */}

        {store.applicationAction.type === "form" ? (
          !forms ? (
            <>
              <Modal.Body>
                <Alert variant={"danger"}>Not forms to edit.</Alert>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={handleCancel}>Close</Button>
              </Modal.Footer>
            </>
          ) : (
            store.actionResponse?.status === "success" ? (
              <>
                <ActionResponseMessageDisplay store={store}/>
                <Modal.Footer>
                  <Button variant="secondary" onClick={handleCancel}>Close</Button>
                </Modal.Footer>
              </>
            ) : (
              <Modal.Body>
                <applicationActionConfirmationModalContext.Provider value={{
                  store: store,
                  handleCancel: handleCancel,
                  handleYes: handleYes
                }}>
                  {forms}
                </applicationActionConfirmationModalContext.Provider>
              </Modal.Body>
            )
          )
        ) : null}

        {store.applicationAction.type === "simple" ? (
          <>
            <ActionOrResponseMessageDisplay store={store}/>
            <Modal.Footer>
              {store.actionStore?.status !== "error" && store.state !== "error" && store.state !== "posted" ? (
                <>
                  <Button variant="secondary" onClick={handleCancel}>{store.actionStore?.reject_button || "Cancel"}</Button>
                  <Button variant="primary" onClick={() => handleYes()}>{store.actionStore?.confirm_button || store.applicationAction.name}</Button></>
              ) : (
                <Button variant="secondary" onClick={handleCancel}>Close</Button>
              )}

            </Modal.Footer>
          </>
        ) : null}

        {store.applicationAction.type === "log" ? (
          <Modal.Body className={"sk-logs-container"}>
            {store.actionStore?.logs?.length ? (
              store.actionStore?.logs.map(log => <>
                <Card border={log.style} className={"mt-3 mb-3"}>
                  <Card.Body>
                    <Card.Text className={`sk-log-heading text-${log.style}`}>
                      {log.icon ? <Icon icon={log.icon}/> : <InfoCircleFill/>} {log.date}
                    </Card.Text>
                    <Card.Text>
                      {log.message}
                    </Card.Text>
                  </Card.Body>
                </Card>
              </>)
            ) : (
              <h2 className={"mt-4 mb-4 text-center"}>
                No history for this application
              </h2>
            )}
          </Modal.Body>
        ) : null}
      </div>

    </Modal>
  </>;
});

const ActionResponseMessageDisplay:React.FC<{
  store: ApplicationActionConfirmationModalStore
}> = observer(({store}) => <>
  {store.actionResponse?.message_format === "plaintext" && store.actionResponse?.message ? <Modal.Body>{store.actionResponse?.message}</Modal.Body> : null}
  {store.actionResponse?.message_format === "html" && store.actionResponse?.message ? <Modal.Body>
    <div dangerouslySetInnerHTML={{__html: xss(`${store.actionResponse?.message}`)}}/>
  </Modal.Body> : null}
</>);

const ActionMessageDisplay:React.FC<{
  store: ApplicationActionConfirmationModalStore
}> = observer(({store}) => <>
  {store.actionStore?.message_format === "plaintext" && store.actionStore?.message ? <Modal.Body>{store.actionStore?.message}</Modal.Body> : null}
  {store.actionStore?.message_format === "html" && store.actionStore?.message ? <Modal.Body>
    <div dangerouslySetInnerHTML={{__html: xss(`${store.actionStore?.message}`)}}/>
  </Modal.Body> : null}
</>);

const ActionOrResponseMessageDisplay:React.FC<{
  store: ApplicationActionConfirmationModalStore
}> = observer(({store}) => store.actionResponse ? <ActionResponseMessageDisplay store={store}/> : <ActionMessageDisplay store={store}/>);

export default ApplicationActionConfirmationModal;
