import React, {forwardRef} from "react";
import {observer} from "mobx-react-lite";
import {Dropdown, DropdownProps} from "react-bootstrap";
import BackApplicationsPageStore from "../../stores/backoffice/pages/BackApplicationsPageStore";
import Icon from "../../helpers/components/Icon";

interface Props extends DropdownProps {
  store: BackApplicationsPageStore;
}

const H4 = forwardRef<HTMLDivElement, {onClick: (e: React.MouseEvent) => void}>((props , ref) => (
  <h4
    className={"mr-2 ml-4 mb-0 text-info dropdown-toggle"}
    onClick={e => {
      e.preventDefault();
      props.onClick(e);
    }}
    ref={ref}
    style={{
      userSelect: "none",
      cursor: "pointer"
    }}
  >{props.children}</h4>
));
H4.displayName = "H4";

const SelectedApplications: React.FC<Props> = observer(({store, ...rest}) => {
  if (store.selectedApplications.length > 0) {
    return <Dropdown {...rest}>
      <Dropdown.Toggle as={H4}>
        {store.selectedApplications.length} Selected
      </Dropdown.Toggle>
      <Dropdown.Menu>
        <Dropdown.Header className={"text-info"}>With {store?.selectedApplications.length} applications:</Dropdown.Header>
        {store?.applicationsConfig?.available_batch_actions?.map(batchAction => (
          <Dropdown.Item key={batchAction.slug} onClick={e => {
            e.stopPropagation();
            store?.showApplicationActionConfirmation(batchAction, store.selectedApplications.join(","));
          }}>
            <Icon icon={batchAction.icon} className={"bi mr-2"}/> {batchAction.name}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>;
  } else {
    return null;
  }
});

export default SelectedApplications;
