import {makeAutoObservable, observable, runInAction} from "mobx";
import {ApiCampaignsGet} from "../../services/api/campaigns/ApiCampaignsGet";
import {Log} from "../../helpers/log";
import ApiCampaignModel from "../../services/api/campaigns/ApiCampaignModel";
import React, {useContext} from "react";
import {IObservableArray} from "mobx/dist/internal";

class CampaignsStore {
  log = new Log("CampaignsStore");

  constructor() {
    makeAutoObservable(this);
  }

  async fetchCampaigns() {
    this.log.log("fetchCampaigns");

    try {
      const campaignResponse = (await ApiCampaignsGet()).data;
      this.log.log("ApiCampaignsGet responded with", campaignResponse);
      runInAction(() => {
        if (!this.campaigns) this.campaigns = observable.array();
        campaignResponse.results.forEach(campaign => this.campaigns && this.campaigns.push(campaign));
      });
      return;
    } catch (e) {
      this.log.error(e, "Error fetching campaigns", (e as Error)?.message ? (e as Error).message : "no message provided");
      runInAction(() => {
        this.error = e;
      });
      throw e;
    }
  }

  async getCurrentCampaign():Promise<ApiCampaignModel|null> {
    if (this.campaigns) {
      if (this.campaigns.length) {
        return this.campaigns[0]; // TODO выбор кампании!!!
      } else {
        return null;
      }
    } else {
      await this.fetchCampaigns();
      return await this.getCurrentCampaign(); // do same thing once again
    }
  }

  campaigns?:IObservableArray<ApiCampaignModel>;

  error: null | unknown = null;
}

export const campaignsStore = new CampaignsStore();

const CampaignsStoreContext = React.createContext(campaignsStore);

const useCampaigns = () => useContext(CampaignsStoreContext);

export default useCampaigns;
