let isTranslated = false;
let isPatched = false;

const testTextNode = document.getElementById('translate-test');

const observerCoonfig = {
  childList: true,
  attributes: false,
  subtree: true,
};

const observerCallback:MutationCallback = (mutationsList, observer) => {
  for(const mutation of mutationsList) {
    if (mutation.type === 'childList') {
      isTranslated = document.querySelector('#translate-test')?.innerHTML  !== "English, please";
    }
  }

  if (isTranslated && !isPatched) {
    // Monkeypatch by Dan Abramov
    // https://github.com/facebook/react/issues/11538#issuecomment-417504600
    console.warn("[GTSafe] Google Translate detected! Patching...");

    try {
      if (typeof Node === 'function' && Node.prototype) {
        const originalRemoveChild = Node.prototype.removeChild;

        // @ts-ignore
        Node.prototype.removeChild = function(child) {
          if (child.parentNode !== this) {
            if (console) {
              console.error('[GTSafe Patch] Cannot remove a child from a different parent', child, this);
            }
            return child;
          }
          // @ts-ignore
          return originalRemoveChild.apply(this, arguments);
        }

        const originalInsertBefore = Node.prototype.insertBefore;
        // @ts-ignore
        Node.prototype.insertBefore = function(newNode, referenceNode) {
          if (referenceNode && referenceNode.parentNode !== this) {
            if (console) {
              console.error('[GTSafe Patch] Cannot insert before a reference node from a different parent', referenceNode, this);
            }
            return newNode;
          }
          // @ts-ignore
          return originalInsertBefore.apply(this, arguments);
        }
      }
      isPatched = true;
      console.warn("[GTSafe] Patched!");
    } catch {
      console.error("[GTSafe] An error occurred during patching. Please, turn off in-page Google Translate, or crashes may occur.");
    }

    observer.disconnect();
  }
};

const observer = new MutationObserver(observerCallback);

testTextNode && observer.observe(testTextNode, observerCoonfig);

export {};
