import * as yup from "yup";

interface JWTPayload {
  username: string;
  iat: number;
  exp: number;
  user_id: number;
}

export const JWTPayloadSchema = yup.object({
  username: yup.string().required(),
  iat: yup.number().required(),
  exp: yup.number().required(),
  user_id: yup.number().required()
});

export default JWTPayload;