import {FormikProps} from "formik";
import {useEffect, useState} from "react";

const useSkFieldValidInvalid = (props: {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  formikProps: FormikProps<any>,
  name: string;
  isInvalid?: boolean;

  reallyTouched?: boolean;
}) => {
  // hello, Formik
  const [reallyTouched, setReallyTouched] = useState<boolean>(props.formikProps.values[props.name] || !!props.reallyTouched);

  useEffect(() => {
    props.formikProps.submitCount > 0 && setReallyTouched(true);
  }, [props.formikProps.submitCount]);

  const isInvalid = props.isInvalid !== undefined || (reallyTouched && !!props.formikProps.errors[props.name]);

  return {
    isInvalid: isInvalid,
    setReallyTouched: setReallyTouched,
    reallyTouched: reallyTouched,
  };
};

export default useSkFieldValidInvalid;
