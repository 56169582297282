import {ApiAnswerErrorModel, ApiAnswerGetModel} from "../../services/api/applications/ApiAnswerModel";
import {AxiosError, AxiosResponse} from "axios";
import {SkFormValues} from "./SkForm";
import {ApiQuestionRenderedModel} from "../../services/api/questions/ApiQuestionModel";
import * as yup from "yup";
import {array} from "yup";

const responseWithErrorsSchema = yup.object().shape({
  "answer_errors": yup.object().required()
});

export interface ResponseWithErrors {
  answer_errors?: {
    [questionId: string]: string[];
  }
}

const arrayOfRequiredStringsSchema = yup.array().of(yup.string().required()).min(1).required();

const structuralErrorsArraySchema = array().of(yup.object().shape({
  "answer_value": array().of(yup.string()).min(1)
})).required();

const structuralErrorsInAnswersObjectSchema = yup.object().shape({
  answers: structuralErrorsArraySchema
});

interface AnswerErrorsObject {
  answer_value?: string[];
}

export type processErrorsAnswersGetter = (response: AxiosResponse) => (ApiAnswerErrorModel|ApiAnswerGetModel)[];

const getAnswerValueErrors = (answerErrorsObjects: AnswerErrorsObject[], values: SkFormValues, questions: ApiQuestionRenderedModel[], errorMessage: string):[{[fieldName: string]: string}, string] => {
  const fieldErrors:{[fieldName: string]: string} = {};
  let noneFieldError = errorMessage;

  // Для общего сообщения об ошибке
  const wrongQuestionNames:string[] = [];

  // итерируем по отправленным значениям формы и собираем ошибки по ним в объекте из ответа
  Object.keys(values).forEach((questionId, index) => {
    const questionName = questions.find(q => q.id === questionId)?.name;
    const answerErrorsObject:AnswerErrorsObject|undefined = answerErrorsObjects[index];

    // скукоживаем ошибки в строчки
    let error = undefined;
    try {
      if (answerErrorsObject && answerErrorsObject.answer_value) {
        error = answerErrorsObject.answer_value.join(", ");
      }
    } catch {
      error = "Got unknown error format, see console for the details";
      console.error("Got unknown error format, see console for the details. answerErrorsObjects:", answerErrorsObjects);
    }

    if (error) {
      fieldErrors[questionId] = error;
      questionName && wrongQuestionNames.push(questionName);
    }
  });

  // общее сообщения об ошибке (с инфой о названии полей)
  if (wrongQuestionNames.length) noneFieldError = `${noneFieldError}. Check ${wrongQuestionNames.join(", ")}.`;

  return [fieldErrors, noneFieldError];
};

const processErrors = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  e: any | Error | AxiosError | AxiosResponse,
  values?: SkFormValues,
  questions?: ApiQuestionRenderedModel[],
  errorMessage = "An error occurred during saving",
  errorsGetter?: processErrorsAnswersGetter,
):[string, {[fieldName: string]: string}] => {
  let fieldErrors:{[fieldName: string]: string} = {};
  let noneFieldError = errorMessage;

  if ((e.response && e.response.status === 400) || e.response.data?.status === "error") {
    // Django errors here
    const errorsToGuess = errorsGetter ? errorsGetter(e.response) : e.response.data;

    // Это ноыый формат ошибок
    //{
    //     "answer_errors": {
    //         "45e482f1-2522-47ed-8e10-c14cddcb8a64": [
    //             "Please use only latin characters"
    //         ],
    //         "060d1388-197c-4413-b409-4155c2f600b4": [
    //             "Please use only latin characters"
    //         ]
    //     }
    // }
    if (responseWithErrorsSchema.isValidSync(errorsToGuess)) {
      const responseWithErrors:ResponseWithErrors = errorsToGuess as ResponseWithErrors;

      if (responseWithErrors.answer_errors) {
        fieldErrors = Object.fromEntries(Object.entries(responseWithErrors.answer_errors).map(([key, value]) => {
          return value.join ? [`${key}`, `${value.join(", ")}`] : [`${key}`, `${value}`];
        }));
      }
    }

    // Если  это массив строк — конкатенируем их и выводим как ошибку формы
    else if (arrayOfRequiredStringsSchema.isValidSync(errorsToGuess)) {
      noneFieldError = (errorsToGuess as string[]).join(", ");
    }

    // Следующий возможный формат — объект вида
    // { "answers": [ { "answer_value": ["ошибка1", "ошибка2"] }, {}, {}, ... ] }
    else if (structuralErrorsInAnswersObjectSchema.isValidSync(errorsToGuess)) {
      if (questions && values && errorsToGuess) {
        [fieldErrors, noneFieldError] = getAnswerValueErrors((errorsToGuess.answers as AnswerErrorsObject[]), values, questions, errorMessage);
      }
    }

    // и ещё один формат! (ахахахаха)
    else if (structuralErrorsArraySchema.isValidSync(errorsToGuess)) {
      const answersObjects:AnswerErrorsObject[] = (errorsToGuess as AnswerErrorsObject[]);

      if (questions && values && errorsToGuess) {
        [fieldErrors, noneFieldError] = getAnswerValueErrors(answersObjects, values, questions, errorMessage);      }
    }

    // Старый алгоритм угадывания ошибок (ссори, у ДЖинго всё очень плохо с форматом выводом ошибок — тысячи их)
    // ВНИМАНИЕ тут могут быть копипасты и чёрт с ними
    else if (questions && values && Array.isArray(errorsToGuess)) {
      // Для общего сообщения об ошибке
      const wrongQuestionNames:string[] = [];

      // try to get errors by questions
      Object.keys(values).forEach((questionId, index) => {
        const questionName = questions.find(q => q.id === questionId)?.name;
        const response:ApiAnswerErrorModel|ApiAnswerGetModel = errorsToGuess[index];

        // flatten if needed
        let error;
        try {
          const errorsCarriers = Object
            .values((response as ApiAnswerErrorModel));

          if (errorsCarriers && errorsCarriers.length) {
            error = errorsCarriers
              .reduce<string[]>((a, errors) => errors ? a.concat(errors) : a, [])
              .join(", ");
          }
        } catch {
          error = "Unknown error has occurred, see console for the details";
          console.error(response);
        }

        if (error) {
          fieldErrors[questionId] = error;
          questionName && wrongQuestionNames.push(questionName);
        }
      });

      // field-by-field summary
      if (wrongQuestionNames.length) noneFieldError = `${noneFieldError}. Check ${wrongQuestionNames.join(", ")}.`;

    } else if (e.response.data?.non_field_errors) {
      noneFieldError = e.response.data.non_field_errors.join(", ");
    }

  } else if (e.message) {
    // internal error
    noneFieldError = e.message;
  }

  return [noneFieldError, fieldErrors];
};

export default processErrors;
