import React, {useMemo} from "react";
import {Button, Card} from "react-bootstrap";
import {FileEarmarkFill as FileIcon, Hourglass, X} from "react-bootstrap-icons";
import {observer} from "mobx-react-lite";
import {FileStore} from "../../stores/singletones/FileUploaderStore";

const SkFileBlockLight:React.FC<{
  disabled?: boolean;
  submitted?: boolean;
  onDelete: () => void;
  id: string;
}> = observer(({
  id, onDelete, submitted, disabled
}) => {
  const file = useMemo(() => new FileStore(null, id), [id]);

  return (
    <Card className={"mb-3"} {... submitted && {border: "success"}}>
      <Card.Body>
        <div className="d-flex align-items-baseline">
          <div>
            {file.state === "pending" && <Hourglass className={"mr-1"}/>}
            {file.state !== "pending" && <FileIcon className={"mr-1"}/>}
          </div>
          <div className={"flex-grow-1"} style={{
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis"
          }}>
            {file.filename_origin}
          </div>
          {!disabled && <div>
            <Button className={"ml-1"} size={"sm"} variant={"danger"} onClick={e => {
              e.preventDefault();
              onDelete();
            }}>
              <X/>
            </Button>
          </div>}
        </div>
      </Card.Body>
    </Card>
  );
});

export default SkFileBlockLight;
