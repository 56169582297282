import React from "react";
import {LoginForm} from "./LoginForm";
import {useAuth} from "../stores/AuthStore";
import {observer} from "mobx-react-lite";
import {SkApplyHistory} from "../const/SkApplyHistory";

const LoginBlock:React.FC = observer(() => {
  const auth = useAuth();

  return (
    <LoginForm onSubmit={(values, actions) => {
      actions.setStatus(null);
      actions.setErrors({});

      window.localStorage.setItem("lastLoginEmail", values["email"] || "");

      auth.fetchLogin(values.email, values.password, () => SkApplyHistory.push("/"))
        .then(() => {
          actions.setSubmitting(false);
        })
        .catch(error => {
          const fieldErrors:{[fieldName: string]: string} = {};
          let nonFieldError = "An error occurred";

          // Parse generic error
          if (error?.response?.data?.non_field_errors && Array.isArray(error?.response?.data?.non_field_errors)) {
            nonFieldError = `${nonFieldError}: ${error.response.data.non_field_errors.join(", ")}`;
          }
          else if (error?.message) {
            nonFieldError = `${nonFieldError}: ${error.message}. If the problem persists, take a screenshot with the open developer console and write to support.`;
          }
          // Django put messages in detail
          else if (error?.detail) {
            nonFieldError = `${nonFieldError}: ${error.detail}. If the problem persists, take a screenshot with the open developer console and write to support.`;
          }

          // Parse fields errors
          if (error?.response?.data) {

            Object.keys(error.response.data).forEach((key:string) => {
              const fieldDescriptor = error.response.data[key];
              let fieldErrorMessage = `Error in ${key} field`;

              if (Array.isArray(fieldDescriptor) && fieldDescriptor.length) {
                fieldErrorMessage = `${fieldErrorMessage}: ${fieldDescriptor.join(", ")}`;
              }

              fieldErrors[key] = fieldErrorMessage;
            });
          }

          // Setting processed errors
          actions.setErrors(fieldErrors);
          actions.setStatus(nonFieldError);

          actions.setSubmitting(false);
        });
    }}/>
  );
});

export default LoginBlock;
