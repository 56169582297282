import {useCallback} from "react";

const useHandleDropdownToggle = (setter: (open: boolean) => void) => useCallback((isOpen, e, m) => {
  if (isOpen) {
    setter(true);
  } else {
    if (m.source !== "select") {
      setter(false);
    }
  }
}, []);

export default useHandleDropdownToggle;