import {AxiosResponse} from "axios";
import AxiosApiInstance, {APIBase} from "../../../AxiosApiInstance";

export interface ApiBackCampaignsReportsAvailableGetParams {
  education_type?: string;
}

export interface ApiReportAvailable {
  name: string; // название репорта
  description: string; // описание
  slug: string; // возможно это type
  url: string;
  url_relative: string;
}

export interface ApiBackCampaignsReportsAvailableGetResponse {
  reports_available: ApiReportAvailable[];
}

export const ApiBackCampaignsReportsAvailableGet: (campaign_pk: string, params?: ApiBackCampaignsReportsAvailableGetParams) => Promise<AxiosResponse<ApiBackCampaignsReportsAvailableGetResponse>> = (campaign_pk, params) => {
  return AxiosApiInstance.get(`${APIBase}back/campaigns/${campaign_pk}/reports_available/`, {
    params: params
  });
};