import {AxiosResponse} from "axios";
import AxiosApiInstance from "../../../AxiosApiInstance";
import ApiApplicationFormModel from "../../../forms/ApiApplicationFormModel";
import ApiApplicationRenderedModel from "../../../applications/ApiApplicationRenderedModel";
import ApplicationLog from "../../../../../interfaces/back/ApplicationLog";

//TODO кажется это Action тот же, посмотри, ну и вообще недописан он
export interface ApiBackCampaignsActionsGetResponse {
  name: string;
  status: "success" | "error" | "warning";
  message: string; // кажется это то, что надо отобразить в модале
  message_format: "plaintext" | "html" | string;
  type: string | "download";
  forms: ApiApplicationFormModel[] | null; // TODO но это неточно
  rendered_application?: ApiApplicationRenderedModel;
  confirm_button: string; // кажется это название кнопки окей
  reject_button: string; // кажется это название кнопки отмены
  logs?: ApplicationLog[];
}

export const ApiBackCampaignsActionsGet: (url: string, applications?: string) => Promise<AxiosResponse<ApiBackCampaignsActionsGetResponse>> = (url, applications) => {
  const config = {
    params: {
      ...(applications?.length ? {applications: applications} : {})
    }
  };

  return AxiosApiInstance.get(url, config);
};

export default ApiBackCampaignsActionsGet;
