import {AxiosInstance, AxiosResponse} from "axios";
import {APIBase} from "../AxiosApiInstance";
import ApiRecommendationForRecommenderModel from "./ApiRecommendationForRecommenderModel";

export interface ApiRecommendationForRecommenderGetResponse404 {
  detail?: string;
}

export const ApiRecommendationForRecommenderGet: (id:string, axios: AxiosInstance) => Promise<AxiosResponse<ApiRecommendationForRecommenderModel>> = (id, axios) => {
  return axios.get(`${APIBase}recommendations_for_recommender/${id}/`);
};