import {action, autorun, computed, makeObservable, observable, set} from "mobx";
import React, {useContext, useEffect} from "react";

export type PageState = "init" | "ok" | "pending" | "saving" | "refresh" | "error";

interface BasePageStore {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  refresh?(): any | Promise<any>;
  clear?(): void;
  toBeStored?: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [key: string]: any;
  };
}
abstract class BasePageStore {
  lsname = `${this.constructor.name}_storedData`;

  abstract state: PageState | string;
  abstract error: unknown;

  // Any page store should have load method for block component to trigger when page component is being loaded
  /* eslint-disable @typescript-eslint/no-explicit-any */
  abstract load(): any | Promise<any>;

  isSleep: boolean = observable(false);
  sleep: () => void = action(() => {
    this.isSleep = true;
  });
  awake: () => void = action(() => {
    this.isSleep = false;
  });

  registerToBeStored() {
    if (this.toBeStored) {
      try {
        const json: string|null = window.localStorage.getItem(this.lsname);
        let clear = false;

        if (json) {
          try {
            const toBeStoredData = this.toBeStored;
            const localStoredData = JSON.parse(json);

            const toBeStoredKeys = Object.keys(toBeStoredData) as (keyof BasePageStore)[];
            const localStoredKeys = Object.keys(localStoredData) as (keyof BasePageStore)[];

            if (!toBeStoredKeys.some(tbsk => !localStoredKeys.includes(tbsk))) {
              set(this, localStoredData);
            } else {
              clear = true;
            }
          } catch {
            clear = true;
          }
        } else {
          clear = true;
        }

        if (clear) window.localStorage.removeItem(this.lsname);

        makeObservable(this, {
          toBeStored: computed
        });

        autorun(() => {
          try {
            const data = JSON.stringify(this.toBeStored);

            if (data) {
              window.localStorage.removeItem(this.lsname);
              window.localStorage.setItem(this.lsname, data);
            }
          } catch (e) {
            console.error(e);
          }
        });

      } catch (e) {
        console.error(e);
      }
    }

  }
}

export default BasePageStore;

interface BasePageStoreContext<S extends BasePageStore, A extends any[]> {
  getStore: (...args: A) => S;
}

export function createPageStoreContext<S extends BasePageStore, A extends any[]> (factory: (...args: A) => S) {
  let store: S | undefined;

  const getStore = (...args: A) => store || (store = factory(...args));

  return React.createContext<BasePageStoreContext<S, A>>({
    getStore: getStore
  });
}

interface CreatePageStoreHookOptions {
  refresh: boolean;
}

export function createPageStoreHook<S extends BasePageStore, A extends any[]> (factory: (...args: A) => S, options: CreatePageStoreHookOptions = {
  refresh: true
}) {
  const storeContext = createPageStoreContext(factory);

  return (...args: A) => {
    const store = useContext(storeContext).getStore(...args);

    if (options.refresh) {
      useEffect(() => {
        if (store.isSleep) {
          store.awake();
          store.refresh && store.refresh();
        }
        return () => store.sleep();
      }, [store]);
    }

    return store;
  };
}
