import React, {CSSProperties, PropsWithChildren} from "react";
import Spinner from "../components/Spinner";

const Loading:React.FC<PropsWithChildren<{
  loading: unknown;
  style?: CSSProperties;
}>> = props => {
  if (!props.loading) {
    return <>
      {props.children}
    </>;
  } else {
    return (
      <div
        className="d-flex align-items-center justify-content-center"
        style={props.style || {height: "100vh"}}
      >
        <Spinner/>
      </div>
    );
  }
};

export default Loading;