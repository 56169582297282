import BasePageStore, {createPageStoreHook, PageState} from "../../BasePageStore";
import {Log} from "../../../helpers/log";
import {action, makeObservable, observable, runInAction} from "mobx";
import BackAppStore from "./BackAppStore";
import axios from "axios";
import NotificationsConfigsStore from "../configs/NotificationsConfigsStore";
import ProgramsConfigsStore from "../configs/ProgramsConfigsStore";
import TracksConfigsStore from "../configs/TracksConfigsStore";

class BackSettingsPageStore extends BasePageStore {
  constructor(private backAppStore: BackAppStore, load = false) {
    super();

    makeObservable(this, {
      load: action,
      refresh: action,
      clear: action,
      state: observable,
      error: observable,
      notificationsConfigsStoreInstance: observable,
      programsConfigsStoreInstance: observable,
      tracksConfigsStoreInstance: observable,
    });

    if (load) {
      this.load().catch(e => this.error = e);
    }

    //TODO some stuff to be stored in LS, maybe some local settings
    this.registerToBeStored();
  }

  notificationsConfigsStoreInstance?:NotificationsConfigsStore;

  get notificationsConfigsStore():NotificationsConfigsStore {
    if (!this.notificationsConfigsStoreInstance)
      this.notificationsConfigsStoreInstance = new NotificationsConfigsStore(this.backAppStore);

    return this.notificationsConfigsStoreInstance;
  }

  programsConfigsStoreInstance?:ProgramsConfigsStore;

  get programsConfigsStore():ProgramsConfigsStore {
    if (!this.programsConfigsStoreInstance)
      this.programsConfigsStoreInstance = new ProgramsConfigsStore(this.backAppStore, this);

    return this.programsConfigsStoreInstance;
  }

  tracksConfigsStoreInstance?:TracksConfigsStore;

  get tracksConfigsStore():TracksConfigsStore {
    if (!this.tracksConfigsStoreInstance)
      this.tracksConfigsStoreInstance = new TracksConfigsStore(this.backAppStore);

    return this.tracksConfigsStoreInstance;
  }

  log = new Log("BackApplicationsPageStore");
  state: PageState = "init";
  error: unknown;

  async load(): Promise<void> {
    this.state = "pending";
    try {
      //TODO real deal here
      await new Promise<void>(resolve => window.setTimeout(() => resolve(), 1000));
      runInAction(() => {
        this.state = "ok";
      });
    } catch (e) {
      if (axios.isCancel(e)) {
        this.log.log("Previous fetchApplications or fetchApplicationsConfig canceled");
      } else {
        runInAction(() => {
          this.error = e;
          this.state = "error";
        });
      }
    }
  }

  async refresh(): Promise<void> {
    this.log.log("Refreshing settings...");

    this.state = "refresh";
    try {
      //TODO real deal here
      await new Promise<void>(resolve => window.setTimeout(() => resolve(), 1000));
      runInAction(() => {
        this.state = "ok";
      });
    } catch (e) {
      if (axios.isCancel(e)) {
        //TODO real deal here
        this.log.log("Previous fetchSettings canceled");
      } else {
        runInAction(() => {
          this.error = e;
          this.state = "error";
        });
      }
    }
  }

  clear() {
    this.error = undefined;
    this.state = "init";
    this.isSleep = false;
    this.notificationsConfigsStoreInstance = undefined;
    this.programsConfigsStoreInstance = undefined;
    this.tracksConfigsStoreInstance = undefined;
  }
}

export const useBackSettingsPageStore = createPageStoreHook<BackSettingsPageStore, ConstructorParameters<typeof BackSettingsPageStore>>((...args: ConstructorParameters<typeof BackSettingsPageStore>) => new BackSettingsPageStore(...args), {refresh: false});

export default BackSettingsPageStore;