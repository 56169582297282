import React, {ReactNode, useEffect, useRef, useState} from "react";
import {CardProps} from "react-bootstrap/Card";
import {Button, Card, Form} from "react-bootstrap";
import useSkFieldValidInvalid from "../useSkFieldValidInvalid";
import SkFieldProps from "./SkFieldProps";
import isSkFieldSubmitted from "./IsSkFieldSubmitted";
import SkFieldDescriptionBlock from "./SkFieldDescriptionBlock";
import SkFieldLabel from "./SkFieldLabel";
import {ApiQuestionRenderedModel} from "../../../services/api/questions/ApiQuestionModel";
import {SkFieldSelectDescriptions, SkFieldSelectValues} from "../SkForm";
import NovariantsMessage from "../NovariantsMessage";

const SkFieldPicker:React.FC<SkFieldProps & {
  // key-values hash similar to select's map of <index,value>
  question: ApiQuestionRenderedModel;
  questions?: ApiQuestionRenderedModel[];
  free?: boolean; // ;D (nuff interesting, just can type their own value)
  noValuesMessage?: ReactNode;
  selectValues: SkFieldSelectValues;
  selectDescriptions: SkFieldSelectDescriptions;
}> = (props) => {
  const {isInvalid, setReallyTouched} = useSkFieldValidInvalid(props);

  const submitted = isSkFieldSubmitted(props);
  const disabled = props.disabled || props.formikProps.isSubmitting;

  const valueStoredInFormik = props.formikProps.values[props.name];
  const selectedValue = props.selectValues[valueStoredInFormik];
  const selectedDescription = props.selectDescriptions[valueStoredInFormik];

  const [expanded, setExpanded] = useState( props.free && valueStoredInFormik && !selectedValue);
  const [freeMode, setFreeMode] = useState( props.free && valueStoredInFormik && !selectedValue);

  const collapsedCardProps:CardProps = isInvalid ? {
    border: "danger",
    bg: "light"
  } : submitted ? {
    border: "success",
    bg: "light"
  } : {
    bg: "light"
  };

  useEffect(() => {
    setReallyTouched(true);
    !freeMode && setExpanded(!valueStoredInFormik);
  }, [valueStoredInFormik]);

  useEffect(() => {
    setReallyTouched(false);
  }, []);

  // this is for reset field value, if parent field is being reset
  // empty selectedValue will indicate that
  useEffect(() => {
    valueStoredInFormik && !selectedValue && !freeMode && props.formikProps.setFieldValue(props.name, "");
  }, [selectedValue]);

  const freeStyleRef = useRef<HTMLInputElement>(null);

  return <Form.Group controlId={`SkFieldPicker_${props.name}`}>
    <SkFieldLabel {...props}/>

    {(disabled || !expanded) ? <Card {...collapsedCardProps} className={disabled ? "bg-disabled" : ""}>
      <Card.Body>
        <Card.Title className={"mb-0"}>
          {selectedValue}
        </Card.Title>
        {selectedDescription ? <p className={"mt-2 mb-0"}><small>{selectedDescription}</small></p> : null}
        {!disabled && <Button variant="dark" className={"mt-3"} size={"sm"} onClick={(e) => {
          e.preventDefault();
          props.formikProps.setFieldValue(props.name, "");
          setExpanded(true);
        }}>
          Change
        </Button>}
      </Card.Body>

    </Card> : null}

    {!disabled && expanded && !freeMode ? <div className={"sk-picker"}>
      {Object.keys(props.selectValues).map(key => (
        <Card key={`${props.name}_${key}`} onClick={() => {
          props.formikProps.setFieldValue(props.name, key);
          setExpanded(false);
        }}>
          <Card.Body>
            <Card.Title className={"mb-0"}>
              {props.selectValues[key]}
            </Card.Title>
            {props.selectDescriptions[key] ? <p className={"mt-2 mb-0"}><small>{props.selectDescriptions[key]}</small></p> : null}
          </Card.Body>
        </Card>
      ))}
      <NovariantsMessage values={props.selectValues} question={props.question} questions={props.questions} noValuesMessage={props.noValuesMessage}/>
      {props.free && <Card onClick={() => {
        setFreeMode(true);
        window.queueMicrotask(() => freeStyleRef.current?.focus());
      }}>
        <Card.Body>
          <Card.Title className={"mb-0"}>
            <b>
              <i>Other</i>
            </b>
          </Card.Title>
        </Card.Body>
      </Card>}
    </div> : null}

    {freeMode ? <Card {...collapsedCardProps} style={{
      display: expanded ? "block" : "none"
    }}>
      <Card.Body>
        <Form.Control
          as={"input"}
          type="text"
          name={props.name}
          value={props.formikProps.values[props.name] || ""}
          onChange={props.formikProps.handleChange}
          isValid={submitted}
          isInvalid={isInvalid}
          disabled={disabled}
          ref={freeStyleRef}
          className={"mb-3"}
        />
        {!disabled && <Button variant="dark" size={"sm"} onClick={(e) => {
          e.preventDefault();
          props.formikProps.setFieldValue(props.name, "");
          setFreeMode(false);
        }}>
          Сhoose from options
        </Button>}
      </Card.Body>
    </Card> : null}

    <SkFieldDescriptionBlock
      description={props.description}
      isInvalid={isInvalid}
      errorText={`${props.formikProps.errors[props.name]}`}
    />
  </Form.Group>;
};

export default SkFieldPicker;
