import {action, makeObservable, observable, runInAction} from "mobx";
import BasePageStore, {PageState} from "../BasePageStore";
import {campaignsStore} from "../singletones/CampaignsStore";
import ApiCampaignModel from "../../services/api/campaigns/ApiCampaignModel";
import ApiApplicationFormModel from "../../services/api/forms/ApiApplicationFormModel";
import {Log} from "../../helpers/log";

class ApplyPageStore extends BasePageStore {
  log = new Log("ApplyPageStore");
  state:PageState | "empty" = "init";
  error: unknown;

  constructor(load = false) {
    super();

    makeObservable(this, {
      state: observable,
      error: observable,
      currentCampaign: observable,
      applyForm: observable,
      load: action,
    });

    if (load) {
      this.load();
    }
  }

  currentCampaign: ApiCampaignModel | null = null;
  applyForm: ApiApplicationFormModel | null = null;

  async load():Promise<void> {
    // get current campaign
    try {
      this.currentCampaign = await campaignsStore.getCurrentCampaign();

      if (!this.currentCampaign) {
        this.error = new Error("No active campaigns at the moment");
        runInAction(() => {
          this.state = "empty";
        });
        return;
      }
      // get apply form
      this.applyForm = this.currentCampaign?.reg_form || null;
      if (this.applyForm) {
        // check we have questions to render
        if (this.applyForm?.questions.length) {
          runInAction(() => {
            this.state = "ok";
          });
          return;
        } else {
          this.error = new Error("Apply form has no questions");
          return;
        }
      } else {
        this.error = new Error("Apply form has no questions");
        return;
      }
    } catch (e) {
      runInAction(() => {
        this.state = "error";
        this.error = e;
      });
      return;
    }
  }
}

export default ApplyPageStore;