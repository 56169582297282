import {Col, Container, Dropdown, Nav, Row} from "react-bootstrap";
import {BoxArrowUpLeft, CalendarCheck, DoorClosed, GraphUp, PersonCircle} from "react-bootstrap-icons";
import {NavLink, useRouteMatch} from "react-router-dom";
import React, {useCallback, useContext, useMemo} from "react";
import {observer} from "mobx-react-lite";
import {useAuth} from "../../../stores/AuthStore";
import {BackAppStoreContext} from "../../../pages/backoffice/BackApp";
import ErrorBoundary from "../../ErrorBoundary";
import { pageInfo as applicationPageInfo} from "../../../pages/backoffice/BackApplicationsPage";
import { pageInfo as reportsPageInfo } from "../../../pages/backoffice/BackReportsPage";
import { pageInfo as settingsPageInfo } from "../../../pages/backoffice/BackSettingsPage";
import {ApiEducationTypeModel} from "../../../services/api/campaigns/ApiCampaignModel";
import Logo from "../../Logo";
import {SkApplyHistory} from "../../../const/SkApplyHistory";

const Navigation:React.FC = observer(() => {
  const authStore = useAuth();
  const backAppStore = useContext(BackAppStoreContext);
  const match = useRouteMatch<{id?: string, eduId?: string, page?: string}>("/admin/:id/:eduId/:page?");
  const handleLogout:React.MouseEventHandler = useCallback(() => {
    authStore.logout();
  }, []);


  const campaignSelector = backAppStore && backAppStore.campaignId ? <Dropdown>
    <Dropdown.Toggle id="dropdown-change-campaign" variant={"link"}>
      <CalendarCheck className={"mr-1"}/> <span className={"d-none d-xl-inline"}>{backAppStore?.campaign?.name}</span>
    </Dropdown.Toggle>
    <Dropdown.Menu>
      {backAppStore?.campaign?.education_types?.filter(et => et.id !== backAppStore?.educationTypeId).map((et: ApiEducationTypeModel) =>
        <Dropdown.Item key={et.id} onClick={() => SkApplyHistory.push(`/admin/${backAppStore?.campaignId}/${et.id}/${match?.params.page ? match?.params.page : ""}`)}>
          Switch to {et.name}
        </Dropdown.Item>)
      }
      {backAppStore?.educationTypeId !== "all" && <Dropdown.Item onClick={() => SkApplyHistory.push(`/admin/${backAppStore?.campaignId}/all/${match?.params.page ? match?.params.page : ""}`)}>
          Switch to all degree levels
      </Dropdown.Item>}
      <Dropdown.Divider/>
      <Dropdown.Item onClick={() => backAppStore?.clearSelection(() => SkApplyHistory.push("/admin"))}>
        <BoxArrowUpLeft className={"mr-1"}/> Select different campaign
      </Dropdown.Item>
    </Dropdown.Menu>
  </Dropdown> : null;

  const redashUrl = useMemo(() => `${process.env.REACT_APP_STATS_ROOT}`.replace("%campaignPk%", backAppStore?.campaignId || ""), [backAppStore?.campaignId]);

  const navigation = backAppStore && backAppStore.campaignId ? <Nav variant="pills">
    <Nav.Item>
      <NavLink to={`/admin/${backAppStore?.campaignId}/${backAppStore?.educationTypeId}/applicants`} className="nav-link">{applicationPageInfo.icon} <span className={"d-none d-lg-inline"}>{applicationPageInfo.name}</span></NavLink>
    </Nav.Item>
    <Nav.Item>
      <NavLink to={`/admin/${backAppStore?.campaignId}/${backAppStore?.educationTypeId}/reports`} className="nav-link">{reportsPageInfo.icon} <span className={"d-none d-lg-inline"}>{reportsPageInfo.name}</span></NavLink>
    </Nav.Item>
    <Nav.Item>
      <NavLink to={`/admin/${backAppStore?.campaignId}/${backAppStore?.educationTypeId}/settings`} className="nav-link">{settingsPageInfo.icon} <span className={"d-none d-lg-inline"}>{settingsPageInfo.name}</span></NavLink>
    </Nav.Item>
    <Nav.Item>
      <Nav.Link href={redashUrl}>
        <GraphUp/> <span className={"d-none d-lg-inline"}>Stats</span>
      </Nav.Link>
    </Nav.Item>
  </Nav> : null;

  const headerClass = backAppStore && backAppStore.campaignId ? "sk-header" : "sk-header-light";

  const logo = backAppStore && !backAppStore.campaignId ? <div className="mr-3 flex-grow-1">
    <Logo color={"blue"} style={{height: "1em"}}/>
  </div> : null;

  return (
    <div className={headerClass}>
      <ErrorBoundary errorLevel={"Navigation"}>
        <Container>
          <Row className="mt-4 mb-4">
            <Col>
              <div className="d-flex align-items-baseline sk-navigation-menu">
                {logo}
                <div className="mr-3 flex-grow-1">
                  {campaignSelector}
                </div>
                <div className="mr-3">
                  {navigation}
                </div>
                <div>
                  <Dropdown alignRight={true}>
                    <Dropdown.Toggle id="dropdown-user-actions" variant={"link"}>
                      <PersonCircle/>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Header>
                        {authStore.userName ? <h4 className={"sk-username"}>{authStore.userName}</h4> : null}
                        <div className={"text-muted"}>{authStore.user?.username}</div>
                      </Dropdown.Header>
                      <Dropdown.Divider/>
                      <Dropdown.Item href="#" onClick={handleLogout}>
                        <DoorClosed className={"mr-1"}/> Sign out
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </ErrorBoundary>
    </div>
  );
});

export default Navigation;
