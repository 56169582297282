import {makeAutoObservable, observable, runInAction} from "mobx";
import {Log} from "../../helpers/log";
import React, {useContext} from "react";
import ApiApplicationModel from "../../services/api/applications/ApiApplicationModel";
import {campaignsStore} from "./CampaignsStore";
import {authStore} from "../AuthStore";
import {ApiUsersMe, APIUsersMeResponse} from "../../services/api/users/ApiUsersMe";

class ApplicationsStore {
  log = new Log("ApplicationsStore");

  attempts = 0;

  constructor() {
    makeAutoObservable(this);
  }

  async fetchApplications() {
    this.log.log("fetchApplications");

    this.attempts++;

    try {
      const response:APIUsersMeResponse = (await ApiUsersMe()).data;
      this.log.log("ApiUsersMe (it has applications) responded with", response);

      runInAction(() => {
        this.applications.replace(response.applications);
      });

      return;
    } catch (e) {
      this.log.error(e, "Error fetching applications", (e as Error)?.message ? (e as Error)?.message : "no message provided");
      runInAction(() => {
        this.error = e;
      });
      throw e;
    }
  }

  async getCurrentApplication():Promise<ApiApplicationModel|null> {
    if (authStore.isAuthenticated) {
      const currentCampaign = await campaignsStore.getCurrentCampaign();
      if (currentCampaign) {
        if (this.applications.length) {
          this.log.log("already have Applications");
          const application = this.applications.find(a => a.campaign === currentCampaign.id) || null;
          if (application) {
            return application;
          } else {
            this.log.error("can't find current application in applications");
          }
        } else {
          this.log.log("don't have Applications, need to fetch");
          if (this.attempts <= 5) {
            await this.fetchApplications();
            return await this.getCurrentApplication();
          } else {
            this.log.log("made 5 attempts to load applications, failed");
            return null;
          }
        }
      } else {
        this.log.error("campaignsStore.getCurrentCampaign returned null");
      }
    } else {
      this.log.error("authStore.isAuthenticated returned false");
    }

    return null;
  }

  applications = observable.array<ApiApplicationModel>();

  setApplications(applications: ApiApplicationModel[]) {
    this.applications.replace(applications);
  }

  error: null | unknown = null;
}

export const applicationsStore = new ApplicationsStore();

const ApplicationsStoreContext = React.createContext(applicationsStore);

const useApplications = () => useContext(ApplicationsStoreContext);

export default useApplications;
