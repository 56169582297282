import React, {useContext, useMemo} from "react";
import ErrorBoundary from "../components/ErrorBoundary";
import {Alert, Col, Container, Form, Row} from "react-bootstrap";
import Logo from "../components/Logo";
import {useLocation, useParams} from "react-router";
import queryString from "query-string";
import RecommendationForRecommenderPageStore from "../stores/pages/RecommendationForRecommenderPageStore";
import {observer} from "mobx-react-lite";
import Loading from "./Loading";
import {useFormik} from "formik";
import {runInAction} from "mobx";
import * as yup from "yup";
import SkFieldText from "../components/SkForms/questionTypes/SkFieldText";
import SkSubmitButton from "../components/SkForms/SkSubmitButton";

const RecommendationForRecommenderPageStoreContext = React.createContext<{
  store: RecommendationForRecommenderPageStore;
}>({
  store: {} as RecommendationForRecommenderPageStore,
});

const ErrorsFilter:React.FC = observer((props) => {
  const {store} = useContext(RecommendationForRecommenderPageStoreContext);

  if (store.axiosError && store.axiosError.response?.status === 401) {
    return <Alert variant={"danger"}>You are not authorised to see this page.</Alert>;
  } else if (store.axiosError && store.axiosError.response?.status === 400 && store.apiErrorMessage) {
    return <Alert variant={"danger"}>{store.apiErrorMessage}</Alert>;
  } else {
    return <>{props.children}</>;
  }
});

const RecommendationForRecommenderForm:React.FC = observer(() => {
  const {store} = useContext(RecommendationForRecommenderPageStoreContext);
  const formik = useFormik({
    initialValues: {
      recommendation_text: store.recommendation ? store.recommendation.recommendation_text : "",
    },
    validationSchema: yup.object().shape({
      recommendation_text: yup.string().required("Please provide a recommendation text"),
    }),
    onSubmit: (values, helpers) => {
      runInAction(() => store.recommendation && (store.recommendation.recommendation_text = values.recommendation_text));
      store.save().finally(() => {
        helpers.setSubmitting(false);
      });
    }
  });

  const loading = !(store.state === "ok" || store.state === "pending");

  return <Loading loading={loading}>
    {store.recommendationCancelled ? <>
      <h1>Recommendation request cancelled</h1>
      <p>{store.recommendationCancelled}</p>
    </> : <>
      <h1>Hello, {store.recommendation?.recommender_print_name}</h1>
      <p>You received this link via email {store.recommendation?.recommender_email} because {store.recommendation?.applicant_print_name} ({store.recommendation?.applicant_email}) requested a recommendation from you. Please, provide one in the form below and submit it.</p>
      {store.locked ? (
        <Alert variant={"success"}>Your recommendation has been successfully submitted. Thank you!</Alert>
      ) : (
        <Form onSubmit={formik.handleSubmit}>
          <SkFieldText formikProps={formik} name={"recommendation_text"} multiline={true} style={{height: 300}} label={"Recommendation Text"}/>
          <SkSubmitButton formikProps={formik} caption={"Submit"}/>
        </Form>
      )}
    </>}
  </Loading>;
});

const RecommendationForRecommenderPage:React.FC = observer(() => {
  const {id} = useParams<{id?: string}>();
  const location = useLocation();
  const qs: {token?: string} = useMemo(() => {
    try {
      return queryString.parse(location.search);
    } catch {
      return {};
    }
  }, [location.search]);
  const token = useMemo(() => qs.token ? qs.token : "", [qs.token]);
  const store = useMemo(() => id ? new RecommendationForRecommenderPageStore(token, id) : undefined, [token, id]);

  return <ErrorBoundary errorLevel={"RecommendationPage"}>
    <Container>
      <Row className="mt-4 mb-4">
        <Col>
          <div className="d-flex align-items-baseline sk-navigation-menu">
            <div className="ml-3 mr-3 flex-grow-1">
              <Logo color={"blue"} style={{height: "1em"}}/>
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          {store ? <>
            <RecommendationForRecommenderPageStoreContext.Provider value={{store: store}}>
              <ErrorsFilter>
                <RecommendationForRecommenderForm/>
              </ErrorsFilter>
            </RecommendationForRecommenderPageStoreContext.Provider>
          </> : null}
        </Col>
      </Row>
    </Container>
  </ErrorBoundary>;
});

export default RecommendationForRecommenderPage;