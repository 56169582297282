import axios, {AxiosResponse, CancelTokenSource} from "axios";
import AxiosApiInstance, {APIBase} from "../../../AxiosApiInstance";
import {ApiRecommendationBackModel} from "../../../recommendations/ApiRecommendationModel";

export let ApiBackCampaignsRecommendationsGetSource: CancelTokenSource | undefined;

export const ApiBackCampaignsRecommendationsGet: (campaignPk: string, recommendationBlindId: string) => Promise<AxiosResponse<ApiRecommendationBackModel>> = (campaignPk, recommendationBlindId) => {
  ApiBackCampaignsRecommendationsGetSource = axios.CancelToken.source();

  return AxiosApiInstance.get(`${APIBase}back/campaigns/${campaignPk}/recommendations/${recommendationBlindId}/`, {
    cancelToken: ApiBackCampaignsRecommendationsGetSource.token
  });
};

export default ApiBackCampaignsRecommendationsGet;