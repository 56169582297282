import {AxiosResponse} from "axios";
import AxiosApiInstance, {APIBase} from "../../AxiosApiInstance";
import {ApiCampaignShortModel} from "../../campaigns/ApiCampaignModel";

export const ApiBackCampaignsGet: (campaignId: string, education_type?: string) => Promise<AxiosResponse<ApiCampaignShortModel>> = (campaignId, education_type) => {
  return AxiosApiInstance.get(`${APIBase}back/campaigns/${campaignId}/`, {
    params: {
      ...(education_type ? {education_type: education_type} : {})
    }
  });
};

export default ApiBackCampaignsGet;