import React from "react";
import {Col, Container, Row} from "react-bootstrap";
import Loading from "./Loading";

const LoadingPage: React.FC = () => (
  <Container>
    <Row>
      <Col>
        <Loading loading={true}/>
      </Col>
    </Row>
  </Container>
);

export default LoadingPage;