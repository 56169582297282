import ApiAnswerPostModel, {ApiAnswerGetModel} from "../services/api/applications/ApiAnswerModel";
import deleteCode from "../const/deleteCode";

// TODO это более advanced-функция, её бы хорошо использовать вместо того огорода с "DELETE" в сторе

const AnswersDiff = <A extends ApiAnswerPostModel>(fresh: A[], old?: ApiAnswerGetModel[]): A[] => {
  if (!old) return  fresh;

  const result: A[] = [];

  // сначала проходимся по новым значениям
  fresh.forEach(freshAnswer => {
    const oldAnswer = old.find(oa => oa.question === freshAnswer.question && oa.answer_number === freshAnswer.answer_number);

    if (!oldAnswer) {
      // Такого ответа даже не было, у нас точно свежак
      result.push(freshAnswer);
    } else {
      // Такой был, надо сравнить значения и пушить если оно новое
      if (freshAnswer.answer_value !== oldAnswer.answer_value) result.push(freshAnswer);
    }
  });

  // теперь нужно проверить на удаление старые
  old.forEach(oldAnswer => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const {id, ...oldAnswerWithoutId} = oldAnswer;
    const freshAnswer = fresh.find(fa => fa.question === oldAnswer.question && fa.answer_number === oldAnswer.answer_number);

    if (!freshAnswer) {
      // На этот СТАРЫЙ ответ нету нового! Кажется это удаление
      result.push(Object.assign({}, oldAnswerWithoutId, {answer_value: deleteCode}) as A);
    }
  });

  return result;
};

export default AnswersDiff;