import React from "react";
import {Button} from "react-bootstrap";

export const PayButton:React.FC<{
    url: string;
    termsAccepted: boolean;
    caption: string;
    variant: string;
}> = ({url, termsAccepted, caption, variant}) => {
    const className = `t-4 mb-4 btn btn-${variant} btn-block btn-lg`;

    return <>
        {termsAccepted ? <a className={className} href={url}>{caption}</a> : <Button className={className} disabled variant={variant}>{caption}</Button>}
    </>
}