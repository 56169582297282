import AxiosApiInstance, {APIBase} from "../../../../AxiosApiInstance";
import ApiProgramConfigGet from "./ApiProgramConfigGet";
import {AxiosResponse} from "axios";

// это апи-метод по новому типу
// вместо обязательных параметров он выбрасывает ошибку, если чего-то не хватает

const ApiBackCampaignsConfigsProgramsIdPatch
  = (campaign_pk: string, payload: Omit<ApiProgramConfigGet, "educational_tracks">)
  :Promise<AxiosResponse<ApiProgramConfigGet>> => {
    if (!payload.id) throw new Error("ApiBackCampaignsConfigsProgramsIdPatch: no-id error");

    return AxiosApiInstance.patch(`${APIBase}back/campaigns/${campaign_pk}/configs/programs/${payload.id}/`, payload);
  };

export default ApiBackCampaignsConfigsProgramsIdPatch;