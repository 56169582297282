import {ApiQuestionRenderedModel} from "../../services/api/questions/ApiQuestionModel";
import {SkFormValues} from "../../components/SkForms/SkForm";
import {ApiAnswerGetModel} from "../../services/api/applications/ApiAnswerModel";
import {toJS} from "mobx";

const initialValuesFromAnswersOrValue = (questions: ApiQuestionRenderedModel[], answers: ApiAnswerGetModel[]) => {
  const initialValues: SkFormValues = {};

  questions.forEach(q => {
    let questionValue:string|string[] = "";
    const questionAnswers = toJS(answers.filter(a => a.question === q.id));

    if (questionAnswers.length) {
      // IMPORTANT before mapping values, sort them from 1 to 2, so they will be with the same answer_number
      const mappedValues = questionAnswers.sort((a1, a2) => a1.answer_number - a2.answer_number).map(a => a.answer_value);

      switch (q.display_type) {
      default:
        // for most fields it's just a string value
        questionValue = mappedValues.join(",");
        break;
      case "upload":
        // for uploads it's just file id's array
        questionValue = mappedValues;
        break;
      }
    }
    else if (q.value) {
      questionValue = q.value;
    }
    else {
      switch (q.display_type) {
      case "upload":
        questionValue = [];
        break;
      } 
    }

    initialValues[q.id] = questionValue;
  });

  return initialValues;
};

export default initialValuesFromAnswersOrValue;