import {makeAutoObservable, observable} from "mobx";
import {ToastProps} from "react-bootstrap";
import {v4 as uuid} from "uuid";
import React, {useContext} from "react";

interface ToastModel {
  props: ToastProps;
  header?: JSX.Element | string;
  body: JSX.Element | string;
  variant?: "danger" | "warning" | "success";
}

const DEBUG = process.env.REACT_APP_DEBUG_TOASTS === "true";

export interface StoredToastModel extends ToastModel {
  id: string;
}

class ToastsStore {
  toasts = observable.array<StoredToastModel>([]);

  addToast(toast: ToastModel) {
    this.toasts.push({
      id: uuid(),
      ...toast
    });
  }

  removeToast(id: string) {
    const t = this.toasts.find(t => t.id === id);

    if (t) {
      this.toasts.remove(t);
    }
  }

  constructor() {
    makeAutoObservable(this);

    if (process?.env?.NODE_ENV === "development") {

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (window as any).SKToasts = {
        addTestToast: (variant?: "danger" | "warning" | "success") => {
          this.addToast({
            props: {
              animation: true
            },
            body: Math.random() > .5 ? "Test toast" : "Different test toast text",
            header: Math.random() > .5 ? "Header" : "",
            variant: variant
          });
        }
      };
    }

    if (DEBUG) {
      (async () => {
        const loremIpsum = await import("lorem-ipsum");
        const TO = window.setInterval(() => {
          if (!this) window.clearTimeout(TO); else {
            if (this.toasts.length < 3) {
              this.addToast({
                props: {},
                body: loremIpsum.loremIpsum(),
                header: Math.random() > .5 ? loremIpsum.loremIpsum({
                  count: 1,
                  units: "word"
                }) : ""
              });
            }
          }
        }, (Math.random() * 2 + 1) * 1000);
      })();
    }
  }
}

const toastsStore = new ToastsStore();

export const useToastsStore = () => useContext(React.createContext(toastsStore));

export default toastsStore;