import React from "react";
import {observer} from "mobx-react-lite";
import {Dropdown} from "react-bootstrap";
import {Link} from "react-router-dom";
import BtnProps from "../../helpers/components/factories/BtnProps";
import {ApiCampaignShortModel} from "../../services/api/campaigns/ApiCampaignModel";
import ErrorBoundary from "../ErrorBoundary";

const CampaignsSelector:React.FC<{
  campaigns?: ApiCampaignShortModel[];
}> = observer(({
  campaigns
}) =>
  <ErrorBoundary errorLevel={"CampaignsSelector"}>{
    campaigns?.map(campaign => {
      if (campaign.education_types && campaign.education_types.length > 0) {
        return <Dropdown key={campaign.id} className={"mb-4 mt-4"}>
          <Dropdown.Toggle
            variant="primary"
            size={"lg"}
            id={`select_campaign_${campaign.id}`}
          >
            {campaign.name}
          </Dropdown.Toggle>
          <Dropdown.Menu>
            {campaign.education_types?.map(educationType => <Dropdown.Item key={educationType.id} to={`/admin/${campaign.id}/${educationType.id}/`} as={Link}>{educationType.name}</Dropdown.Item>)}
            <Dropdown.Item to={`/admin/${campaign.id}/all/`} as={Link}>All</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>;
      } else {
        return <Link
          to={`/admin/${campaign.id}/all/`}
          {...BtnProps({variant: "primary", size: "lg"})}
          className={"mb-4 mt-4"}
        >
          {campaign.name}
        </Link>;
      }
    })
  }</ErrorBoundary>
);

export default CampaignsSelector;