export enum RecommendationMode {
  Blind = "blind", File = "file"
}

export enum RecommendationRequestState {
  NotSent = "not_sent", Sending = "sending", Sent = "sent", Delivered = "delivered", NotDelivered = "not_delivered"
}

export enum RecommendationResponseState {
  NotReceived = "not_received", Received = "received"
}

export interface ApiRecommenderModel {
  position: string;
  first_name: string;
  last_name: string;
  email: string;
}

export interface ApiRecommendationModel {
  id: string;
  application: string;
  question: string;
  mode: RecommendationMode;

  // в зависимости от mode, с АПИ могут прийти рахные варианты двух следующих полей
  recommender: ApiRecommenderModel | undefined;
  upload: string | undefined;

  request_state: RecommendationRequestState;
  response_state: RecommendationResponseState;
  request_sent_at: string;
  response_sent_at: string;
}

export interface ApiRecommendationBackModel extends ApiRecommendationModel {
  recommendation_text?: string;
}