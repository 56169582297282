import React, {useContext} from "react";
import {makeAutoObservable, reaction, runInAction} from "mobx";
import {Log} from "../helpers/log";
import tokenStore, {JWTVerify} from "./singletones/TokenStore";
import {Location, UnregisterCallback} from "history";
import { AxiosResponse } from "axios";
import User from "./models/User";
import JWTPayload from "../interfaces/jwt/JWTPayload";
import {ApiTokenObtain} from "../services/api/token/ApiTokenObtain";
import {ApiUsersMe, APIUsersMeResponse, APIUsersMeResponseSchema} from "../services/api/users/ApiUsersMe";
import {applicationsStore} from "./singletones/ApplicationsStore";
import {SkApplyHistory} from "../const/SkApplyHistory";

type UTMName = "utm_source" | "utm_medium" | "utm_campaign" | "utm_term" | "utm_content";

class UTMStore {
  log = new Log("UTMStore");

  unlistenCallback?: UnregisterCallback;

  listen() {
    this.unlistenCallback = SkApplyHistory.listen((location) => {
      this.syncUTMs(location);
    });
  }

  unlisten() {
    this.unlistenCallback && this.unlistenCallback();
  }

  constructor() {
    makeAutoObservable(this);

    reaction(() => this.UTMJson, (value, previousValue) => {
      if (value !== previousValue) {
        this.log.log("UTM state:", this.UTMs);
      }
    });

    this.syncUTMs(SkApplyHistory.location);
  }

  syncUTMs(location: Location) {
    if (this) { // TODO disposal maybe instead of this check? btw idk how to dispose hostory listeners
      const href = `${window.location.origin}${location.pathname}${location.search}`;

      this.log.log("URL changed", href);

      const urlInstance = new URL(href);

      this.log.log("URL instance", urlInstance);

      let preserve = false;

      const syncUTMPart = (name: UTMName) => {
        const new_value = urlInstance.searchParams.get(name);
        const old_value = this[name];

        if (old_value !== new_value) {
          if (old_value && !new_value) {
            urlInstance.searchParams.set(name, old_value);
            preserve = true;
          } else {
            this[name] = new_value || undefined;
          }
        }
      }

      runInAction(() => {
        syncUTMPart("utm_source");
        syncUTMPart("utm_medium");
        syncUTMPart("utm_campaign");
        syncUTMPart("utm_term");
        syncUTMPart("utm_content");
      });

      if (preserve) {
        location.search = urlInstance.search;
        SkApplyHistory.replace(location);
      }
    }
  }

  clearUTM() {
    this.log.log("dropping all UTMs");

    this.utm_source = undefined;
    this.utm_medium = undefined;
    this.utm_campaign = undefined;
    this.utm_term = undefined;
    this.utm_content = undefined;

    const location = SkApplyHistory.location;

    const href = `${window.location.origin}${location.pathname}${location.search}`;

    const urlInstance = new URL(href);
    urlInstance.searchParams.delete("utm_source");
    urlInstance.searchParams.delete("utm_medium");
    urlInstance.searchParams.delete("utm_campaign");
    urlInstance.searchParams.delete("utm_term");

    urlInstance.searchParams.delete("utm_content");

    location.search = urlInstance.search;

    SkApplyHistory.replace(location);
  }

  get UTMJson() {
    return JSON.stringify(this.UTMs);
  }

  get UTMs() {
    return {
      utm_source: this.utm_source,
      utm_medium: this.utm_medium,
      utm_campaign: this.utm_campaign,
      utm_term: this.utm_term,
      utm_content: this.utm_content,
    };
  }

  get hasUTM() {
    return !!this.utm_source || !!this.utm_medium || !!this.utm_campaign || !!this.utm_term || !!this.utm_content;
  }

  utm_source?: string;
  utm_medium?: string;
  utm_campaign?: string;
  utm_term?: string;
  utm_content?: string;
}

export class AuthStore {
  log = new Log("AuthStore");

  state: "init" | "ok" | "pending" | "error" = "init";

  user: User | null = null;

  utmStore = new UTMStore();

  get userName() {
    if (!this.user) return undefined;
    return this.user.firstName ? `${this.user.firstName} ${this.user.lastName}` : `${this.user.username.split("@")[0]}`;
  }

  error: unknown;

  constructor() {
    makeAutoObservable(this);

    reaction(() => this.user === null, () => {
      if (this.user === null) {
        this.utmStore.listen();
      } else {
        this.utmStore.unlisten();
      }
    }, {fireImmediately: true});

    if (tokenStore.token) {
      this.log.log("initial user fetching");

      this.fetchUser().then((user) => {
        this.state = "ok";
        this.log.log(`initial user fetched, he's name is ${user?.username}`);
      }).catch(e => {
        runInAction(() => {
          this.user = null;
          this.error = e;
          this.state = "error";
        });
      });

    } else {
      this.state = "ok";
    }
  }

  get isAuthenticated() {
    return this.user !== null;
  }

  async fetchUser():Promise<User> {
    const response:AxiosResponse<APIUsersMeResponse> = await ApiUsersMe();

    //throws ValidationError, if something messed up with user payload
    APIUsersMeResponseSchema.validateSync(response.data);

    // set out the current user applications
    applicationsStore.setApplications(response.data.applications);

    const user = new User(response.data);

    runInAction(() => {
      this.user = user;
    });

    return user;
  }

  async processToken(token: string) {
    const tokenPayload:JWTPayload = JWTVerify(token);

    // for API auth headers
    tokenStore.setToken(token);

    const user = await this.fetchUser();

    if (tokenPayload.username !== user.username) {
      // noinspection ExceptionCaughtLocallyJS
      throw "Signed and stored usernames are DIFFERENT! Something's wrong with security. Login failed.";
    }

    this.log.log("fetchUserAndToken: user is valid, creating and saving new one's");

    return;
  }

  async fetchLogin(email: string, password: string, redirect?: () => void):Promise<void> {
    this.state = "pending";

    try {
      // Get token, verify and save it
      const responseTokenObtain = await ApiTokenObtain({
        email: email,
        password: password
      });

      await this.processToken(responseTokenObtain.data.token).then(() => {
        runInAction(() => {
          this.state = "ok";
        });
      });

      if (redirect) {
        redirect();
      }
    } catch (e) {
      runInAction(() => {
        this.user = null;
        tokenStore.clearToken();

        this.state = "error";
      });

      throw e;
    }
  }

  //TODO that supposed to be async to destroy session on server
  logout() {
    this.log.log("Logging out...");

    tokenStore.clearToken();
    this.user = null;

    this.log.log("Logged out!");
  }
}

export const authStore = new AuthStore();

// auth helpers
export const AuthContext = React.createContext<AuthStore>(authStore);
export const useAuth = () => useContext(AuthContext);
