import ApiEducationalTrack from "../tracks/ApiEducationalTrack";
import * as yup from "yup";

interface ApiProgramConfigGet {
  id: string;
  name: string;
  visible: boolean;
  sort: number;
  educational_tracks: ApiEducationalTrack[];
  exam_date: string | null;
  education_type: string;
  can_be_deleted: boolean;
}

export const ApiProgramConfigSchema = yup.object().shape({
  name: yup.string().required(),
  education_type: yup.string().required(),
});

export default ApiProgramConfigGet;