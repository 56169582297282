import {useEffect} from "react";
import {BagPasser} from "./useBag";

function useBagPass<B>(passBag: BagPasser<B> | undefined, bag: B, deps: any[] = []) {
  return useEffect(() => {
    if (passBag) {
      passBag(bag);

      return (() => {
        passBag(undefined);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [passBag, bag, ...deps]);
}

export default useBagPass;
