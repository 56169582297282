import React from "react";
import * as yup from "yup";
import {Formik, FormikHelpers} from "formik";
import {Alert, Button, Form, Spinner} from "react-bootstrap";
import {setFormikEmailValue} from "../helpers/utils";

const loginPayloadSchema = yup.object({
  email: yup.string().email("Incorrect email").required("Type in your email"),
  password: yup.string().min(5, "Password is too short").required("Type in your password")
});

export interface LoginFormValues {
  email: string;
  password: string;
}

interface P {
  onSubmit: (
    values:LoginFormValues,
    actions: FormikHelpers<LoginFormValues>,
  ) => void
}

export const LoginFormStatus: React.FC<{
  status?: string;
}> = ({status}) => (
  <>
    {status && (
      <Alert variant={"danger"}>
        {status}
      </Alert>
    )}
  </>
);

export const LoginForm:React.FC<P> = ({onSubmit}) => {
  const initialEmail = window.localStorage.getItem("lastLoginEmail") || "";

  return   <>
    <Formik
      validationSchema={loginPayloadSchema}
      onSubmit={onSubmit}
      initialValues={{
        email: initialEmail,
        password: "",
      }}
    >
      {({
        handleSubmit,
        handleChange,
        setFieldValue,
        values,
        touched,
        errors,
        isSubmitting,
        status
      }) => (
        <Form noValidate onSubmit={handleSubmit}>
          <Form.Group controlId="loginFormEmail">
            <Form.Label>Email</Form.Label>
            <Form.Control
              type="email"
              name="email"
              value={values.email}
              onChange={e => setFormikEmailValue(e, setFieldValue)}
              isValid={touched.email && !errors.email}
              isInvalid={!!errors.email}
              disabled={isSubmitting}
            />
            {!errors.email && <Form.Text className="text-muted">
                Email you've provided while apply
            </Form.Text>}
            {errors.email && <Form.Control.Feedback type="invalid">
              {errors.email}
            </Form.Control.Feedback>}
          </Form.Group>

          <Form.Group controlId="loginFormPassword">
            <Form.Label>Password</Form.Label>
            <Form.Control
              type="password"
              name="password"
              value={values.password}
              onChange={handleChange}
              isValid={touched.password && !errors.password}
              isInvalid={!!errors.password}
              disabled={isSubmitting}
            />
            <Form.Control.Feedback type="invalid">
              {errors.password}
            </Form.Control.Feedback>
          </Form.Group>

          <LoginFormStatus status={status}/>

          <Button variant="primary" type="submit" disabled={isSubmitting} block size="lg">
            {isSubmitting
              ? <><Spinner
                as="span"
                animation="border"
                role="status"
                aria-hidden="true"
              /></>
              : "Sign in"
            }
          </Button>
        </Form>
      )}
    </Formik>
  </>;

};
