import React from "react";
import {
  RecommendationResponseState
} from "../services/api/recommendations/ApiRecommendationModel";
import {Badge} from "react-bootstrap";

const RecommendationResponseStatusBadge:React.FC<{responseState: RecommendationResponseState}> = ({responseState}) => {
  if (responseState === RecommendationResponseState.Received) return <Badge variant="success">Received</Badge>;
  if (responseState === RecommendationResponseState.NotReceived) return <Badge variant="secondary">Not Received</Badge>;
  return <Badge variant="secondary">{responseState}</Badge>;
};

export default RecommendationResponseStatusBadge;