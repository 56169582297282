import AxiosApiInstance, {APIBase} from "../../../../AxiosApiInstance";
import {AxiosResponse} from "axios";
import ApiEducationalTrack from "./ApiEducationalTrack";

const ApiBackCampaignsConfigsTracksIdPost
  = (campaign_pk:string, payload: Omit<ApiEducationalTrack, "id">)
  :Promise<AxiosResponse<ApiEducationalTrack>> => {
    return AxiosApiInstance.post(`${APIBase}back/campaigns/${campaign_pk}/configs/tracks/`, payload);
  };

export default ApiBackCampaignsConfigsTracksIdPost;