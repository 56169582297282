import {ButtonProps} from "react-bootstrap";

const BtnProps = (props: ButtonProps) => {
  let className = "btn";

  if (props.active) {
    className = `${className} active`;
  }

  if (props.block) {
    className = `${className} btn-block`;
  }

  if (props.block) {
    className = `${className} btn-block`;
  }

  if (props.variant) {
    className = `${className} btn-${props.variant}`;
  }

  if (props.size) {
    className = `${className} btn-${props.size}`;
  }

  if (props.disabled) {
    className = `${className} disabled`;
  }

  if (props.className) {
    className = `${className} ${props.className}`;
  }

  return {
    className: className
  };
};

export default BtnProps;