import React, {useMemo} from "react";
import {observer} from "mobx-react-lite";
import {Table} from "react-bootstrap";
import {ApplicationRenderedModelUTMS} from "../services/api/applications/ApiApplicationRenderedModel";

export const UTMTable: React.FC<{
  utms?: ApplicationRenderedModelUTMS;
}> = observer(({utms}) => {
  const show = useMemo(() => {
    return utms && (utms.utm_source || utms.utm_medium || utms.utm_campaign || utms.utm_term || utms.utm_content);
  }, [utms, utms?.utm_source, utms?.utm_medium, utms?.utm_campaign, utms?.utm_term, utms?.utm_content]);

  if (!show) return null
  else return utms ? <>
    <h3>UTM</h3>
    <Table>
      <tbody>
      {utms.utm_source ? <tr>
        <td>Source</td>
        <td>{utms.utm_source}</td>
      </tr>: null}
      {utms.utm_medium ? <tr>
        <td>Medium</td>
        <td>{utms.utm_medium}</td>
      </tr>: null}
      {utms.utm_campaign ? <tr>
        <td>Campaign</td>
        <td>{utms.utm_campaign}</td>
      </tr>: null}
      {utms.utm_term ? <tr>
        <td>Term</td>
        <td>{utms.utm_term}</td>
      </tr>: null}
      {utms.utm_content ? <tr>
        <td>Content</td>
        <td>{utms.utm_content}</td>
      </tr>: null}
      </tbody>
    </Table>
  </> : null
});
