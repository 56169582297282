import CampaignNotificationStyle from "./CampaignNotificationStyle";
import * as yup from "yup";

interface ApiNotificationsConfig {
  id: string;
  text: string;
  style: CampaignNotificationStyle;
  campaign: string;
  education_type: string;
  education_program?: string;
  hidden: boolean;
}

export const ApiNotificationsConfigSchema = yup.object().shape({
  text: yup.string().required(),
});

export default ApiNotificationsConfig;