import AxiosApiInstance, {APIBase} from "../AxiosApiInstance";
import {AxiosResponse} from "axios";
import ApiApplicationModel from "./ApiApplicationModel";
import ApiAnswerPostModel from "./ApiAnswerModel";

export interface ApiApplicationsPostRequest {
  answers: Omit<ApiAnswerPostModel, "id">[];
  campaign: string;
  utms?: {
    utm_source?: string;
    utm_medium?: string;
    utm_campaign?: string;
    utm_term?: string;
    utm_content?: string;
  }
}

export interface ApiApplicationsPostResponse extends ApiApplicationModel {
  auth_token: string; // JWT-токен!
}

export const ApiApplicationsPost: (payload?: ApiApplicationsPostRequest) => Promise<AxiosResponse<ApiApplicationsPostResponse>> = (payload) => {
  return AxiosApiInstance.post(APIBase + "applications/", payload);
};
