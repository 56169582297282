import {makeAutoObservable, observable, runInAction, toJS} from "mobx";
import React, {useContext} from "react";
import {v4 as uuid} from "uuid";
import {Log} from "../../helpers/log";
import {ApiUploadsPost, ApiUploadsPostResponseError} from "../../services/api/uploads/ApiUploadsPost";
import {ApiUploadsGet} from "../../services/api/uploads/ApiUploadsGet";
import axios, {AxiosError, AxiosResponse} from "axios";
import ApiUploadModel from "../../services/api/uploads/ApiUploadModel";
import {SkFormMeta} from "../../components/SkForms/SkFormContexts";

type FilesResolve = (value: (string[] | PromiseLike<string[]>)) => void;
type FilesReject = (reason?: Error) => void;
type SimpleResolve = () => void;
type SimpleReject = (reason?: Error) => void;

export class FileStore {
  constructor(public root: FileUploaderStore|null = null, public id = "") {
    makeAutoObservable(this);

    if (this.id) {
      this.get();
    }
  }

  key = uuid();

  state: "uploading" | "pending" | "ok" | "error" = "ok";

  filename = "Filename pending...";
  filename_origin = "Filename pending...";
  url?: string;
  progress = 0;
  error: Error | null = null;

  processFile(response: AxiosResponse<ApiUploadModel>) {
    const data = response.data;

    runInAction(() => {
      this.progress = 100;
      this.filename_origin = data.filename_origin;
      this.filename = data.filename;
      this.url = data.url;
      this.id = data.id;

      this.state = "ok";
    });
  }

  processError(error: Error | unknown, publicMessage?: string) {
    let publicMessageProcessed = publicMessage || "An error occurred during file upload";

    if (axios.isAxiosError(error)) {
      const axiosError = error as AxiosError<ApiUploadsPostResponseError>;

      // У этого API метода довольно странный способ вывода ошибок
      publicMessageProcessed = axiosError.response && Array.isArray(axiosError.response?.data) ? axiosError.response.data.join(", ") : `${axiosError.response?.data}`;
    }

    runInAction(() => {
      this.progress = 0;
      this.filename_origin = "";
      this.filename = "";
      this.url = undefined;
      this.id = "";

      this.state = "error";
      this.error = Error(publicMessageProcessed);
      this.root?.log.error(error);
    });
  }

  get() {
    this.state = "pending";

    ApiUploadsGet(this.id).then((response) => {
      this.processFile(response);
    }).catch((error) => {
      this.processError(error, "Upload not found");
    });
  }

  async post(file: File): Promise<void> {
    const formMeta = this.root?.formMeta;
    const questionId = this.root?.questionId;
    if (!formMeta || !questionId) return;

    this.state = "uploading";
    // set temporary filename during upload process
    this.filename_origin = file.name;

    const formData = new FormData();

    formData.append("file", file);
    formData.append("form", formMeta.form);
    formData.append("question", questionId);
    formData.append("application", formMeta.application);

    try {
      const response = await ApiUploadsPost(formData, (percentCompleted => {
        runInAction(() => {
          this.progress = percentCompleted;
        });
      }));

      this.processFile(response);
    } catch (error: ApiUploadsPostResponseError | unknown) {
      this.processError(error, "An error occurred during file upload");
    }
  }
}

export interface FileUploaderStoreOptions {
  max?: number;
}

export class FileUploaderStore {
  constructor() {
    makeAutoObservable(this);
  }

  log = new Log("FileUploaderStore");

  show = false;

  files = observable.array<FileStore>([]);

  get uploadInProgress() {
    return !!this.files.find(fileStore => fileStore.state === "uploading");
  }

  resolve: FilesResolve | SimpleResolve | null = null;
  reject: FilesReject | SimpleReject | null = null;
  formMeta: SkFormMeta | null = null;
  questionId = "";
  options: FileUploaderStoreOptions | null = null;
  download = false;

  get canUpload() {
    return !!this.formMeta && !!this.questionId;
  }

  openFilesDownloadDialog(files: string[]) {
    return new Promise<void>((resolve, reject) => {
      runInAction(() => {
        this.resolve = resolve;
        this.reject = reject;

        this.download = true;

        files.forEach(id => this.files.push(new FileStore(this, id)));

        this.show = true;
      });
    });
  }

  // Use setUpUploader and resetUploader if you handle UI by yourself

  setUpUploader(files: string[] = [], meta: SkFormMeta, questionId: string, options?: FileUploaderStoreOptions) {
    this.formMeta = meta;
    this.options = options || null;
    this.questionId = questionId;
    files.forEach(id => this.files.push(new FileStore(this, id)));
  }

  resetUploader() {
    this.files.clear();
    this.resolve = null;
    this.reject = null;
    this.formMeta = null;
    this.options = null;
    this.download = false;
    this.questionId = "";
  }

  // Use openFilesUploaderDialog for FileUploader UI

  openFilesUploaderDialog(files: string[] = [], meta: SkFormMeta, questionId: string, options?: FileUploaderStoreOptions) {
    return new Promise<string[]>((resolveFiles, rejectsFiles) => {
      runInAction(() => {
        this.resolve = resolveFiles;
        this.reject = rejectsFiles;

        this.setUpUploader(files, meta, questionId, options);

        this.show = true;
      });
    });
  }

  closeFileUploader(save = true, error?: Error) {
    if (save && this.resolve) {
      this.resolve(toJS(this.files).filter(fileStore => fileStore.state === "ok").map(fileStore => fileStore.id));
    } else {
      this.reject && this.reject(error || new Error("Unknown file upload close reason"));
    }

    this.show = false;

    this.resetUploader();
  }

  async postFile(file: File): Promise<FileStore> {
    if (!this.formMeta) {
      throw (new Error("fileMeta is null"));
    }

    // this will render an empty card with progressbar
    const fileStore = new FileStore(this);

    // now that's all will appear
    this.files.push(fileStore);

    // this will start uploading process and place some filename
    await fileStore.post(file);

    return fileStore;
  }

  delete(fileStore: FileStore) {
    this.files.remove(fileStore);
  }
}

export const fileUploaderStore = new FileUploaderStore();

const FileUploaderContext = React.createContext(fileUploaderStore);

const useFileUploader = () => useContext(FileUploaderContext);

export default useFileUploader;