import {action, computed, makeObservable, observable, runInAction} from "mobx";
import axios, {AxiosError, AxiosInstance} from "axios";
import {
  ApiRecommendationForRecommenderGet,
  ApiRecommendationForRecommenderGetResponse404
} from "../../services/api/recommendations_for_recommender/ApiRecommendationForRecommenderGet";
import ApiRecommendationForRecommenderModel
, {ApiRecommendationForRecommenderSchema} from "../../services/api/recommendations_for_recommender/ApiRecommendationForRecommenderModel";
import BasePageStore, {PageState} from "../BasePageStore";
import {ApiRecommendationForRecommenderPatch} from "../../services/api/recommendations_for_recommender/ApiRecommendationForRecommenderPatch";
import {Log} from "../../helpers/log";

class RecommendationForRecommenderPageStore extends BasePageStore {
  axiosInstance: AxiosInstance;

  log = new Log("RecommendationForRecommenderPageStore");

  locked = false;
  lock() { this.locked = true; }

  error: unknown;
  resetError() {
    this.error = undefined;
    this.state = "ok";
  }
  setError(error: unknown) {
    this.error = error;
  }
  get axiosError():AxiosError|undefined {
    if (axios.isAxiosError(this.error)) {
      return this.error as AxiosError;
    } else {
      return undefined;
    }
  }
  get apiErrorMessage():string|undefined {
    if (this.axiosError) {
      try {
        return (this.axiosError.response?.data as string[]).join(",");
      } catch {
        return undefined;
      }
    } else {
      return undefined;
    }
  }

  setState(state: PageState | string) { this.state = state; }
  state: PageState | string = "init";

  constructor(private token: string, private id: string) {
    super();
    // setup local axios
    const axiosInstance = axios.create();

    this.log.log("creating axios with token", token);

    axiosInstance.interceptors.request.use(
      async config => {
        config.headers["Authorization"] = `Token ${token}`;
        return config;
      });

    this.axiosInstance = axiosInstance;

    makeObservable(this, {
      locked: observable,
      lock: action,
      error: observable,
      axiosError: computed,
      apiErrorMessage: computed,
      resetError: action,
      setError: action,
      setState: action,
      state: observable,
      setRecommendation: action,
      recommendation: observable,
      load: action,
      save: action,
      fetchRecommendation: action,
      patchRecommendation: action,
    });

    this.log.log("RecommendationForRecommenderPageStore created");

    this.load();
  }

  setRecommendation(recommendation?: ApiRecommendationForRecommenderModel) { this.recommendation = recommendation; }
  recommendation?: ApiRecommendationForRecommenderModel;

  recommendationCancelled?: string;

  async load() {
    try {
      this.log.log("trying to load recommendation with id", this.id);
      await this.fetchRecommendation();
      this.setState("ok");
    } catch (e) {
      if (axios.isAxiosError(e)) {
        const ae = e as AxiosError<ApiRecommendationForRecommenderGetResponse404>;
        runInAction(() => {
          this.recommendationCancelled = ae.response?.data?.detail || "Applicant has cancelled their recommendation request";
          this.setState("ok");
        });
      } else {
        console.log(e);
        this.setError(e);
        this.setState("error");
      }
    }
  }

  async save() {
    this.state = "saving";
    try {
      await this.patchRecommendation();
      this.setState("ok");
    } catch (e) {
      console.log(e);
      this.setError(e);
      this.setState("error");
    }
  }

  async fetchRecommendation() {
    const response = await ApiRecommendationForRecommenderGet(this.id, this.axiosInstance);
    if (response.status === 200) {
      const recommendation = ApiRecommendationForRecommenderSchema.validateSync(response.data);
      this.log.log("fetchRecommendation got recommendation", recommendation);
      this.setRecommendation(recommendation);
    } else {
      throw response;
    }
  }

  async patchRecommendation() {
    if (!this.recommendation) throw new Error("no recommendation");
    const response = await ApiRecommendationForRecommenderPatch(this.id, this.axiosInstance, this.recommendation);
    if (response.status === 200) {
      this.setRecommendation(ApiRecommendationForRecommenderSchema.validateSync(response.data));
      this.lock();
    } else {
      throw response;
    }
  }
}

export default RecommendationForRecommenderPageStore;