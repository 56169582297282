import ApiNotificationsConfig
  from "../../../services/api/back/campaigns/configs/notifications/ApiNotificationsConfig";
import PartialBy from "../../../helpers/types/PartialBy";
import {makeAutoObservable, reaction, runInAction} from "mobx";
import ApiBackCampaignsConfigsNotificationsIdPost
  from "../../../services/api/back/campaigns/configs/notifications/ApiBackCampaignsConfigsNotificationsIdPost";
import ApiBackCampaignsConfigsNotificationsIdPatch
  from "../../../services/api/back/campaigns/configs/notifications/ApiBackCampaignsConfigsNotificationsIdPatch";
import ApiBackCampaignsConfigsNotificationsIdDelete
  from "../../../services/api/back/campaigns/configs/notifications/ApiBackCampaignsConfigsNotificationsIdDelete";
import toastsStore from "../../singletones/ToastsStore";
import * as yup from "yup";
import NotificationsConfigsStore from "./NotificationsConfigsStore";

type ApiNotificationsConfigPartial = PartialBy<ApiNotificationsConfig, "id">;

export const ApiNotificationsConfigPartialSchema = yup.object().shape({
  text: yup.string().required(),
  style: yup.string().required(),
  campaign: yup.string().required(),
  education_type: yup.string().required(),
  education_program: yup.string(),
  hidden: yup.boolean().required(),
});

class NotificationsConfigItemStore {
  constructor(private root: NotificationsConfigsStore, public data: ApiNotificationsConfigPartial) {
    makeAutoObservable(this, {
      disposeReactions: false,
      setupReactions: false,
      disposers: false,
    });

    if (data.id) {
      this.savedJson = JSON.stringify(data);
    }


    this.setupReactions();
  }

  disposers: (() => void)[] = [];

  disposeReactions() {
    this.disposers.forEach(d => d());
  }

  setupReactions() {
    this.disposers.push(
      reaction(() => this.json, (json, prevJson) => {
        if (this.hasChanges) {
          runInAction(() => this.state = "pending");
          this.postOrPatch()
            .then(() => runInAction(() => {
              this.state = "ok";
            }))
            .catch(() => runInAction(() => {
              this.state = "error";
              try {
                if (prevJson) {
                  const data = JSON.parse(prevJson);
                  if (ApiNotificationsConfigPartialSchema.isValidSync(data)) {
                    this.data = data as ApiNotificationsConfigPartial;
                  }
                } else {
                  // remove this, cause undefined json is sign of posting attempt
                  setInterval(() => runInAction(() => this.root.notificationsConfigs.remove(this)), 0);
                }
              } catch {
                console.error("Cant rollback notification data during post or update fail", json, prevJson);
              }

              this.state = "error";
              this.data = JSON.parse(prevJson);
              toastsStore.addToast({
                props: {
                  autohide: true,
                  delay: 4300,
                },
                body: "An error occured during Notification update",
                variant: "danger",
              });
            }));
        }
      }, {
        fireImmediately: true
      })
    );
  }

  get saved():boolean {
    return !!this.data.id;
  }

  get json():string {
    return JSON.stringify(this.data);
  }

  savedJson = "";

  get hasChanges() {
    return this.json !== this.savedJson;
  }

  state: "pending" | "ok" | "focused" | "error" | "deleted" = "ok";

  async postOrPatch() {
    const data = (this.saved ? await ApiBackCampaignsConfigsNotificationsIdPatch(this.data as ApiNotificationsConfig) : await ApiBackCampaignsConfigsNotificationsIdPost(this.data)).data;
    runInAction(() => {
      this.data = data;
      this.savedJson = JSON.stringify(data);
    });
  }

  async delete() {
    if (!this.data.id) throw new Error("Error deleting");

    await ApiBackCampaignsConfigsNotificationsIdDelete(this.data.id,this.data.campaign);
    this.disposeReactions();
    runInAction(() => this.state = "deleted");
  }
}

export default NotificationsConfigItemStore;