import React, {
  DetailedHTMLProps,
  MouseEventHandler,
  ReactElement,
  useState
} from "react";
import {ChevronRight} from "react-bootstrap-icons";
import useBagPass from "../helpers/hooks/useBagPass";
import {BagPasser} from "../helpers/hooks/useBag";

export interface CollapseBag {
  expand: () => void;
  collapse: () => void;
}

interface CollapseProps extends DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  collapse?: boolean;
  locked?: boolean;
  passBag?: BagPasser<CollapseBag>;
  children: () => [ReactElement, ReactElement];
}

const Collapse:React.FC<CollapseProps> = ({
  collapse = false,
  locked= false,
  children,
  passBag,
  ...other
}) => {
  const [collapseState, setCollapseState] = useState(collapse);
  const [header, content] = children();
  const handleExpandClick:MouseEventHandler = (e) => {
    e.preventDefault();
    if (!locked) {
      setCollapseState(!collapseState);
    }
  };

  useBagPass(passBag, {
    expand: () => setCollapseState(false),
    collapse: () => setCollapseState(true)
  }, [collapse]);

  return <div {...other}>
    <div className={`sk-collapse-header ${!collapseState && "sk-collapse-header-expanded"}`} onClick={handleExpandClick}>
      <h3 className={"flex-grow-1"}>
        {header}
      </h3>
      <h3>
        <ChevronRight className={"sk-collapse-arrow"}/>
      </h3>
    </div>
    <div style={collapseState ? {display: "none"} : {}}>
      {content}
    </div>
  </div>;
};

export default Collapse;