import React, {useContext} from "react";
import {makeAutoObservable} from "mobx";
class GraduationStepFieldStore {
  graduationStep: any = null

  constructor() {
    makeAutoObservable(this);
    this.graduationStep = null;
  }

  setGraduationStep (graduationStep: string) {
    this.graduationStep = graduationStep
  }

}

export const graduationStepFieldStore = new GraduationStepFieldStore();

// auth helpers
export const GraduationStepFieldContext = React.createContext<GraduationStepFieldStore>(graduationStepFieldStore);
export const useGraduationStepField = () => useContext(GraduationStepFieldContext);
