import React from "react";
import AuthRouteProps from "./AuthRouteProps";
import {observer} from "mobx-react-lite";
import {useAuth} from "../../stores/AuthStore";
import {Redirect, Route} from "react-router-dom";

const AuthRoute:React.FC<AuthRouteProps> = observer(({ children, redirectTo= "/login", ...rest }) => {
  const auth = useAuth();

  if (auth.isAuthenticated) {
    return <Route {...rest}>{children}</Route>;
  } else {
    return  <Redirect
      to={{
        pathname: redirectTo,
        state: { from: rest.location }
      }}
    />;
  }
});

export default AuthRoute;