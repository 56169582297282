import BasePageStore, {createPageStoreHook, PageState} from "../../BasePageStore";
import {Log} from "../../../helpers/log";
import {action, makeObservable, observable, reaction, runInAction} from "mobx";
import BackAppStore from "./BackAppStore";
import {
  ApiBackCampaignsReportsGet,
  ApiBackCampaignsReportsGetSource
} from "../../../services/api/back/campaigns/reports/ApiBackCampaignsReportsGet";
import ApiBackReportModel, {ApiBackReportModelSchemaPartial} from "../../../services/api/back/campaigns/reports/ApiBackReportModel";
import {
  ApiBackCampaignsReportsAvailableGet,
  ApiBackCampaignsReportsAvailableGetParams,
  ApiReportAvailable
} from "../../../services/api/back/campaigns/reports/ApiBackCampaignsReportsAvailableGet";
import {ApiBackCampaignsReportsPost} from "../../../services/api/back/campaigns/reports/ApiBackCampaignsReportsPost";
import ReportStore from "../reports/ReportStore";
import axios from "axios";
import {ApiBackCampaignsApplicationsGetSource} from "../../../services/api/back/campaigns/applications/ApiBackCampaignsApplicationsGet";

class BackReportsPageStore extends BasePageStore {
  log = new Log("BackReportsPageStore");
  state: PageState = "init";
  error: unknown;

  constructor(public backAppStore: BackAppStore, load = false) {
    super();
    makeObservable(this, {
      state: observable,
      error: observable,
      processPostReport: action,
      load: action,
      refresh: action,
      clear: action,
    });

    if (load) {
      this.load().catch(e => this.error = e);
    }

    //reload on campaign change!
    reaction(() => `${this.backAppStore.campaignId}${this.backAppStore.educationTypeId}`, () => {
      this.log.log("campaignId changed! cancelling all fetchings and clear the list");
      ApiBackCampaignsApplicationsGetSource?.cancel("campaignId changed! cancelling all fetchings");
      this.clear();

      if (this.backAppStore.campaignId) {
        this.load();
      }
    });
  }

  reports = observable.array<ReportStore>([]);

  private async fetchReports() {
    if (!this.backAppStore.campaignId) throw Error("No campaign selected!");

    ApiBackCampaignsReportsGetSource?.cancel("fetchReports called");

    const reports:ReportStore[] = (await ApiBackCampaignsReportsGet(this.backAppStore.campaignId)).data.results.map(m => new ReportStore(this, m));

    this.log.log("Gor reports", reports);

    runInAction(() => {
      this.reports.replace(reports);
    });
    return;
  }

  private async postReport(url: string, reportType: string) {
    if (!this.backAppStore.campaignId) throw Error("No campaign selected!");
    const data = {
      report_type: reportType,
      ...(this.backAppStore.educationType ? {education_type: this.backAppStore.educationTypeId} : {})
    };

    this.log.log("Posting report...", data);

    const postedReport:ApiBackReportModel = (await ApiBackCampaignsReportsPost(url, data)).data;

    try {
      if (await ApiBackReportModelSchemaPartial.isValid(postedReport)) {
        runInAction(() => {
          console.log(postedReport);
          this.reports.unshift(new ReportStore(this, postedReport));
        });
      } else {
        this.log.error("Error pushing fresh report into reports page due to invalid schema");
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      this.log.error("Error pushing fresh report into reports page due to invalid schema", e);
    }
  }

  async processPostReport(url: string, reportType: string) {
    this.state = "pending";
    try {
      await this.postReport(url, reportType);
      runInAction(() => this.state = "ok");
    } catch (e) {
      runInAction(() => {
        this.error = e;
        this.state = "error";
        throw e;
      });
    }
  }

  reportsAvailable = observable.array<ApiReportAvailable>([]);

  private async fetchReportsAvailable() {
    if (!this.backAppStore.campaignId) throw Error("No campaign selected!");
    const params:ApiBackCampaignsReportsAvailableGetParams = this.backAppStore.educationType ? {education_type: this.backAppStore.educationTypeId} : {};
    const reportsAvailable:ApiReportAvailable[] = (await ApiBackCampaignsReportsAvailableGet(this.backAppStore.campaignId, params)).data?.reports_available;

    this.log.log("Gor reportsAvailable", reportsAvailable);

    runInAction(() => {
      this.reportsAvailable.replace(reportsAvailable);
    });
    return;
  }

  async load(): Promise<void> {
    this.state = "pending";
    try {
      await Promise.all([
        this.fetchReports(),
        this.fetchReportsAvailable(),
      ]);
      runInAction(() => {
        this.state = "ok";
      });
    } catch (e) {
      runInAction(() => {
        this.error = e;
        this.state = "error";
      });
    }
  }

  async refresh(): Promise<void> {
    if (!this.reports.length) {
      return;
    }

    this.log.log("Refreshing reports...");

    this.state = "refresh";
    try {
      await this.fetchReports();
      runInAction(() => {
        this.state = "ok";
      });
    } catch (e) {
      if (axios.isCancel(e)) {
        this.log.log("Previous fetchReports canceled");
      } else {
        runInAction(() => {
          this.error = e;
          this.state = "error";
        });
      }
    }
  }

  clear() {
    this.reports.clear();
    this.error = undefined;
    this.state = "init";
    this.isSleep = false;
  }
}

export const useBackReportsPageStore = createPageStoreHook<BackReportsPageStore, ConstructorParameters<typeof BackReportsPageStore>>((...args: ConstructorParameters<typeof BackReportsPageStore>) => new BackReportsPageStore(...args));

export default BackReportsPageStore;