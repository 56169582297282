import React from "react";
import ToastsBlock from "../../components/ToastsBlock";

const WithToasts:React.FC = (props) => (
  <>
    <ToastsBlock/>
    {props.children}
  </>
);

export default WithToasts;