import * as yup from "yup";

export enum FieldDisplayFormat {
  Plain = "plain",
  Badge = "badge",
  Date = "date",
  File = "file",
  Email = "email",
  Recommendation = "recommendation",
}

export interface FieldRecommendation {
  icon: string;
  id: string;
  style: string;
  upload?: string;
  text?: string;
}

export const FieldRecommendationSchema = yup.object().shape({
  icon: yup.string().required(),
  id: yup.string().required(),
  style: yup.string().required(),
  upload: yup.string(),
});

interface ApiApplicationField {
    id: string;
    name: string;
    form_slug: string;
    form_name: string;
    display_format?: FieldDisplayFormat;
}

export default ApiApplicationField;