import PartialBy from "../../../helpers/types/PartialBy";
import {makeAutoObservable, reaction, runInAction} from "mobx";
import toastsStore from "../../singletones/ToastsStore";
import TracksConfigsStore from "./TracksConfigsStore";
import ApiEducationalTrack, {ApiTrackConfigPartialSchema} from "../../../services/api/back/campaigns/configs/tracks/ApiEducationalTrack";
import ApiBackCampaignsConfigsTracksIdPatch
  from "../../../services/api/back/campaigns/configs/tracks/ApiBackCampaignsConfigsTracksIdPatch";
import ApiBackCampaignsConfigsTracksIdPost
  from "../../../services/api/back/campaigns/configs/tracks/ApiBackCampaignsConfigsTracksIdPost";
import ApiBackCampaignsConfigsTracksIdDelete
  from "../../../services/api/back/campaigns/configs/tracks/ApiBackCampaignsConfigsTracksIdDelete";

class TrackConfigItemStore {
  constructor(private root: TracksConfigsStore, public data: PartialBy<ApiEducationalTrack, "id">) {
    makeAutoObservable(this, {
      disposeReactions: false,
      setupReactions: false,
      disposers: false,
    });

    if (data.id) {
      this.savedJson = JSON.stringify(data);
    }

    this.setupReactions();
  }

  disposers: (() => void)[] = [];

  disposeReactions() {
    this.disposers.forEach(d => d());
  }

  setupReactions() {
    this.disposers.push(
      reaction(() => this.json, (json, prevJson: string | undefined) => {
        if (this.hasChanges) {
          runInAction(() => this.state = "pending");
          this.postOrPatch()
            .then(() => runInAction(() => {
              this.state = "ok";
            }))
            .catch(() => runInAction(() => {
              this.state = "error";
              try {
                if (prevJson) {
                  const data = JSON.parse(prevJson);
                  if (ApiTrackConfigPartialSchema.isValidSync(data)) {
                    this.data = data as PartialBy<ApiEducationalTrack, "id">;
                  }
                } else {
                  // remove this, cause undefined json is sign of posting attempt
                  setInterval(() => runInAction(() => this.root.tracksConfigs.remove(this)), 0);
                }
              } catch {
                console.error("Cant rollback education track data during post or update fail", json, prevJson);
              }
              toastsStore.addToast({
                props: {
                  autohide: true,
                  delay: 4300,
                },
                body: "An error occured during Track update or post",
                variant: "danger",
              });
            }));
        }
      }, {
        fireImmediately: true
      })
    );
  }

  get saved():boolean {
    return !!this.data.id;
  }

  get json():string {
    return JSON.stringify(this.data);
  }

  savedJson = "";

  get hasChanges() {
    return this.json !== this.savedJson;
  }

  state: "pending" | "ok" | "focused" | "error" | "deleted" = "ok";

  async postOrPatch() {
    const campaignId = this.root.backAppStore.campaignId;
    if (!campaignId) throw new Error("No campaignId in TrackConfigItemStore:postOrPatch");

    const data = (this.saved
      ? await ApiBackCampaignsConfigsTracksIdPatch(
        campaignId,
        this.data as ApiEducationalTrack
      )
      : await ApiBackCampaignsConfigsTracksIdPost(
        campaignId,
        this.data as ApiEducationalTrack
      )).data;

    runInAction(() => {
      this.data = data;
      this.savedJson = JSON.stringify(data);
    });
  }

  async delete() {
    if (!this.data.can_be_deleted) throw new Error("Cannot be deleted");

    const campaignId = this.root.backAppStore.campaignId;
    if (!campaignId) throw new Error("No campaignId in ProgramConfigItemStore:delete");

    if (!this.data.id) throw new Error("Error deleting");

    await ApiBackCampaignsConfigsTracksIdDelete(this.data.id, campaignId);
    this.disposeReactions();
    runInAction(() => this.state = "deleted");
  }
}

export default TrackConfigItemStore;