import React, {CSSProperties, useMemo} from "react";
import {Form} from "react-bootstrap";
import useSkFieldValidInvalid from "../useSkFieldValidInvalid";
import SkFieldProps from "./SkFieldProps";
import isSkFieldSubmitted from "./IsSkFieldSubmitted";
import SkFieldDescriptionBlock from "./SkFieldDescriptionBlock";
import SkFieldLabel from "./SkFieldLabel";

type SkFieldTextTransform = Array<'lowercase'>;

const SkFieldText:React.FC<SkFieldProps & {
  multiline?: boolean;
  cols?: number;
  style?: CSSProperties;
  hideLabel?: boolean;
  placeholder?: string;
  label?: string;
  transform?: SkFieldTextTransform;
}> = (props) => {
  const {isInvalid, setReallyTouched} = useSkFieldValidInvalid(props);

  // handle text transformations trough onChange
  // TODO it might be a really slow with large strings, so use wise or refactor to on-char-input way
  const onChange = useMemo(() => {
    let onChange = props.formikProps.handleChange;

    if (props.transform) {
      onChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        let val = e.target.value;

        if (props.transform?.includes("lowercase")) {
          val = (`${val}`).toLowerCase();
        }

        props.formikProps.setFieldValue(props.name, val);
      }
    }

    return onChange;
  }, [props.formikProps.handleChange, props.transform]);

  const controlProps = {
    type: "text",
    style: props.style,
    name: props.name,
    value: props.formikProps.values[props.name] || "",
    onChange: onChange,
    isValid: isSkFieldSubmitted(props),
    isInvalid: isInvalid,
    disabled: props.disabled || props.formikProps.isSubmitting,
    onBlur: () => setReallyTouched(true),
    placeholder: props.placeholder,
  };

  return (
    <Form.Group controlId={props.name}>
      {!props.hideLabel ? <SkFieldLabel {...props}/> : null}
      {props.multiline ? (
        <Form.Control
          as={"textarea"}
          cols={props.cols}
          {...controlProps}
        />
      ) : (
        <Form.Control
          as={"input"}
          {...controlProps}
        />
      )}
      <SkFieldDescriptionBlock
        description={props.description}
        isInvalid={isInvalid}
        errorText={`${props.formikProps.errors[props.name]}`}
      />
    </Form.Group>
  );
};

export default SkFieldText;
