import React, {useEffect, useState} from "react";
import {Sticky, StickyContainer} from "react-sticky";

const WithSticky:React.FC<{
  when: (width: number) => boolean;
  topOffset?: number;
  stickyClass?: string;
}> = ({
  topOffset = 0,
  stickyClass= "sticky",
  ...props
}) => {
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return (() => {
      window.removeEventListener("resize", handleResize);
    });
  }, []);

  if (props.when(width)) {
    return <>
      <StickyContainer style={{height: "100%"}}>
        <Sticky topOffset={-topOffset}>
          {({isSticky, style}) => (
            <div
              className={`${isSticky && stickyClass}`}
              style={isSticky && width > 768 ? {
                width: style.width,
                left: style.left,
                paddingTop: `${topOffset}px`,
              } : {}}
            >
              {props.children}
            </div>
          )}
        </Sticky>
      </StickyContainer>
    </>;
  } else {
    return <>
      {props.children}
    </>;
  }

};

export default WithSticky;