import React, {SVGAttributes} from "react";
import * as Icons from "react-bootstrap-icons";
import camelcase from "camelcase";

interface IconProps extends SVGAttributes<SVGElement> {
  icon: string;
  color?: string;
  size?: string | number;
}

const Icon:React.FC<IconProps> = ({icon, ...props}) => {
  let safeIconName: keyof typeof Icons = "App";
  const iconName = camelcase(`${icon}`, {pascalCase: true});

  if (Object.keys(Icons).includes(iconName)) {
    safeIconName = (camelcase(`${icon}`, {pascalCase: true}) as keyof typeof Icons);
  }

  const Comp = Icons[safeIconName];

  return <Comp {...props}/>;
};

export default Icon;