import axios, {AxiosResponse} from "axios";
import tokenStore, { JWTVerify } from "../../stores/singletones/TokenStore";
import {autorun} from "mobx";
import { Log } from "../../helpers/log";
import {ApiTokenRefreshResponse} from "./token/ApiTokenRefresh";
import * as yup from "yup";
import {authStore} from "../../stores/AuthStore";

export const APIBase = process.env.REACT_APP_API_ROOT ? process.env.REACT_APP_API_ROOT : "/";

const log = new Log("Api");

const AxiosApiInstance = axios.create();

export interface ApiError {
  response?: {
    data?: unknown;
  }
}

export const isApiError = (e: unknown) => {
  return !!(e as ApiError)?.response?.data;
};

// Request API Authorization calls
AxiosApiInstance.interceptors.request.use(
  async config => {
    if (tokenStore.token) {
      config.headers["Authorization"] = `Bearer ${tokenStore.token}`;
    }

    return config;
  },
  error => {
    if (axios.isCancel(error)) {
      log.log("Request cancelled", error.request);
      return Promise.reject(error);
    }

    log.error("An error occurred during making the request to the server.", error.request);
    return Promise.reject(new Error("An error occurred during making the request to the server. If the problem persists, take a screenshot with the open developer console and write to support."));
  });

// Add a response interceptor
AxiosApiInstance.interceptors.response.use(
  (response) => {
  // Any status code that lie within the range of 2xx cause this function to trigger
  // Do something with response data
    return response;
  }, (error) => {
    if (axios.isCancel(error)) {
      log.log("Request cancelled", error.request);
      return Promise.reject(error);
    }

    if (error.response) {
      if (error.response.status === 401) {
        try {
          ApiErrorResponseTokenExpiredSchema.validateSync(error.response.data);
          // token has expired
          authStore.logout();

          const originalRequest = error.config;

          // retry, if not yet
          if (!originalRequest.skApiRetry) {
            log.log("Retry");

            originalRequest.skApiRetry = true;
            return AxiosApiInstance(originalRequest);
          } else {
            return Promise.reject(error);
          }

        } catch (e) {
          // unknown 401 situation
          return Promise.reject(error);
        }
      }

      log.error("Error! Server responded with a status and data", error.response.status, error.response.data);

      // if we have a response, so let's pass it further
      if (axios.isAxiosError(error) || (error.response.status && error.response.data)) {
        return Promise.reject(error);
      } else {
        return Promise.reject(new Error("Unknown error came from server"));
      }

    } else if (error.request) {
      log.error("Server didn't respond", error.request);

      return Promise.reject(new Error("Server didn't respond. Please, try again later. If the problem persists, take a screenshot with the open developer console and write to support."));
    } else {
    // Something happened in setting up the request that triggered an Error
      log.error("An error occurred during making the request to the server.", error.request);

      return Promise.reject(new Error("An error occurred during making the request to the server. If the problem persists, take a screenshot with the open developer console and write to support."));
    }
  });

// Renew API Token, if less than half of it's lifetime pass
autorun( () => {
  if (tokenStore.token && tokenStore.isNeedRefresh) {
    log.log("Auth token is being refreshed...");

    AxiosApiInstance.post(APIBase + "token/refresh/", {
      token: tokenStore.token
    })
      .then((response: AxiosResponse<ApiTokenRefreshResponse>) => {
        if (response.data.token) {
          try {
            JWTVerify(response.data.token);
            tokenStore.setToken(response.data.token);
            log.log("Auth token refreshed seccessfully!");
          } catch (e) {
            log.error("Auth token refresh FAILED. Something wrong with the JWT Payload.");
            tokenStore.clearToken();
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
});

export default AxiosApiInstance;

const ApiErrorResponseTokenExpiredSchema = yup.object().shape({
  detail: yup.string().matches(/Token has expired\./)
});