import React, {MouseEventHandler} from "react";
import {observer, Observer} from "mobx-react-lite";
import useFileUploader from "../../../stores/singletones/FileUploaderStore";
import {Button, Form} from "react-bootstrap";
import SkFileBlockLight from "../SkFileBlockLight";
import useSkFieldValidInvalid from "../useSkFieldValidInvalid";
import SkFieldProps from "./SkFieldProps";
import isSkFieldSubmitted from "./IsSkFieldSubmitted";
import SkFieldLabel from "./SkFieldLabel";
import {SkFormMetaChecker} from "../SkFormContexts";
import SkFieldDescriptionBlock from "./SkFieldDescriptionBlock";


const SkFieldFile:React.FC<SkFieldProps & {
  max?: number;
}> = observer((props) => {
  return <SkFormMetaChecker name={"attachment field"}>
    {(formMeta) => {
      const {isInvalid, setReallyTouched} = useSkFieldValidInvalid(props);
      const submitted = isSkFieldSubmitted(props);
      const disabled = props.disabled || props.formikProps.isSubmitting;

      const fileUploaderStore = useFileUploader();
      const value:string[] = props.formikProps.values[props.name];
      const canUpload = !disabled && (props.max ? value.length < props.max : true);

      const handleFileUpload:MouseEventHandler = (e) => {
        if (disabled) return;
        e.preventDefault();
        setReallyTouched(true);
        fileUploaderStore.openFilesUploaderDialog(value, formMeta, props.name,{
          max: props.max
        }).then(
          files => props.formikProps.setFieldValue(props.name, files)
        );
      };

      return <Observer>{() => <Form.Group controlId={props.name}>
        <SkFieldLabel {...props}/>

        {value.map(id => (
          <SkFileBlockLight
            disabled={disabled}
            submitted={submitted}
            key={id}
            onDelete={
              () => !disabled && props.formikProps.setFieldValue(props.name, value.filter(url => url !== id))
            }
            id={id}
          />
        ))}



        {canUpload && <div>
          <Button variant={"dark"} onClick={handleFileUpload}>
                Upload {value.length > 0 && "more"} files
          </Button>
        </div>}


        <SkFieldDescriptionBlock
            description={props.description}
            isInvalid={isInvalid}
            errorText={`${props.formikProps.errors[props.name]}`}
        />

        {/*{props.description && !isInvalid && <Form.Text className="text-muted">*/}
        {/*  {ReactHtmlParser(props.description)}*/}
        {/*</Form.Text>}*/}
        {/*{isInvalid && <Form.Control.Feedback type="invalid">*/}
        {/*  {props.formikProps.errors[props.name]}*/}
        {/*</Form.Control.Feedback>}*/}
      </Form.Group>}</Observer>;
    }}
  </SkFormMetaChecker>;
});

export default SkFieldFile;
