import React, {useEffect, useMemo} from "react";
import {observer} from "mobx-react-lite";
import BackAppStore from "../../stores/backoffice/pages/BackAppStore";
import Loading from "../Loading";
import {Download, FileArrowDown, FilePlus, Files, Plus} from "react-bootstrap-icons";
import {Button, Card, Col, Container, Nav, Row} from "react-bootstrap";
import BackReportsPageStore, {useBackReportsPageStore} from "../../stores/backoffice/pages/BackReportsPageStore";
import {NavLink, Route, Switch, useRouteMatch} from "react-router-dom";
import {useToastsStore} from "../../stores/singletones/ToastsStore";
import ReportDates from "../../components/backoffice/report/ReportDates";
import ReportName from "../../components/backoffice/report/ReportName";
import ErrorBoundary from "../../components/ErrorBoundary";
import PageInfo from "../../interfaces/back/PageInfo";
import PageName from "../../components/backoffice/PageName";
import {SkApplyHistory} from "../../const/SkApplyHistory";

export const pageStoreContext = React.createContext<BackReportsPageStore|null>(null);

export const pageInfo:PageInfo = {
  name: "Reports",
  icon: <Files/>
};

const BackReportsPage:React.FC<{
  backAppStore: BackAppStore
}> = observer(({
  backAppStore
}) => {
  const pageStore = useBackReportsPageStore(backAppStore, true);
  const { url } = useRouteMatch();
  const toastsStore = useToastsStore();
  const timeout = useMemo(() => 30000, []);

  useEffect(() => {
    let id:number;

    const action = () => {
      if (timeout) {
        pageStore.reports.forEach(r => {
          if (r.updating) r.processUpdate();
        });
        id = window.setTimeout(action, timeout);
      }
    };
    id = window.setTimeout(action, timeout);

    return () => window.clearTimeout(id);
  }, [pageStore.reports.length]);

  return <ErrorBoundary errorLevel={"BackReportsPage"}>
    <pageStoreContext.Provider value={pageStore}>
      <Container>
        <Row>
          <Col>
            <div className={"d-flex align-items-center"}>
              <PageName
                icon={pageInfo.icon}
                name={pageInfo.name}
                pageState={pageStore.state}
                className={"mr-4 mb-0"}
              />
              <div className={"flex-grow-1 mr-4"}/>
              <div>
                <Nav variant="pills">
                  <Nav.Item>
                    <NavLink to={`${url}`} className="nav-link" exact={true}><FileArrowDown/> Requested Exports</NavLink>
                  </Nav.Item>
                  <Nav.Item>
                    <NavLink to={`${url}/new`} className="nav-link"><FilePlus/> Request New Export</NavLink>
                  </Nav.Item>
                </Nav>
              </div>
            </div>

          </Col>
        </Row>
      </Container>

      <ErrorBoundary errorLevel={"BackReportsPage-reportsList"}>
        <Loading loading={pageStore.state === "init" || pageStore.state === "pending"}>
          <Container>
            <Row>
              <Col>
                <Switch>
                  <Route path={`${url}`} exact={true}>

                    {pageStore.reports.length > 0 && pageStore.reports.map((report) =>
                      <Card className={"mt-4 mb-4"} key={`${report.model.id}`}>
                        <Card.Body>
                          <Card.Title>
                            <ReportName report={report} reportsAvailable={pageStore.reportsAvailable}/>
                          </Card.Title>
                          <div className="mt-3 mb-3">
                            <ReportDates report={report}/>
                          </div>
                          {report.model.status === "finished" && <Button variant={"outline-primary"} href={report.model.url}>
                            <Download/> Download
                          </Button>}
                        </Card.Body>
                      </Card>
                    )}

                  </Route>
                  <Route path={`${url}/new`} exact={true}>

                    {pageStore.reportsAvailable.length > 0 && pageStore.reportsAvailable.map(reportAvailable =>
                      <Card className={"mt-4 mb-4"} key={reportAvailable.slug}>
                        <Card.Body>
                          <Card.Title>{reportAvailable.name}</Card.Title>
                          <Card.Text>
                            {reportAvailable.description}
                          </Card.Text>
                          <Button
                            variant="outline-primary"
                            onClick={() => {
                              pageStore.processPostReport(reportAvailable.url, reportAvailable.slug).then(() => {
                                toastsStore.addToast({
                                  props: {
                                    autohide: true
                                  },
                                  header: `${reportAvailable.name}`,
                                  body: "Successfully Requested",
                                });
                                SkApplyHistory.push(url);
                              }).catch(() => {
                                toastsStore.addToast({
                                  props: {
                                    autohide: false
                                  },
                                  header: `${reportAvailable.name}`,
                                  body: "And error occurred during requesting", // TODO вывод ошибки
                                });
                              });
                            }}
                          >
                            <Plus className={"bi"}/> Request report
                          </Button>
                        </Card.Body>
                      </Card>
                    )}

                  </Route>
                </Switch>
              </Col>
            </Row>
          </Container>
        </Loading>
      </ErrorBoundary>


    </pageStoreContext.Provider>
  </ErrorBoundary>;
});

export default BackReportsPage;
