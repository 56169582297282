import React from "react";
import {useToastsStore} from "../stores/singletones/ToastsStore";
import {observer} from "mobx-react-lite";
import {Toast} from "react-bootstrap";

const ToastsBlock:React.FC = observer(() => {
  const toastsStore = useToastsStore();
  const toasts = toastsStore.toasts.map(({id, props, body, header,variant}) => (
    <Toast {...props} onClose={() => toastsStore.removeToast(id)} className={`sk-toast ${variant ? "sk-toast-"+variant : ""} animate__animated animate__faster animate__fadeInRight ${header ? "sk-toast-w-header" : ""}`} key={id}>
      {header ? <Toast.Header>{header}</Toast.Header> : <Toast.Header/>}
      <Toast.Body>{body}</Toast.Body>
    </Toast>
  ));

  return <>
    <div
      aria-live="polite"
      aria-atomic="true"
      style={{
        position: "fixed",
        bottom: 0,
        right: 0,
        zIndex: 1000
      }}
    >
      <div className="sk-toast-container">
        {toasts}
      </div>
    </div>
  </>;
});

export default ToastsBlock;