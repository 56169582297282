import * as yup from "yup";
import ApiUserShort from "../../../users/ApiUserShort";

export type ReportStatus = "created" | "finished" | string;

interface ApiBackReportModel {
  id: string;
  display_name?: string;
  description?: string;
  report_type: string;
  user: ApiUserShort;
  status: ReportStatus;
  campaign: string;
  education_type: string;
  created_at?: string;
  started_at: string;
  finished_at?: string;
  url?: string;
}

export const ApiBackReportModelSchemaPartial = yup.object().shape({
  id: yup.string(),
  report_type: yup.string(),
  user: yup.object().shape({
    id: yup.number(),
    username: yup.string(),
    first_name: yup.string(),
    last_name: yup.string(),
  }),
});

export default ApiBackReportModel;