/* eslint-disable jsx-a11y/anchor-is-valid */

import React, {useMemo, useState} from "react";
import {Alert, Button, Card, ProgressBar, Spinner} from "react-bootstrap";
import ApplicationPageStore from "../stores/pages/ApplicationPageStore";
import {CheckCircleFill} from "react-bootstrap-icons";
import {runInAction} from "mobx";
import WithSticky from "../pages/withs/WithSticky";
import {observer} from "mobx-react-lite";
import {ApiApplicationFormStore} from "../helpers/factory/StoreWithFormsFromData";
import {CampaignNotificationStyleVariants} from "./backoffice/configs/NotificationsConfigs";
import {PayButton} from "./PayButton";
import AxiosApiInstance, { APIBase } from "../services/api/AxiosApiInstance";
// TODO: потом понадобится
// import { PaidProvidionAgreement } from "./PaidProvidionAgreement";
// import {PaidProvidionAgreement} from "./PaidProvidionAgreement";

const ApplicationPageStickyBlock:React.FC<{
  handleApplicationSubmit: () => void;
  scrollToForm: (id: string) => void;
  applicationPageStore: ApplicationPageStore
}> = observer(({
  applicationPageStore,
  handleApplicationSubmit,
  scrollToForm,
}) => {
  const forms = applicationPageStore.model?.forms;
  const [termsAccepted, setTermsAccepted] = useState(true);
  const formsReadonly = applicationPageStore.model?.forms_readonly;
  const showSubmitButton = useMemo(() => !!applicationPageStore.model?.show_submit_button, [applicationPageStore.model?.show_submit_button]);
  // const [termsAccepted, setTermsAccepted] = useState(false);
  const docsDownloaded = useMemo(() => !!applicationPageStore.model?.docs_can_be_downloaded, [applicationPageStore.model?.docs_can_be_downloaded])

  const applicationSent = !showSubmitButton;

  const totalQuestions = applicationPageStore.model?.forms.reduce((a: number, form) => a + form.questions.length, 0) || 0;
  const totalQuestionsSubmitted = applicationPageStore.model?.forms.reduce((a: number, form) => a + form.questionsSubmitted, 0) || 0;
  const totalQuestionsMandatory = applicationPageStore.model?.forms.reduce((a: number, form) => a + form.questionsMandatory, 0) || 0;

  const overallProgress = Math.round(totalQuestionsSubmitted / totalQuestions * 100);

  const PBFormOverallProps = (form: ApiApplicationFormStore) => ({
    ...(form.fulfilled && {variant: "success"})
  });

  const PBOverallProps = {
    ...(totalQuestionsSubmitted >= totalQuestionsMandatory && {variant: "success"})
  };

  const downloadMatriculationDocs = () => {
    AxiosApiInstance.get(`${APIBase}applications/${applicationPageStore.applicationId}/matriculation_docs/`, {
      responseType: "blob"
    })
      .then((response: any) => {
        if (response.status !== 200) {
          throw new Error('Ошибка при загрузке файла');
        }
        return response.data
      })
      .then(data => {
        const link = document.createElement('a');

        const blob = new Blob([data]); 
        const url = window.URL.createObjectURL(blob);
        link.href = url

        link.setAttribute('type', 'application/octet-stream');
        link.setAttribute('download', 'filename.zip');
        link.click();
      })
      .catch((e) => {
        console.log(e)
      })
  }

  console.log('store ', applicationPageStore)

  return (
    <WithSticky when={width => width >= 2048} topOffset={24} stickyClass={"sk-status-sticky"}>
      {applicationPageStore.model?.notifications.length ? applicationPageStore.model?.notifications.map((n,i) => {
        const variant = CampaignNotificationStyleVariants[n.style];

        return <Card
          key={`notification_${i}`}
          className={"mb-4"}
          {...(variant ? {
            bg: variant,
          } : {})}
          {...(variant === "danger" ? {
            text: "white",
          } : {})}
        >
          <Card.Body>
            <Card.Text>
              {n.text}
            </Card.Text>
          </Card.Body>
        </Card>;
      }) : null}

      {applicationSent ? 
       applicationPageStore.model?.current_state_tag === "registration" ||
        applicationPageStore.model?.current_state_tag === "accepted" || applicationPageStore.model?.current_state_tag === "completed" ? 
        <Card
          bg={"light"}
          text={"dark"}
          className={"mb-4"}
        >
          <Card.Body>
            {applicationPageStore.model?.show_download_docs_button || applicationPageStore.model.current_state_tag === "completed" ? 
            (
              <>
              <Card.Title>
                Your enrollment data is sent.
              </Card.Title>
              <p>Please download the completed enrollment forms below (refresh the page if the button is not active).</p>
              <p>To be enrolled you need to submit your manualy signed forms to Admissions Team. </p>
              <p>For more details feell free to contact us at admisisons@skoltech.ru</p>
              </>
            )
              :
              (
                <>
                <Card.Title>
                  Your matriculation data is sent.
                </Card.Title>
                <p>Now download and sign the form.</p>
                <p>You will receive an email with detailed instructions on how to submit your signed form and other documents to the Admissions Office.</p>
                </> 
              )
            }
            {applicationPageStore.model?.show_download_docs_button && <Button disabled={!docsDownloaded} onClick={downloadMatriculationDocs}>Download application</Button>}
            
          </Card.Body>
        </Card> : ( 
         <Card
         bg={"light"}
         text={"dark"}
         className={"mb-4"}
       >
         <Card.Body>
          <Card.Title>
              Your application is sent!
            </Card.Title>
            <p>Your Skoltech selection application is being processed. We will be in touch via email about next steps of the selection process. Please make sure not to miss our e-mails.</p>
            <p>At the moment you can't make any changes to the data you've provided, you can only view it.</p>
            <p>Should you have any questions, feel free to contact us at admissions@skoltech.ru. See you!</p>
         </Card.Body>
       </Card>
      ) : (
        <>
          {/** блок с прогрессом */}
          {formsReadonly?.length !== forms?.length ? <Card
            bg={"light"}
            text={"dark"}
            className={"mb-4"}
          >
            <Card.Body>
              {applicationPageStore.model?.current_state_tag === "approved" &&
                <>
                <p>
                  Submit your matriculation data!
                </p>
                <p>
                  Please fill out all the information in the matriculation form: we need this data to setup your Skoltech records. When you complete the matriculation form, please remember to press the “Submit” button. After submission, you will be able to download a PDF file of your application form, which you will need to sign and pass to the Admissions Office. Keep an eye on further communications from us regarding this process.
                </p>
              </>
              }
              <Card.Title>
                Overall application progress
              </Card.Title>
              <ProgressBar>
                <ProgressBar now={overallProgress} key={1} {...PBOverallProps}/>
              </ProgressBar>
              <div className={"d-flex align-items-baseline mt-4 mb-2"}>
                <Card.Title className={"flex-grow-1"}>
                  Progress by forms
                </Card.Title>
                <small className="text-muted">Click form name to scroll</small>
              </div>
              {forms && forms.filter(form => formsReadonly ? !formsReadonly.includes(form.id) : false).map((form: ApiApplicationFormStore) => (
                <div className={"d-flex align-items-baseline mb-0"} key={form.id}>
                  <h6 className={"w-50 mr-4"}>
                    <a href="#" onClick={(e: React.MouseEvent) => {
                      e.preventDefault();
                      scrollToForm(form.id);
                    }}
                    className={`${form.fulfilled && "text-success"}`}
                    >
                      {form.name} {form.fulfilled && <CheckCircleFill className={"ml-2 align-middle"}/>}
                    </a>
                  </h6>
                  <div className={"w-50"}>
                    <ProgressBar className={"mb-4"}>
                      {form.name === 'Profile' && <ProgressBar now={Math.round(form.questionsSubmitted / (form.questions.length - 1) * 100)} key={1} {...PBFormOverallProps(form)}/>}
                      {form.name === 'Education details' && <ProgressBar now={Math.round(form.questionsSubmitted / (form.questions.length - 2) * 100)} key={1} {...PBFormOverallProps(form)}/>}
                      {form.name === 'Professional details' && <ProgressBar now={Math.round(form.questionsSubmitted / (form.questions.length - 13) * 100)} key={1} {...PBFormOverallProps(form)}/>}
                    </ProgressBar>
                  </div>
                </div>
              ))}
            </Card.Body>
          </Card> : null}

          {showSubmitButton ? <>
            {applicationPageStore.model?.can_be_submitted && (totalQuestionsSubmitted >= totalQuestionsMandatory) && (
              <p className={"lead"}>
                You filled out all mandatory fields. From now on, your application can be submitted.
              </p>
            )}
            {applicationPageStore.model?.show_pay_button && (totalQuestionsSubmitted >= totalQuestionsMandatory) && (
              <p className={"lead"}>
                Please proceed with a processing fee payment.
              </p>
            ) && (
              <p className={"lead"}>
                <a href="https://box.skoltech.ru/index.php/s/UxQyDu59nzFUf3D" target="_blank" rel="noreferrer">Terms and conditions</a>
              </p>
            )}
            {applicationPageStore.submitError && <Alert
              variant="danger"
              onClose={() => runInAction(() => {
                applicationPageStore.submitError = "";
              })}
              dismissible
            >
              {applicationPageStore.submitError}
            </Alert>}
            {!applicationPageStore.model?.show_pay_button && (<>
              {/* <Button className={"mt-4 mb-4"} variant={"primary"} size={"lg"} block disabled={!termsAccepted || !applicationPageStore.model?.can_be_submitted} onClick={handleApplicationSubmit}>
                {applicationPageStore.state === "submit"
                    ? <><Spinner
                        as="span"
                        animation="border"
                        role="status"
                        aria-hidden="true"
                    /></>
                    : <>Submit Application</>
                }
              </Button> */}
              <Button className={"mt-4 mb-4"} variant={"primary"} size={"lg"} block disabled={!applicationPageStore.model?.can_be_submitted} onClick={handleApplicationSubmit}>
                {applicationPageStore.state === "submit"
                    ? <><Spinner
                        as="span"
                        animation="border"
                        role="status"
                        aria-hidden="true"
                    /></>
                    : <>Submit</>
                }
              </Button>
              {/* <PaidProvidionAgreement termsAccepted={termsAccepted} setTermsAccepted={setTermsAccepted}/> */}
            </>)}
            {applicationPageStore.model?.show_pay_button && !!applicationPageStore.model?.pay_button_url && (
              <PayButton url={applicationPageStore.model?.pay_button_url} caption="Pay by Russian card" termsAccepted={termsAccepted} variant="primary"/>
            )}
            {applicationPageStore.model?.show_pay_button && !!applicationPageStore.model.pay_button_url_international && (
              <PayButton url={applicationPageStore.model?.pay_button_url_international} caption="Pay by International card" termsAccepted={termsAccepted} variant="info"/>
            )}
              {/* <PaidProvidionAgreement termsAccepted={termsAccepted} setTermsAccepted={setTermsAccepted}/> */}
          </> : null}
        </>
      )}
    </WithSticky>
  );
});

export default ApplicationPageStickyBlock;
