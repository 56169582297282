import React, {useRef, useState} from "react";
import {Button, Overlay, Popover} from "react-bootstrap";
import {QuestionCircle, QuestionCircleFill} from "react-bootstrap-icons";

const Help:React.FC = () => {
  const [show, setShow] = useState(false);
  const target = useRef(null);

  return <>
    <Button variant={"link"} ref={target} onClick={() => setShow(!show)}>
      {!show ? <QuestionCircle/> : <QuestionCircleFill/>}
    </Button>
    <Overlay target={target.current} show={show} placement="bottom">
      <Popover id="popover-help">
        <Popover.Title as="h3">Instructions</Popover.Title>
        <Popover.Content>
          <p>Manage table columns with <strong>Columns</strong> dropdown menu.</p>
          <p>Select column for table sort with <strong>Sort</strong> dropdown menu, and switch sort order with button next to it.</p>
          <p>Add filters to get filtered set of applications in table with <strong>Filters</strong> dropdown menu. Filters controls will appear between this menu and the table.</p>
          <p>Search all application fields with text input field.</p>
          <p>Request a report as .xls format with <strong>Report</strong> button, containing all applications matched current filters and search settings, with columns currently visible. Reports can be downloaded on <strong>Reports</strong> page.</p>
        </Popover.Content>
      </Popover>
    </Overlay>
  </>;
};

export default Help;