import PartialBy from "../../../helpers/types/PartialBy";
import {makeAutoObservable, reaction, runInAction} from "mobx";
import toastsStore from "../../singletones/ToastsStore";
import ApiProgramConfigGet from "../../../services/api/back/campaigns/configs/programs/ApiProgramConfigGet";
import ApiBackCampaignsConfigsProgramsIdPatch
  from "../../../services/api/back/campaigns/configs/programs/ApiBackCampaignsConfigsProgramsIdPatch";
import ProgramsConfigsStore from "./ProgramsConfigsStore";
import ApiBackCampaignsConfigsProgramsIdPost
  from "../../../services/api/back/campaigns/configs/programs/ApiBackCampaignsConfigsProgramsIdPost";
import ApiBackCampaignsConfigsProgramsIdDelete
  from "../../../services/api/back/campaigns/configs/programs/ApiBackCampaignsConfigsProgramsIdDelete";
import TrackConfigItemStore from "./TrackConfigItemStore";
import * as yup from "yup";

type ApiProgramConfigPartial = PartialBy<ApiProgramConfigGet, "id">;

export const ApiProgramConfigPartialSchema = yup.object().shape({
  name: yup.string().required(),
  visible: yup.boolean().required(),
  education_type: yup.string().required(),
});

class ProgramConfigItemStore {
  constructor(private root: ProgramsConfigsStore, public data: ApiProgramConfigPartial) {
    makeAutoObservable(this, {
      disposeReactions: false,
      setupReactions: false,
      disposers: false,
    });

    if (data.id) {
      this.savedJson = JSON.stringify(data);
    }

    this.setupReactions();
  }

  disposers: (() => void)[] = [];

  disposeReactions() {
    this.disposers.forEach(d => d());
  }

  get tracks(): TrackConfigItemStore[] {
    return this.root.settingsPageStore.tracksConfigsStore.tracksConfigs.filter(t => t.state !== "deleted").filter(t => t.data.educational_program === this.data.id);
  }

  get tracksLoading():boolean {
    return this.root.settingsPageStore.tracksConfigsStore.state !== "ok";
  }

  get hasTracks() {
    return !!this.tracks.length;
  }


  setupReactions() {
    this.disposers.push(
      reaction(() => this.json, (json, prevJson) => {
        if (this.hasChanges) {
          runInAction(() => this.state = "pending");
          this.postOrPatch()
            .then(() => runInAction(() => {
              this.state = "ok";
            }))
            .catch(() => runInAction(() => {
              this.state = "error";
              try {
                if (prevJson) {
                  const data = JSON.parse(prevJson);
                  if (ApiProgramConfigPartialSchema.isValidSync(data)) {
                    this.data = data as ApiProgramConfigPartial;
                  }
                } else {
                  // remove this, cause undefined json is sign of posting attempt
                  setInterval(() => runInAction(() => this.root.programsConfigs.remove(this)), 0);
                }
              } catch {
                console.error("Cant rollback educational program data during post or update fail", json, prevJson);
              }

              this.data = JSON.parse(prevJson);
              toastsStore.addToast({
                props: {
                  autohide: true,
                  delay: 4300,
                },
                body: "An error occured during Notification update",
                variant: "danger",
              });
            }));
        }
      }, {
        fireImmediately: true
      })
    );
  }

  get saved():boolean {
    return !!this.data.id;
  }

  get json():string {
    return JSON.stringify(this.data);
  }

  savedJson = "";

  get hasChanges() {
    return this.json !== this.savedJson;
  }

  state: "pending" | "ok" | "focused" | "error" | "deleted" = "ok";

  async postOrPatch() {
    const campaignId = this.root.backAppStore.campaignId;
    if (!campaignId) throw new Error("No campaignId in ProgramConfigItemStore:postOrPatch");

    const data = (this.saved
      ? await ApiBackCampaignsConfigsProgramsIdPatch(
        campaignId,
        this.data as Omit<ApiProgramConfigGet, "educational_tracks">
      )
      : await ApiBackCampaignsConfigsProgramsIdPost(
        campaignId,
        this.data
      )).data;

    runInAction(() => {
      this.data = data;
      this.savedJson = JSON.stringify(data);
    });
  }

  async delete() {
    if (!this.data.can_be_deleted) throw new Error("Cannot be deleted");

    const campaignId = this.root.backAppStore.campaignId;
    if (!campaignId) throw new Error("No campaignId in ProgramConfigItemStore:delete");

    if (!this.data.id) throw new Error("Error deleting");

    await ApiBackCampaignsConfigsProgramsIdDelete(this.data.id, campaignId);
    this.disposeReactions();
    runInAction(() => this.state = "deleted");
  }
}

export default ProgramConfigItemStore;