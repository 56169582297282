import React, {useContext} from "react";
import {makeAutoObservable} from "mobx";
import { ApiQuestionRenderedModel } from "../services/api/questions/ApiQuestionModel";

// Когда нам нужно скрывать поля в зависимости от массива dependency[], храним отфильтрованные вопросы здесь для каждой формы
class FilteredQuestionsStore {
  filteredQuestions: { [key: string]: ApiQuestionRenderedModel[] } = {}

  constructor() {
    makeAutoObservable(this);
    this.filteredQuestions = {};
  }

  setFilteredQuestions = (filteredQuestions: ApiQuestionRenderedModel[], key: string) => {
    this.filteredQuestions[key] = filteredQuestions 
  }
}

export const filteredQuestionsStore = new FilteredQuestionsStore();

export const filteredQuestionsContext = React.createContext<FilteredQuestionsStore>(filteredQuestionsStore);
export const useFilteredQuestions = () => useContext(filteredQuestionsContext);
