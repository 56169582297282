import logoWhite from "../img/logo_white.png";
import logoBlue from "../img/logo_blue.png";
import React from "react";

const logos = {
  "blue": logoBlue,
  "white": logoWhite,
};

interface Props extends React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement> {
  color?: "blue" | "white";
}
const Logo:React.FC<Props> = ({
  color = "white",
  ...props
}) => (
  <img {...props} src={logos[color]} alt="Skoltech"/>
);

export default Logo;