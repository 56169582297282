import React, {DependencyList, ReactNode, useEffect, useMemo, useState} from "react";
import {Form} from "react-bootstrap";
import useSkFieldValidInvalid from "../useSkFieldValidInvalid";
import SkFieldProps from "./SkFieldProps";
import isSkFieldSubmitted from "./IsSkFieldSubmitted";
import SkFieldDescriptionBlock from "./SkFieldDescriptionBlock";
import SkFieldLabel from "./SkFieldLabel";
import lunr, {Index} from "lunr";
import {ApiQuestionRenderedModel} from "../../../services/api/questions/ApiQuestionModel";
import {SkFieldSelectValues} from "../SkForm";
import NovariantsMessage from "../NovariantsMessage";
import {s2positivei} from "../../../helpers/utils";

/* eslint-disable react-hooks/exhaustive-deps */

const REACT_APP_SELECT_AUTOSUGGEST_HIDE_VARIANTS_FROM_ITEMS_GTE = s2positivei(process.env.REACT_APP_SELECT_AUTOSUGGEST_HIDE_VARIANTS_FROM_ITEMS_GTE, 10);

const SkFieldAutosuggest:React.FC<SkFieldProps & {
  question: ApiQuestionRenderedModel;
  questions?: ApiQuestionRenderedModel[];
  free?: boolean; // ;D (nuff interesting, just can type their own value)
  noValuesMessage?: ReactNode;
  selectValues: SkFieldSelectValues;
  questionsAndIndexDeps: DependencyList;
}> = (props) => {

  const index = useMemo(() => lunr((builder) => {
    builder.field("name");

    try {
      Object.keys(props.selectValues).forEach(key => {
        builder.add({
          name: props.selectValues[key],
          id: key
        });
      });
    } catch (e) {
      console.error(`Error while building index for ${props.name}`, e);
    }
  }), props.questionsAndIndexDeps);

  const {isInvalid, setReallyTouched, reallyTouched} = useSkFieldValidInvalid(props);
  const value = useMemo(() => props.formikProps.values[props.name], [props.formikProps.values, props.name]);
  const variant = useMemo(() => value && props.selectValues[value] ? props.selectValues[value] : "", [value, props.selectValues]);
  const [innerValue, setInnerValue] = useState<string>(variant);
  const [focused, setFocused] = useState<boolean>(false);
  const [searchResults, setSearchResults] = useState<Index.Result[]>([]);
  const [focusedSearchResult, setFocusedSearchResult] = useState<number|undefined>();
  const handleOnKeyDown = (e:React.KeyboardEvent) => {
    if (e.key === "ArrowDown" || e.key === "ArrowUp" || e.key === "Enter") {
      if (searchResults.length) {
        if (e.key === "Enter" && focusedSearchResult !== undefined && searchResults[focusedSearchResult]) {
          setInnerValue((props.selectValues[searchResults[focusedSearchResult].ref] as string));
          return;
        }

        const f = focusedSearchResult && focusedSearchResult >= searchResults.length ? undefined : focusedSearchResult;

        if (e.key === "ArrowDown") {
          if (f !== undefined) {
            setFocusedSearchResult((f + 1) % searchResults.length);
          } else {
            setFocusedSearchResult(0);
          }
        } else if (e.key === "ArrowUp") {
          // тут нужно чтобы 0 тоже отрабатывал!
          if (f) {
            setFocusedSearchResult((f - 1) % searchResults.length);
          } else {
            setFocusedSearchResult(searchResults.length - 1);
          }
        }
      } else {
        setFocusedSearchResult(undefined);
      }
    }
  };
  useEffect(() => setFocusedSearchResult(undefined), [searchResults]);

  const match = useMemo(() => Object.keys(props.selectValues).find(key => props.selectValues[key] === innerValue) || "", [props.selectValues, innerValue]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!reallyTouched) setReallyTouched(true);
    setInnerValue(e.target.value);
  };

  // set value except if it's already same
  useEffect(() => {
    if (match && value !== match) {
      props.formikProps.setFieldValue(props.name, match);
    }
    if (!match && value !== innerValue) {
      props.formikProps.setFieldValue(props.name, innerValue);
    }
  }, [match, innerValue]);

  // search the index and set the results array if
  // show or hide the dropdown based on focus and innner value length
  // const search = useCallback(debounce(() => setSearchResults(index.search(innerValue)), 200), []);
  useEffect(() => {
    if (focused && !match) {
      let sr:Index.Result[] = [];

      try {
        sr = index.search(`${innerValue.trim()}*`);
      } catch (e) {
        console.error(`Error while searching in ${props.name}`, e);
      }
      setSearchResults(sr);
    } else {
      setSearchResults([]);
    }
  }, [focused, innerValue, match]);

  const showDropdown = useMemo(() => focused && !match && searchResults.length, [focused, !match, searchResults.length]);

  return (
    <Form.Group controlId={props.name}>
      <SkFieldLabel {...props}/>
      <div className="dropdown dropdown-autosuggestion">
        <Form.Control
          as={"input"}
          type="text"
          name={props.name}
          value={innerValue}
          onChange={handleChange}
          isValid={isSkFieldSubmitted(props)}
          isInvalid={isInvalid}
          disabled={props.disabled || props.formikProps.isSubmitting}
          onBlur={() => {
            setFocused(false);
            setReallyTouched(true);
          }}
          onFocus={() => setFocused(true)}
          onKeyDown={handleOnKeyDown}
        />
        <NovariantsMessage values={props.selectValues} question={props.question} questions={props.questions} noValuesMessage={props.noValuesMessage}/>
        <ul className={`dropdown-menu ${showDropdown ? "show" : ""}`} aria-labelledby="dropdownMenuButton1">
          {searchResults.length > REACT_APP_SELECT_AUTOSUGGEST_HIDE_VARIANTS_FROM_ITEMS_GTE ? (
            <>
              {innerValue && innerValue.length && searchResults.slice(0,7).map(sr => <li key={sr.ref}>
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <a className="dropdown-item" href="#"
                  onMouseDown={e => e.preventDefault()}
                  onClick={e => {
                    e.preventDefault();
                    setInnerValue((props.selectValues[sr.ref] as string));
                  }}
                >{(props.selectValues[sr.ref] as string)}</a>
              </li>)}
              {innerValue === "" && <li className={"dropdown-item text-muted"}>{searchResults.length} options available, start typing 👆</li>}
            </>
          ) : (
            <>
              {searchResults.map((sr,i) => {
                const c = i === focusedSearchResult ? "dropdown-item active" : "dropdown-item";

                return <li key={sr.ref}>
                  {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                  <a className={c} href="#"
                    onMouseDown={e => e.preventDefault()}
                    onClick={e => {
                      e.preventDefault();
                      setInnerValue((props.selectValues[sr.ref] as string));

                    }}
                  >{(props.selectValues[sr.ref] as string)}</a>
                </li>;
              })}
            </>
          )}
        </ul>
      </div>
      <SkFieldDescriptionBlock
        description={props.description}
        isInvalid={isInvalid}
        errorText={`${props.formikProps.errors[props.name]}`}
      />
    </Form.Group>
  );
};

export default SkFieldAutosuggest;
