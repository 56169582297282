import {AxiosResponse} from "axios";
import AxiosApiInstance, {APIBase} from "../AxiosApiInstance";

export interface ApiTokenObtainRequest {
  email: string;
  password: string;
}

export interface ApiTokenObtainResponse {
  pk: number;
  token: string;
}

export const ApiTokenObtain: (payload: ApiTokenObtainRequest) => Promise<AxiosResponse<ApiTokenObtainResponse>> = (payload) => {
  return AxiosApiInstance.post(APIBase + "token/obtain/", payload);
};