import {makeAutoObservable, observable, runInAction} from "mobx";
import BackAppStore from "../pages/BackAppStore";
import TrackConfigItemStore from "./TrackConfigItemStore";
import ApiEducationalTrack from "../../../services/api/back/campaigns/configs/tracks/ApiEducationalTrack";
import ApiBackCampaignsConfigsTracksGet
  from "../../../services/api/back/campaigns/configs/tracks/ApiBackCampaignsConfigsTracksGet";

class TracksConfigsStore {
  constructor(
    public backAppStore: BackAppStore,
    public state: "init" | "pending" | "ok" | "error" = "init",
  ) {
    makeAutoObservable(this, {
      tracksConfigs: false
    });

    if (state === "init") {
      this.fetch()
        .then(() => runInAction(() => this.state = "ok"))
        .catch(() => runInAction(() => this.state = "error"));
    }
  }

  tracksConfigs = observable.array<TrackConfigItemStore>([], {deep: false});

  async fetch() {
    const campaign = this.backAppStore.campaign;
    if (!campaign) throw new Error("no campaign selected");

    runInAction(() => this.state = "pending");

    try {
      const tracksConfigsRaw:ApiEducationalTrack[]  = (await ApiBackCampaignsConfigsTracksGet(campaign?.id)).data.results;
      runInAction(() => {
        this.tracksConfigs.replace(tracksConfigsRaw.map((data) => new TrackConfigItemStore(this, data)));
        this.state = "ok";
      });
    } catch (e) {
      console.log(e);
      runInAction(() => this.state = "error");
    }
  }
}

export default TracksConfigsStore;