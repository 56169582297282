import AxiosApiInstance, {APIBase} from "../../../../AxiosApiInstance";
import ApiProgramConfigGet from "./ApiProgramConfigGet";
import {AxiosResponse} from "axios";

const ApiBackCampaignsConfigsProgramsIdPost
  = (campaign_pk:string, payload: Omit<ApiProgramConfigGet, "id" | "educational_tracks">)
  :Promise<AxiosResponse<ApiProgramConfigGet>> => {
    return AxiosApiInstance.post(`${APIBase}back/campaigns/${campaign_pk}/configs/programs/`, payload);
  };

export default ApiBackCampaignsConfigsProgramsIdPost;