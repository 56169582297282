import React from "react";
import BasePageStore, {createPageStoreHook, PageState} from "../../BasePageStore";
import {Log} from "../../../helpers/log";
import {action, computed, makeObservable, observable, reaction, runInAction} from "mobx";
import {
  ApiBackCampaignsApplicationModel,
  ApiBackCampaignsApplicationsGet,
  ApiBackCampaignsApplicationsGetSource
} from "../../../services/api/back/campaigns/applications/ApiBackCampaignsApplicationsGet";
import BackAppStore from "./BackAppStore";
import {
  ApiBackCampaignsApplicationsConfigGet,
  ApiBackCampaignsApplicationsConfigModel
} from "../../../services/api/back/campaigns/applications/ApiBackCampaignsApplicationsConfigGet";
import debounce from "debounce";
import ApplicationActionConfirmationModalStore from "../modals/applications/ApplicationActionConfirmationModalStore";
import ApiApplicationAction from "../../../services/api/back/campaigns/actions/ApiApplicationAction";
import axios from "axios";
import {s2positivei} from "../../../helpers/utils";
import toastsStore from "../../singletones/ToastsStore";
import ApiApplicationField, {
  FieldDisplayFormat, FieldRecommendation,
  FieldRecommendationSchema
} from "../../../services/api/back/campaigns/applications/ApiApplicationField";
import xss from "xss";
import emptyCellCode from "../../../const/emptyCellCode";
import {authStore} from "../../AuthStore";
import {ApiBackCampaignsApplicationsExportPost} from "../../../services/api/back/campaigns/applications/ApiBackCampaignsApplicationsExportPost";
import { Link } from "react-router-dom";

const LIMIT = s2positivei(process.env.REACT_APP_BACK_APPLICATIONS_LIMIT, 20);
const STEP = s2positivei(process.env.REACT_APP_BACK_APPLICATIONS_LOAD_STEP, 20);

export interface ConfiguredFilter {
  key: string;
  variantId: string;
}

interface ColumnGroup {
  name: string;
  slug: string;
  hidden: boolean;
  columns: Column[];
}

export interface Column {
  name: string;
  id: string;
  form_name: string;
  form_slug: string;
  displayFormat: FieldDisplayFormat;
  hidden: boolean;
}

export interface TableCell<T> {
  data: T;
  displayFormat: FieldDisplayFormat;
}

interface TableRow {
  className: string;
  cells: TableCell<string | FieldRecommendation>[];
}

class BackApplicationsPageStore extends BasePageStore {
  constructor(public backAppStore: BackAppStore, load = false) {
    super();

    makeObservable(this, {
      applicationsTable: computed,
      columns: computed,
      visibleColumns: computed,
      toggleColumn: action,
      toggleHideAll: action,
      isAllColumnsHidden: computed,
      toggleApplicationSelect: action,
      allSelected: computed,
      toggleAllSelected: action.bound,
      fetchApplicationsConfig: action,
      fetchApplications: action,
      applyApplicationChanges: action,
      load: action,
      loadMore: action,
      refresh: action,
      showApplicationActionConfirmation: action.bound,
      hideModal: action.bound,
      clear: action,
      state: observable,
      error: observable,
      query: observable,
      limit: observable,
      applicationsConfig: observable,
      modal: observable,
      isHasConfiguredFilters: computed,
      configuredFiltersParams: computed,
      setFilter: action,
      clearFilter: action,
      sortFieldId: observable,
      setSortFieldId: action,
      sortField: computed,
      sort: observable,
      switchSort: action,
      sortQuery: computed,
      reportState: observable,
    });

    // autorun(() => console.log("STATE changed", this.state));

    //clear and reload on campaign change!
    reaction(() => `${this.backAppStore.campaignId}${this.backAppStore.educationTypeId}${authStore.user?.id}`, () => {
      this.log.log("campaignId or logged user changed! cancelling all fetchings and clear the list");
      ApiBackCampaignsApplicationsGetSource?.cancel("campaignId changed! cancelling all fetchings");
      this.clear();

      if (this.backAppStore.campaignId) {
        this.load();
      }
    });

    //auto-search, sort and filters
    //TODO не просто перезагрузка, а перезагрузка с фильтрами
    const debouncedSearch = debounce(() => this.runFetchApplications(), 200);

    reaction(() => `${this.query}${JSON.stringify(this.configuredFilters)}${this.sortQuery}`, (query, newQuery) => {
      if (query !== newQuery) {
        runInAction(() => this.limit = LIMIT);
        debouncedSearch();
      }
    });

    //set sort if not set already on config
    reaction(() => this.applicationsConfig?.available_fields?.length, () => {
      if (!this.sortFieldId && this.applicationsConfig?.available_fields?.length) {
        const firstFieldId = this.applicationsConfig.available_fields[0].id;
        runInAction(() => this.sortFieldId = firstFieldId);
      }
    });

    //check that sort field is in available fields
    reaction(() => this.sortFieldId, () => {
      if (this.applicationsConfig?.available_fields?.length && !this.applicationsConfig.available_fields.find(f => f.id === this.sortFieldId)) {
        const firstFieldId = this.applicationsConfig.available_fields[0].id;
        runInAction(() => this.sortFieldId = firstFieldId);
      }
    });

    this.registerToBeStored();

    if (load) {
      this.load().catch(e => this.error = e);
    }
  }

  get toBeStored() {
    return {
      hiddenColumns: this.hiddenColumns,
      configuredFilters: this.configuredFilters,
      sortFieldId: this.sortFieldId,
      sort: this.sort,
    };
  }

  log = new Log("BackApplicationsPageStore");
  state: PageState = "init";
  error: unknown;

  applications = observable.array<ApiBackCampaignsApplicationModel>([]);

  get applicationsTable(): TableRow[] {
    const matrix: boolean[] = this.columns.map(c => !c.hidden);
    const displayFormatMatrix: (FieldDisplayFormat)[] = this.columns.map(c => c.displayFormat || FieldDisplayFormat.Plain);

    return this.applications.map(a => ({
      className: this.selectedApplications.indexOf(a.id) !== -1 ? "table-info" : "",
      cells: this.isAllColumnsHidden
        ? [{data: "—", displayFormat: FieldDisplayFormat.Plain}]
        : a.answers
          .reduce<TableCell<string | FieldRecommendation>[]>((cells,answer: string | FieldRecommendation,i) => {
            if (matrix[i]) {
              const displayFormat = displayFormatMatrix[i];
              let data;

              if (displayFormat === FieldDisplayFormat.Badge) {
                data = xss(`${answer}`, {
                  whiteList: {
                    span: ["class"]
                  }
                });
              } else if (displayFormat === FieldDisplayFormat.Recommendation) {
                if (FieldRecommendationSchema.isValidSync(answer)) {
                  data = answer;
                } else {
                  data = emptyCellCode;
                }
              } else {
                // plain
                data = answer;
              }

              cells.push({
                data: data,
                displayFormat: displayFormat,
              });
            }

            return cells;
          }, [])

    }));
  }

  query = "";
  limit:number = LIMIT;

  applicationsConfig?: ApiBackCampaignsApplicationsConfigModel;

  // Колонки

  get columns():Column[] {
    return this.applicationsConfig?.available_fields?.map((field) => ({
      name: field.name,
      id: field.id,
      form_name: field.form_name,
      form_slug: field.form_slug,
      hidden: !!this.hiddenColumns.find(fid => fid === field.id),
      displayFormat: field.display_format || FieldDisplayFormat.Plain,
    })) || [];
  }

  get columnGroups():ColumnGroup[] {
    const columnGroups:ColumnGroup[] = [];

    this.columns.forEach(column => {
      let group:ColumnGroup|undefined = columnGroups.find(cg => cg.slug === column.form_slug);

      if (!group) {
        group = {
          name: column.form_name,
          slug: column.form_slug,
          hidden: column.hidden,
          columns: [column],
        };
        columnGroups.push(group);
      } else {
        // only if all cols are hidden
        group.hidden = group.hidden && column.hidden;
        group.columns.push(column);
      }
    });

    return columnGroups;
  }

  get visibleColumns(): Column[] {
    if (this.isAllColumnsHidden) {
      return [{name: emptyCellCode, id: emptyCellCode, hidden: false, form_name: emptyCellCode, form_slug: emptyCellCode, displayFormat: FieldDisplayFormat.Plain}];
    } else {
      return this.columns.filter(c => !c.hidden);
    }
  }

  hiddenColumns = observable.array<string>([]);

  sortFieldId?: string;

  setSortFieldId(sortFieldId: string) {
    this.sortFieldId = sortFieldId;
  }

  // this is not supposed to be wrapped in action
  handleSortClick(sortFieldId: string) {
    if (this.sortFieldId === sortFieldId) {
      // меняем порядок
      this.switchSort();
    } else {
      this.setSortFieldId(sortFieldId);
    }
  }

  get sortField():ApiApplicationField|undefined {
    return this.applicationsConfig?.available_fields?.find(f => f.id === this.sortFieldId);
  }

  sort: "asc" | "desc" = "asc";

  switchSort() {
    this.sort = this.sort === "asc" ? "desc" : "asc";
  }

  get sortQuery():string|undefined {
    return this.sortFieldId ? `${this.sort === "asc" ? "" : "-"}${this.sortFieldId}` : undefined;
  }

  configuredFilters = observable.array<ConfiguredFilter>([]);

  setFilter(key: string, variantId: string) {
    const filter = this.configuredFilters.find(cf => cf.key === key);

    if (filter) {
      filter.variantId = variantId;
    } else {
      this.configuredFilters.push({
        key: key,
        variantId: variantId,
      });
    }
  }

  clearFilter(key: string) {
    const filter = this.configuredFilters.find(cf => cf.key === key);

    if (filter) {
      this.configuredFilters.remove(filter);
    }
  }

  clearFilters() {
    this.configuredFilters.clear();
  }

  get isHasConfiguredFilters() {
    return !!this.configuredFilters.length;
  }

  get configuredFiltersParams():{[key: string]: string}|undefined {
    if (!this.configuredFilters.length) return undefined;

    const result:{
      [key: string]: string;
    } = {};

    this.configuredFilters.forEach(cf => {
      result[cf.key] = cf.variantId;
    });

    return result;
  }

  toggleColumn(column: Column) {
    if (column.hidden) {
      this.hiddenColumns.remove(column.id);
    } else {
      this.hiddenColumns.push(column.id);
    }
  }

  toggleColumnGroup(cg: ColumnGroup) {
    if (cg.hidden) {
      cg.columns.forEach(col => this.hiddenColumns.remove(col.id));
    } else {
      cg.columns.forEach(col => !this.hiddenColumns.includes(col.id) && this.hiddenColumns.push(col.id));
    }
  }

  toggleHideAll() {
    if (this.isAllColumnsHidden) {
      this.hiddenColumns.clear();
    } else {
      this.hiddenColumns.replace(this.columns.map(c => c.id));
    }
  }

  get isAllColumnsHidden() {
    return this.columns.length <= this.hiddenColumns.length;
  }



  // Выделение

  selectedApplications = observable.array<string>([]);

  toggleApplicationSelect(id: string) {
    if (this.selectedApplications.includes(id)) {
      this.selectedApplications.remove(id);
    } else {
      this.selectedApplications.push(id);
    }
  }
  get allSelected() {
    return this.applications.length > 0 && this.selectedApplications.length >= this.applications.length;
  }

  toggleAllSelected() {
    if (!this.allSelected) {
      this.selectedApplications.replace(this.applications.map(a => a.id));
    } else {
      this.selectedApplications.clear();
    }
  }

  async fetchApplicationsConfig(): Promise<void> {
    if (!this.backAppStore.campaignId) throw new Error("Please select campaign");
    const campaignId = this.backAppStore.campaignId;
    const educationTypeId = this.backAppStore.educationTypeId;

    this.log.log("Fetching Applications Config (for table)...");

    const applicationsConfig: ApiBackCampaignsApplicationsConfigModel = (await ApiBackCampaignsApplicationsConfigGet(campaignId, educationTypeId ? {
      education_type: educationTypeId
    } : undefined)).data;

    this.log.log("Got Applications Config", applicationsConfig);

    runInAction(() => this.applicationsConfig = applicationsConfig);
  }

  async fetchApplications(limit = this.limit, offset = 0, append = false): Promise<ApiBackCampaignsApplicationModel[]> {
    if (!this.backAppStore.campaignId) throw new Error("Please select campaign");

    const campaignId = this.backAppStore.campaignId;
    const educationTypeId = this.backAppStore.educationTypeId;

    this.log.log("Fetching Applications...");

    ApiBackCampaignsApplicationsGetSource?.cancel("fetchApplications called");

    const applications:ApiBackCampaignsApplicationModel[] = (await ApiBackCampaignsApplicationsGet(campaignId, {
      ...(offset ? {offset: offset} : {}),
      ...(limit ? {limit: limit} : {}),
      ...(this.configuredFiltersParams ? this.configuredFiltersParams : {}),
      ...(educationTypeId && educationTypeId !== "all" ? {education_type: educationTypeId} : {}),
      ...(this.query ? {search: this.query} : {}),
      ...(this.sortQuery ? {sort: this.sortQuery} : {})
    })).data.results;

    this.log.log("Got Applications", applications);

    runInAction(() => {
      if (append) {
        this.applications.replace(this.applications.concat(applications));
      } else {
        this.applications.replace(applications);
      }
    });

    return applications;
  }

  applyApplicationChanges(applications: ApiBackCampaignsApplicationModel[]) {
    applications.forEach(changedApplication => {
      const application = this.applications.find(a => a.id === changedApplication.id);

      if (application) {
        application.answers = observable.array(changedApplication.answers);
      } else {
        this.applications.push(changedApplication);
      }
    });
  }

  runFetchApplications() {
    this.state = "pending";

    this.fetchApplications().then(() => {
      runInAction(() => {
        this.state = "ok";
      });
    }, e => {
      if (axios.isCancel(e)) {
        this.log.log("Previous fetchApplications canceled");

        runInAction(() => {
          this.state = "ok";
        });
      } else {
        runInAction(() => {
          this.error = e;
          this.state = "error";
        });
      }
    });
  }

  get canLoadMore() {
    if (this.query) {
      return this.applications.length === this.limit;
    } else {
      return this.backAppStore.campaign?.applications_count && this.applications.length < this.backAppStore.campaign?.applications_count;
    }
  }

  loadMore() {
    this.state = "refresh";

    this.limit += STEP;

    this.fetchApplications(STEP, this.limit - STEP, true).then((appllications) => {
      if (appllications.length === 0) {
        // we've reached the limit
        this.limit -= STEP;
        toastsStore.addToast({
          props: {
            autohide: true,
            delay: 5000
          },
          body: "No more applicants"
        });
      }
      runInAction(() => {
        this.state = "ok";
      });
    }).catch(e => {
      if (axios.isCancel(e)) {
        this.log.log("Previous fetchApplications canceled");
      } else {
        runInAction(() => {
          this.error = e;
          this.state = "error";
        });
      }
    });
  }

  async load(): Promise<void> {
    this.state = "pending";

    try {

      //🔥🔥🔥 ВАЖНО! сначала грузим конфиг, чтоб получить фильтры — толко затем грузим заявки!
      await this.fetchApplicationsConfig();
      await this.fetchApplications();

      runInAction(() => {
        this.state = "ok";
      });
    } catch (e) {
      if (axios.isCancel(e)) {
        this.log.log("Previous fetchApplications or fetchApplicationsConfig canceled");

        runInAction(() => {
          this.state = "ok";
        });
      } else {
        runInAction(() => {
          this.error = e;
          this.state = "error";
        });
      }
    }
  }

  async refresh(): Promise<void> {
    if (!this.applications.length) {
      return;
    }

    this.log.log("Refreshing applications...");

    this.state = "refresh";
    try {
      await this.fetchApplications();
      runInAction(() => {
        this.state = "ok";
      });
    } catch (e) {
      if (axios.isCancel(e)) {
        this.log.log("Previous fetchApplications canceled");
        runInAction(() => {
          this.state = "ok";
        });
      } else {
        runInAction(() => {
          this.error = e;
          this.state = "error";
        });
      }
    }
  }

  modal: undefined | ApplicationActionConfirmationModalStore;

  showApplicationActionConfirmation(applicationAction: ApiApplicationAction, applications: string) {
    this.modal = new ApplicationActionConfirmationModalStore(this, applicationAction, true, applications);
  }

  hideModal() {
    this.modal = undefined;
  }

  reportState: "ok" | "pending" = "ok";

  async report() {
    if (this.isAllColumnsHidden) throw new Error("all fields are hidden");
    if (!this.backAppStore.campaignId) throw new Error("campaignId undefined");
    if (!this.backAppStore.educationTypeId) throw new Error("educationTypeId undefined");

    const educationTypeId = this.backAppStore.educationTypeId;

    const response = await ApiBackCampaignsApplicationsExportPost(this.backAppStore.campaignId, {
      ...(this.configuredFiltersParams ? this.configuredFiltersParams : {}),
      ...(educationTypeId && educationTypeId !== "all" ? {education_type: educationTypeId} : {}),
      ...(this.query ? {search: this.query} : {}),
      ...(this.sortQuery ? {sort: this.sortQuery} : {}),
      fields: this.visibleColumns.map(c => c.id).join(","),
    });

    if (response.status === 201 && response.data.id) {
      return response.data;
    } else {
      throw new Error("Failed making report request");
    }
  }

  handleReport() {
    if (this.reportState === "ok") {
      runInAction(() => this.reportState = "pending");

      this.report().then((report) => {
        toastsStore.addToast({
          props: {
            autohide: true,
            delay: 6400,
          },
          header: "Report requested",
          body: <>
            Go to <Link to={`/admin/${this.backAppStore?.campaignId}/${this.backAppStore?.educationTypeId}/reports`} className={"text-info"}>Reports</Link> page to download {report.display_name} report
          </>,
        });
      }, (e) => {
        this.log.error(e);
        toastsStore.addToast({
          props: {
            autohide: true,
            delay: 6400,
          },
          header: "Report request failed",
          body: "Try again later. Contact support, if problem persists.",
          variant: "danger",
        });
      }).finally(() => runInAction(() => this.reportState = "ok"));
    }
  }

  clear() {
    this.applications.clear();
    this.applicationsConfig = undefined;
    this.selectedApplications.clear();
    this.modal = undefined;
    this.hiddenColumns.clear();
    this.error = undefined;
    this.state = "init";
    this.isSleep = false;
    this.limit = LIMIT;
    this.query = "";
  }
}

export const useBackApplicationsPageStore = createPageStoreHook<BackApplicationsPageStore, ConstructorParameters<typeof BackApplicationsPageStore>>((...args: ConstructorParameters<typeof BackApplicationsPageStore>) => new BackApplicationsPageStore(...args));

export default BackApplicationsPageStore;