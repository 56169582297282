import BasePageStore, {PageState} from "../BasePageStore";
import {Log} from "../../helpers/log";
import {makeObservable, observable, runInAction} from "mobx";
import {ApiApplicationIdRenderedGet} from "../../services/api/applications/ApiApplicationIdRenderedGet";
import ApiApplicationRenderedModel from "../../services/api/applications/ApiApplicationRenderedModel";
import {applicationsStore} from "../singletones/ApplicationsStore";
import {SkFormValues} from "../../components/SkForms/SkForm";
import AnswersFromFormValues from "../../helpers/factory/AnswersFromFormValues";
import {ApiAnswersCreateMultiplePost} from "../../services/api/answers/ApiAnswersCreateMultiplePost";
import {ApiApplicationsSubmitPost} from "../../services/api/applications/ApiApplicationsSubmitPost";
import {AxiosResponse} from "axios";
import StoreWithFormsFromData, {ApiApplicationFormStore} from "../../helpers/factory/StoreWithFormsFromData";
import AnswersDiff from "../../helpers/AnswersDiff";
import ApiAnswerPostModel from "../../services/api/applications/ApiAnswerModel";

export interface ApplicationPageModel extends ApiApplicationRenderedModel {
  forms: ApiApplicationFormStore[];
}

class ApplicationPageStore extends BasePageStore {
  log = new Log("ApplicationPageStore");
  state:PageState | "empty" | "submit" = "init";
  error: unknown;
  submitError = "";

  constructor(load = false) {
    super();

    makeObservable(this, {
      model: observable,
      applicationId: observable,
      state: observable,
      error: observable
    });

    if (load) {
      this.load();
    }
  }

  applicationId = "";

  model:ApplicationPageModel|null = null;
  get expandedForm() {
    return this.model?.forms.find(f => !f.fulfilled)?.id || "";
  }

  async loadModel(response?:AxiosResponse<ApiApplicationRenderedModel>):Promise<void> {
    if (!this.applicationId) throw Error("applicationId is not set");

    let data:ApiApplicationRenderedModel;

    if (response) {
      data = response.data;
    } else {
      data = (await ApiApplicationIdRenderedGet(this.applicationId)).data;
    }

    this.log.log("loaded ApplicationPageStore with model", data);

    runInAction(() => {
      this.model = StoreWithFormsFromData<ApiApplicationRenderedModel, ApplicationPageModel>(data);
      return;
    });
  }

  async load():Promise<void> {
    this.log.log("loading ApplicationPageStore");
    try {
      const currentApplication = await applicationsStore.getCurrentApplication();

      if (currentApplication?.id) {
        this.log.log("got currentApplication with id", currentApplication?.id);

        runInAction(() => {
          this.applicationId = currentApplication.id;
        });

        await this.loadModel();
        runInAction(() => {
          this.state = "ok";
        });
      } else {
        this.log.error("got currentApplication without id", currentApplication);

        runInAction(() => {
          this.state = "empty";
        });
      }

      return;
    } catch (e) {
      runInAction(() => {
        this.state = "error";
        this.error = e;
      });
    }

    return;
  }

  // resolve to success, reject with form fields errors
  async submitAnswers(fid: string, values: SkFormValues) {
    const model = this.model;
    if (!model) throw Error("Model is broken");
    const applicationId = this.applicationId;
    if (!applicationId) throw Error("Application id not found");
    const form = model.forms.find(f => f.id === fid);
    if (!form) throw Error("Form is broken");

    runInAction(() => {
      this.state = "pending";
    });

    // сложная фигня — массив айдишников вопросов этой формы, которые не задизеймблены
    const formQuestionsIdsNotDisabled = form.questions.filter(q => this.model?.questions_readonly.indexOf(q.id) === -1).map(q => q.id);

    let answers
    if (fid !== "eafc0327-b5ef-40fc-b04e-87cea90964cf") {
      answers = AnswersDiff(AnswersFromFormValues(values, applicationId, this.model?.questions_readonly), this.model?.answers.filter(a => formQuestionsIdsNotDisabled.indexOf(a.question) !== -1));
    } else {
      const removeCheckboxes = (answers: ApiAnswerPostModel[]) => {
        return answers.filter((answer) => {
          return !answer.question.includes("same")
        })
      }
      answers = removeCheckboxes(AnswersFromFormValues(values, applicationId, this.model?.questions_readonly))
    }
    // posting
    try {
      await ApiAnswersCreateMultiplePost(answers);
    } catch (e) {
      runInAction(() => {
        this.state = "ok";
      });
      throw e;
    }

    // Renew application status
    try {
      await this.loadModel();
    } catch (e) {
      runInAction(() => {
        this.state = "ok";
      });
      throw e;
    }

    runInAction(() => {
      this.state = "ok";
    });
  }

  async submit() {
    const applicationId = this.applicationId;
    if (!applicationId) throw Error("Application id not found");

    runInAction(() => {
      this.state = "submit";
    });

    let response;

    try {
      response = await ApiApplicationsSubmitPost(applicationId);
    } catch (e) {
      runInAction(() => {
        this.state = "ok";
      });
      throw e;
    }

    try {
      await this.loadModel(response);
    } catch (e) {
      runInAction(() => {
        this.state = "ok";
      });
      throw e;
    }

    runInAction(() => {
      this.state = "ok";
    });
  }
}

export default ApplicationPageStore;
