import * as yup from "yup";
import {AxiosResponse} from "axios";
import AxiosApiInstance, {APIBase} from "../AxiosApiInstance";
import ApiApplicationModel, {ApiApplicationModelSchema} from "../applications/ApiApplicationModel";
import ApiCampaignShortModel from "../campaigns/ApiCampaignModel";

export interface APIUsersMeResponse {
  id: number;
  username: string;
  first_name: string;
  last_name: string;
  email: string;

  phone?: string;
  educational_program?: string;
  reg_stage?: string;

  applications: ApiApplicationModel[];
  campaigns?: ApiCampaignShortModel[];
}

export const APIUsersMeResponseSchema = yup.object().shape({
  id: yup.number().required(),
  username: yup.string().required(),
  first_name: yup.string(),
  last_name: yup.string(),
  email: yup.string().email(),
  applications: yup.array().of(ApiApplicationModelSchema),
});

export const ApiUsersMe: () => Promise<AxiosResponse<APIUsersMeResponse>> = () => {
  return AxiosApiInstance.get(APIBase + "users/me/");
};