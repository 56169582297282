import ApiBackCampaignsConfigsNotificationsGet
  from "../../../services/api/back/campaigns/configs/notifications/ApiBackCampaignsConfigsNotificationsGet";
import ApiNotificationsConfig
  from "../../../services/api/back/campaigns/configs/notifications/ApiNotificationsConfig";
import {makeAutoObservable, observable, runInAction} from "mobx";
import NotificationsConfigItemStore from "./NotificationsConfigItemStore";
import BackAppStore from "../pages/BackAppStore";

class NotificationsConfigsStore {
  constructor(private backAppStore: BackAppStore, public state: "init" | "pending" | "ok" | "error" = "init") {
    makeAutoObservable(this, {
      notificationsConfigs: false
    });

    if (state === "init") {
      this.fetch()
        .then(() => runInAction(() => this.state = "ok"))
        .catch(() => runInAction(() => this.state = "error"));
    }
  }

  notificationsConfigs = observable.array<NotificationsConfigItemStore>([], {deep: false});

  async fetch() {
    const campaign = this.backAppStore.campaign;
    if (!campaign) throw new Error("no campaign selected");

    runInAction(() => this.state = "pending");

    try {
      const notificationsConfigsRaw:ApiNotificationsConfig[]  = (await ApiBackCampaignsConfigsNotificationsGet(campaign?.id)).data.results;
      runInAction(() => {
        this.notificationsConfigs.replace(notificationsConfigsRaw.map((data) => new NotificationsConfigItemStore(this, data)));
        this.state = "ok";
      });
    } catch (e) {
      console.log(e);
      runInAction(() => this.state = "error");
    }
  }
}

export default NotificationsConfigsStore;