import React from "react";
import {Form} from "react-bootstrap";
import NumberFormat from "react-number-format";
import useSkFieldValidInvalid from "../useSkFieldValidInvalid";
import SkFieldProps from "./SkFieldProps";
import isSkFieldSubmitted from "./IsSkFieldSubmitted";
import SkFieldDescriptionBlock from "./SkFieldDescriptionBlock";
import SkFieldLabel from "./SkFieldLabel";
import {numberFormatProps} from "../../../const/numberFormatProps";

const SkFieldNumber:React.FC<SkFieldProps> = (props) => {
  const {isInvalid, setReallyTouched} = useSkFieldValidInvalid(props);

  return (
    <Form.Group controlId={props.name}>
      <SkFieldLabel {...props}/>
      <NumberFormat
        customInput={Form.Control}
        as={"input"}
        type="tel"
        name={props.name}
        value={props.formikProps.values[props.name] || ""}
        onChange={props.formikProps.handleChange}
        isValid={isSkFieldSubmitted(props)}
        isInvalid={isInvalid}
        disabled={props.disabled || props.formikProps.isSubmitting}
        onBlur={() => setReallyTouched(true)}
        {...numberFormatProps}
      />
      <SkFieldDescriptionBlock
        description={props.description}
        isInvalid={isInvalid}
        errorText={`${props.formikProps.errors[props.name]}`}
      />
    </Form.Group>
  );
};

export default SkFieldNumber;