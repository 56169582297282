import React from "react";
import {Badge, BadgeProps, Spinner} from "react-bootstrap";
import {observer} from "mobx-react-lite";
import {ApiReportAvailable} from "../../../services/api/back/campaigns/reports/ApiBackCampaignsReportsAvailableGet";
import ReportStore from "../../../stores/backoffice/reports/ReportStore";
import {ReportStatus} from "../../../services/api/back/campaigns/reports/ApiBackReportModel";
import camelcase from "camelcase";
import {Variant} from "react-bootstrap/types";

interface Props extends BadgeProps {
  report: ReportStore;
  reportsAvailable: ApiReportAvailable[];
}

const ReportName:React.FC<Props> = observer(({report, reportsAvailable}) => {
  const model = report?.model;
  if (!model) return <></>;

  const status:ReportStatus = camelcase(model.status);
  let variant:Variant = "primary";
  if (status === "created") variant = "info";
  if (status === "finished") variant = "success";

  return <span className={"d-flex align-items-center"}>
    <span>
      {reportsAvailable.find(ra => ra.slug === report.model.report_type)?.name || `Report #${report.model.id}`}
    </span>
    {report.updating && <Spinner className={"ml-2"} animation={"grow"} size={"sm"}/>}
    <Badge pill variant={variant} className={"ml-2"}>{status}</Badge>
  </span>;
});

export default ReportName;
