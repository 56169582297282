import React from "react";
import {observer} from "mobx-react-lite";
import BackAppStore from "../../stores/backoffice/pages/BackAppStore";
import {GearWideConnected} from "react-bootstrap-icons";
import {Col, Container, Nav, Row} from "react-bootstrap";
import ErrorBoundary from "../../components/ErrorBoundary";
import BackSettingsPageStore, {useBackSettingsPageStore} from "../../stores/backoffice/pages/BackSettingsPageStore";
import PageName from "../../components/backoffice/PageName";
import PageInfo from "../../interfaces/back/PageInfo";
import {NavLink, Redirect, Route, Switch, useRouteMatch} from "react-router-dom";
import NotificationsConfigs from "../../components/backoffice/configs/NotificationsConfigs";
import Loading from "../Loading";
import ProgramsConfigs from "../../components/backoffice/configs/ProgramsConfigs";

export const settingsPageStoreContext = React.createContext<BackSettingsPageStore>({} as BackSettingsPageStore);

export const pageInfo: PageInfo = {
  name: "Settings",
  icon: <GearWideConnected/>
};

const BackSettingsPage: React.FC<{
  backAppStore: BackAppStore
}> = observer(({
  backAppStore
}) => {
  const pageStore = useBackSettingsPageStore(backAppStore, true);
  const {url} = useRouteMatch();

  return <ErrorBoundary errorLevel={"BackApplicationsPage"}>
    <settingsPageStoreContext.Provider value={pageStore}>
      <Container>
        <Row>
          <Col>
            <div className={"d-flex align-items-baseline"}>
              <PageName
                icon={pageInfo.icon}
                name={pageInfo.name}
                pageState={pageStore.state}
                className={"mr-4 mb-0"}
              />
              <div className={"flex-grow-1 mr-4"}/>
              <div>
                <Nav variant="pills">
                  <Nav.Item>
                    <NavLink to={`${url}/notifications`} className="nav-link">Notifications</NavLink>
                  </Nav.Item>
                  <Nav.Item>
                    <NavLink to={`${url}/tracks`} className="nav-link">Educational Programs & Tracks</NavLink>
                  </Nav.Item>
                </Nav>
              </div>
            </div>
          </Col>
        </Row>
      </Container>

      <ErrorBoundary errorLevel={"BackApplicationsPage-bottom"}>
        <Container>
          <Row>
            <Col>
              <Switch>
                <Route path={`${url}/notifications`}>
                  <Loading loading={pageStore.notificationsConfigsStore.state === "pending"}>
                    <ErrorBoundary errorLevel={"NotificationsConfigs"}>
                      <NotificationsConfigs store={pageStore.notificationsConfigsStore}/>
                    </ErrorBoundary>
                  </Loading>
                </Route>
                <Route path={`${url}/tracks`}>
                  <Loading loading={pageStore.programsConfigsStore.state === "pending"}>
                    <ErrorBoundary errorLevel={"ProgramsConfigs"}>
                      <ProgramsConfigs store={pageStore.programsConfigsStore}/>
                    </ErrorBoundary>
                  </Loading>
                </Route>
                <Route path={`${url}`}>
                  <Redirect to={`${url}/notifications`}/>
                </Route>
              </Switch>
            </Col>
          </Row>
        </Container>
      </ErrorBoundary>
    </settingsPageStoreContext.Provider>
  </ErrorBoundary>;
});

export default BackSettingsPage;
