import {makeAutoObservable} from "mobx";
import {APIUsersMeResponse} from "../../services/api/users/ApiUsersMe";
import {ApiCampaignShortModel} from "../../services/api/campaigns/ApiCampaignModel";



class User {
  id: number;
  username: string;
  firstName: string;
  lastName: string;
  email: string;
  userpic: string;

  phone?: string;
  educational_program?: string;
  reg_stage?: string;

  campaigns?: ApiCampaignShortModel[];

  get backofficeAccess() {
    return this.campaigns && this.campaigns.length;
  }

  constructor(payload: APIUsersMeResponse) {
    this.id = payload.id;
    this.username = payload.username;
    this.firstName = payload.first_name;
    this.lastName = payload.last_name;
    this.email = payload.email;

    if (payload.campaigns?.length) {
      this.campaigns = payload.campaigns;
    }

    //TODO userpics!
    this.userpic = "https://source.unsplash.com/random";

    //TODO сделать campaigns субсторами
    makeAutoObservable(this);
  }
}

export default User;