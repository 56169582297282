/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {useEffect, useRef} from "react";
import {useAuth} from "../../stores/AuthStore";
import User from "../../stores/models/User";

const GTAGIntegration:React.FC<{
  id: string
}> = ({id}) => {
  const scripts = useRef<(HTMLElementTagNameMap["script"]|HTMLElementTagNameMap["noscript"])[]>();

  useEffect(() => {
    scripts.current = [];

    const asyncScript = document.createElement("script");
    asyncScript.type = "text/javascript";
    asyncScript.src = `https://www.googletagmanager.com/gtag/js?id=${id}`;
    asyncScript.async = true;

    const dataLayerScript = document.createElement("script");
    dataLayerScript.type = "text/javascript";
    dataLayerScript.text = "window.dataLayer = window.dataLayer || [];\n" +
      "        function gtag(){dataLayer.push(arguments);}\n" +
      "        gtag('js', new Date());\n" +
      "\n" +
      `        gtag('config', '${id}', {\n` +
      "            'linker': {\n" +
      "                'domains': ['apply.skoltech.ru', 'student.skoltech.ru']\n" +
      "            }\n" +
      "        });";

    const GTMScript = document.createElement("script");
    GTMScript.type = "text/javascript";
    GTMScript.text = "(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0], j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src= 'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','GTM-WTVKRFR');";

    const GTMNoScript = document.createElement("noscript");
    GTMNoScript.innerHTML = "<iframe src=\"https://www.googletagmanager.com/ns.html?id=GTM-WTVKRFR\" height=\"0\" width=\"0\" style=\"display:none;visibility:hidden\"></iframe>";

    scripts.current?.push(asyncScript, dataLayerScript, GTMScript, GTMNoScript);

    scripts.current?.forEach(c => document.body.appendChild(c));

    return () => {
      if (scripts.current) scripts.current?.forEach(c => document.body.removeChild(c));
    };
  });

  return null;
};

const ChatraIntegration:React.FC<{
  chatraId?: string;
}> = ({chatraId}) => {
  const script = useRef<HTMLElementTagNameMap["script"]>();
  const user = useAuth().user;

  useEffect(() => {
    script.current = document.createElement("script");
    script.current.type = "text/javascript";
    script.current.text = "(function(d, w, c) {\n" +
      "        w.ChatraID = 'xCpe3ZTjhK2essEkZ';\n" +
      "        var s = d.createElement('script');\n" +
      "        w[c] = w[c] || function() {\n" +
      "            (w[c].q = w[c].q || []).push(arguments);\n" +
      "        };\n" +
      "        s.async = true;\n" +
      "        s.src = (d.location.protocol === 'https:' ? 'https:': 'http:')\n" +
      "            + '//call.chatra.io/chatra.js';\n" +
      "        if (d.head) d.head.appendChild(s);\n" +
      "    })(document, window, 'Chatra');";

    document.body.appendChild(script.current);

    (window as any).ChatraSetup = {
      clientId: chatraId ? chatraId : "dc73509696b61a6f6d88df13c05541f4b5f1fd11169fc0ce243b98fc94b63583",
      language: "en",
      buttonStyle: 'tab'
    };

    return () => {
      script.current && document.body.removeChild(script.current);
      (window as any).ChatraSetup = undefined;
    };
  });

  useEffect(() => {
    if (user) {
      const u: User = user;

      (window as any).ChatraIntegration = {
        name: `${u.firstName} ${u.lastName}`,
        email: u.email,
        phone: u.phone || "-",
        "Educational Program": u.educational_program || "-",
        "Reg Stage": u.reg_stage || "-",
      };
    } else {
      (window as any).ChatraIntegration = {
        name: "Unauthorized",
        email: "-",
        phone: "-",
        "Educational Program": "-",
        "Reg Stage": "-",
      };
    }

    return () => (window as any).ChatraIntegration = undefined;
  }, [user]);

  return null;
};

type WithIntegrationsProps = React.PropsWithChildren<{
  chatra: boolean;
  gtm: boolean;
  chatraId?: string;
}>;

const WithIntegrations:React.FC<WithIntegrationsProps> = ({chatra,gtm, chatraId, children}) => (
  <>
    {children}
    {chatra ? <ChatraIntegration chatraId={chatraId}/> : null}
    {gtm ? <GTAGIntegration id={"UA-112601221-1"}/> : null}
  </>
);

export default WithIntegrations;
