import {AxiosResponse} from "axios";
import AxiosApiInstance, {APIBase} from "../../../AxiosApiInstance";
import ApiApplicationField from "./ApiApplicationField";
import ApiApplicationAction from "../actions/ApiApplicationAction";
import ApiApplicationFilter from "./ApiApplicationFilter";

export interface ApiBackCampaignsApplicationsConfigGetParams {
  education_type?: string;
}

export interface ApiBackCampaignsApplicationsConfigModel {
  available_fields?: ApiApplicationField[];
  available_filters?: ApiApplicationFilter[];
  available_batch_actions?: ApiApplicationAction[];
  available_individual_actions?: ApiApplicationAction[];
}

export const ApiBackCampaignsApplicationsConfigGet: (campaign_pk: string, params?: ApiBackCampaignsApplicationsConfigGetParams) => Promise<AxiosResponse<ApiBackCampaignsApplicationsConfigModel>> = (campaign_pk, params) => {
  return AxiosApiInstance.get(`${APIBase}back/campaigns/${campaign_pk}/applications_config/`, {
    params: params
  });
};