import AxiosApiInstance, {APIBase} from "../../../../AxiosApiInstance";
import {AxiosResponse} from "axios";
import ApiEducationalTrack from "./ApiEducationalTrack";

// это апи-метод по новому типу
// вместо обязательных параметров он выбрасывает ошибку, если чего-то не хватает

const ApiBackCampaignsConfigsTracksIdPatch
  = (campaign_pk: string, payload: ApiEducationalTrack)
  :Promise<AxiosResponse<ApiEducationalTrack>> => {
    if (!payload.id) throw new Error("ApiBackCampaignsConfigsTracksIdPatch: no-id error");

    return AxiosApiInstance.patch(`${APIBase}back/campaigns/${campaign_pk}/configs/tracks/${payload.id}/`, payload);
  };

export default ApiBackCampaignsConfigsTracksIdPatch;