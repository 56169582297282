import AxiosApiInstance, {APIBase} from "../AxiosApiInstance";
import {AxiosResponse} from "axios";

type ApiApplicationIdVariablesGetResponse = {
  name: string;
  value: string;
}[];

export const ApiApplicationIdVariablesGet: (id:string) => Promise<AxiosResponse<ApiApplicationIdVariablesGetResponse>> = (id) => {
  return AxiosApiInstance.get(APIBase + `applications/${id}/variables/`);
};