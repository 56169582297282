import React from "react";

import WithToasts from "./WithToasts";
import WithFileUploader from "./WithFileUploader";
import WithIntegrations from "./WithIntegrations";

type WithDefaultsProps = React.PropsWithChildren<{
  backoffice?: boolean;
  chatra?: boolean;
  gtm?: boolean;
  chatraId?: string;
}>;

const WithDefaults:React.FC<WithDefaultsProps> = (props) => (
  <WithToasts>
    <WithFileUploader>
      <WithIntegrations
        chatraId={props.chatraId}
        chatra={props.chatra !== false} // it works like deafult value is true
        gtm={props.gtm !== false}
      >
        {props.children}
      </WithIntegrations>
    </WithFileUploader>
  </WithToasts>
);

export default WithDefaults;
