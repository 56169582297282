import {MutableRefObject, useCallback, useRef} from "react";

export interface Bag {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
}

export type BagPasser<B> = (passedBag: B|undefined) => void;

function useBag<B = Bag>():[MutableRefObject<B|undefined>, BagPasser<B>] {
  const bagRef: MutableRefObject<B|undefined> = useRef<B|undefined>(undefined);
  const passBag: BagPasser<B> = useCallback((passedBag: B|undefined) => bagRef.current = passedBag, []);
  return [bagRef, passBag];
}

export default useBag;
