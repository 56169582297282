import React from "react";
import FileUploaderBlock from "../../components/FileUploaderBlock";

const WithFileUploader:React.FC = (props) => (
  <>
    <FileUploaderBlock/>
    {props.children}
  </>
);

export default WithFileUploader;