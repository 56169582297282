import React, {ErrorInfo} from "react";
import {Alert} from "react-bootstrap";

interface ErrorDisplayProps {
  error: unknown | Error;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  errorLogger?: (...args: any) => void;
}

interface ErrorDisplayState {
  caughtError: unknown | Error;
}

class ErrorDisplay extends React.Component<ErrorDisplayProps, ErrorDisplayState> {
  constructor(props: ErrorDisplayProps) {
    super(props);
    this.state = {
      caughtError: null
    };
  }

  static getDerivedStateFromError(error: unknown) {
    return {
      caughtError: error
    };
  }

  componentDidCatch(error: unknown, errorInfo: ErrorInfo) {
    this.props.errorLogger && this.props.errorLogger(error, errorInfo);
  }

  render() {
    const error = this.state.caughtError || this.props.error;

    if (error) {
      return <Alert variant="danger" onClose={() => window.location.reload()} dismissible>
        <Alert.Heading>An error occured</Alert.Heading>
        <p>
          Please, open <b>developer's console</b> and make a screenshot of the whole browser's window.
        </p>
        <p>
          Contact our support and provide them with a screenshot and problem's details.
        </p>
        <p>
          We're sorry for the inconveniences.
        </p>
        <h3>Error message:</h3>
        <p>
          {`${(error && (error as Error).message) || error}`}
        </p>
      </Alert>;
    } else {
      return <>
        {this.props.children}
      </>;
    }


  }
}

export default ErrorDisplay;