import {useMemo} from "react";
import { DateTime } from "luxon";

interface WindowWithLogs extends Window {
  sklogs?: {
    [level: string]: string;
  }
}

export const devLog = process.env.LOG === "true"

export class Log {
  push(message: string) {
    const w = window as WindowWithLogs;

    if (!w.sklogs) {
      w.sklogs = {};
    }

    w.sklogs[this.level] = message;
  }

  constructor(public level: string) {}


  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  log(...args: any) {
    const prefix = `[${DateTime.local().toHTTP()}][${this.level}]:`;

    if (devLog) {
      console.log(prefix,...args);
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    this.push(`${prefix} ${args.map((a:any) => `${a}`).join(", ")}`);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  error(...args: any) {
    const prefix = `[ERROR][${DateTime.local().toHTTP()}][${this.level}]:`;

    if (devLog) {
      console.error(prefix,...args);
    }


    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    this.push(`${prefix} ${args.map((a:any) => `${a}`).join(", ")}`);
  }
}

export const useLog = (level: string) => {
  return useMemo(() => new Log(level), [level]);
};
