import React from "react";
import {RecommendationRequestState} from "../services/api/recommendations/ApiRecommendationModel";
import {Badge} from "react-bootstrap";

const RecommendationRequestStatusBadge:React.FC<{requestState: RecommendationRequestState}> = ({requestState}) => {
  if (requestState === RecommendationRequestState.NotSent) return <Badge variant="secondary">Not Sent</Badge>;
  if (requestState === RecommendationRequestState.Delivered) return <Badge variant="primary">Delivered</Badge>;
  if (requestState === RecommendationRequestState.NotDelivered) return <Badge variant="warning">Not Delivered</Badge>;
  if (requestState === RecommendationRequestState.Sending) return <Badge variant="primary">Sending</Badge>;
  if (requestState === RecommendationRequestState.Sent) return <Badge variant="success">Sent</Badge>;
  return <Badge variant="secondary">{requestState}</Badge>;
};

export default RecommendationRequestStatusBadge;