import axios, {AxiosResponse} from "axios";
import {APIBase} from "../AxiosApiInstance";

export interface ApiUsersResetPasswordPostResponse {
  status: "ok";
}

export interface ApiUsersResetPasswordPostResponseError {
  status: "error";
  error_text: string;
}

export const ApiUsersResetPasswordPost: (email: string) => Promise<AxiosResponse<ApiUsersResetPasswordPostResponse | ApiUsersResetPasswordPostResponseError>> = (email) => {
  return axios.post(APIBase + "users/reset_password/", {email: email});
};
export default ApiUsersResetPasswordPost;