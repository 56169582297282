import React, {useContext} from "react";
import {ApiQuestionRenderedModel} from "../../services/api/questions/ApiQuestionModel";
import {SkFormValues} from "./SkForm";
import {Alert} from "react-bootstrap";
import {ApiApplicationFormStore} from "../../helpers/factory/StoreWithFormsFromData";

export interface SkFormMeta {
  form: string;
  application: string;
}

export const SkFormMetaContext = React.createContext<SkFormMeta|undefined>(undefined);

export const SkFormMetaChecker:React.FC<{
  name: string;
  children: (formMeta: SkFormMeta) => JSX.Element | null;
}> = ({name, children}) => {
  const formMeta = useContext(SkFormMetaContext);

  if (formMeta) return children(formMeta);
  else return <Alert variant={"danger"}>
    An error occurred. Can't use {name} without form meta context. Please, contact support.
  </Alert>;
};

export interface SkFormProps {
  reactiveInitialValues?: SkFormValues;
  questions: ApiQuestionRenderedModel[];
  questionsDisabled?: string[];
}

export const SkFormPropsContext = React.createContext<SkFormProps|undefined>(undefined);

export const SkFormPropsChecker:React.FC<{
  name: string;
  children: (formMeta: SkFormProps) => JSX.Element | null;
}> = ({name, children}) => {
  const formProps = useContext(SkFormPropsContext);

  if (formProps) return children(formProps);
  else return <Alert variant={"danger"}>
    An error occurred. Can't use {name} without form props context. Please, contact support.
  </Alert>;
};

export const SkFormStoreContext = React.createContext<ApiApplicationFormStore|undefined>(undefined);

export const SkFormStoreChecker:React.FC<{
  name: string;
  children: (formStore: ApiApplicationFormStore) => JSX.Element | null;
}> = ({name, children}) => {
  const formStore = useContext(SkFormStoreContext);

  if (formStore) return children(formStore);
  else return <Alert variant={"danger"}>
    An error occurred. Can't use {name} without form props context. Please, contact support.
  </Alert>;
};
