import * as yup from "yup";

interface ApiRecommendationForRecommenderModel {
  applicant_print_name: string;
  recommender_print_name: string;
  recommendation_text: string;
  applicant_email: string;
  recommender_email: string;
}

export const ApiRecommendationForRecommenderSchema = yup.object().shape({
  applicant_print_name: yup.string().required(),
  recommender_print_name: yup.string().required(),
  recommendation_text: yup.string(),
  applicant_email: yup.string(),
  recommender_email: yup.string(),
});

export default ApiRecommendationForRecommenderModel;