import {makeAutoObservable, observable, runInAction} from "mobx";
import BackAppStore from "../pages/BackAppStore";
import ApiProgramConfigGet from "../../../services/api/back/campaigns/configs/programs/ApiProgramConfigGet";
import ApiBackCampaignsConfigsProgramsGet
  from "../../../services/api/back/campaigns/configs/programs/ApiBackCampaignsConfigsProgramsGet";
import ProgramConfigItemStore from "./ProgramConfigItemStore";
import {ApiEducationTypeToVariant} from "../../../services/api/campaigns/ApiCampaignModel";
import BackSettingsPageStore from "../pages/BackSettingsPageStore";

class ProgramsConfigsStore {
  constructor(
    public backAppStore: BackAppStore,
    public settingsPageStore: BackSettingsPageStore,
    public state: "init" | "pending" | "ok" | "error" = "init"
  ) {
    makeAutoObservable(this, {
      programsConfigs: false
    });

    if (state === "init") {
      this.fetch()
        .then(() => runInAction(() => this.state = "ok"))
        .catch(() => runInAction(() => this.state = "error"));
    }
  }

  programsConfigs = observable.array<ProgramConfigItemStore>([], {deep: false});

  get educationTypeVariants():ApiEducationTypeToVariant[]|undefined {
    return this.backAppStore.campaign?.education_types_to_variants;
  }

  get defaultEducationTypeVariant():ApiEducationTypeToVariant|undefined {
    if (this.educationTypeVariants && this.educationTypeVariants.length) {
      return this.educationTypeVariants[0];
    } else {
      return undefined;
    }
  }

  async fetch() {
    const campaign = this.backAppStore.campaign;
    if (!campaign) throw new Error("no campaign selected");

    runInAction(() => this.state = "pending");

    try {
      const programsConfigsRaw:ApiProgramConfigGet[]  = (await ApiBackCampaignsConfigsProgramsGet(campaign?.id)).data.results;
      runInAction(() => {
        this.programsConfigs.replace(programsConfigsRaw.map((data) => new ProgramConfigItemStore(this, data)));
        this.state = "ok";
      });
    } catch (e) {
      console.log(e);
      runInAction(() => this.state = "error");
    }
  }
}

export default ProgramsConfigsStore;