import React, {
  ChangeEventHandler,
  MouseEventHandler,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";
import {Alert, Button, Card, Form, Modal, OverlayTrigger, ProgressBar, Tab, Tabs, Tooltip} from "react-bootstrap";
import useSkFieldValidInvalid from "../useSkFieldValidInvalid";
import SkFieldProps from "./SkFieldProps";
import SkFieldDescriptionBlock from "./SkFieldDescriptionBlock";
import SkFieldLabel from "./SkFieldLabel";
import RecommendationBlockStore from "../../../stores/RecommendationBlockStore";
import {observer, Observer} from "mobx-react-lite";
import {ApiRecommenderModel, RecommendationMode} from "../../../services/api/recommendations/ApiRecommendationModel";
import {useFormik} from "formik";
import SkFieldText from "./SkFieldText";
import * as yup from "yup";
import {SkFormMeta, SkFormMetaChecker} from "../SkFormContexts";
import useFileUploader, {FileStore, FileUploaderStore} from "../../../stores/singletones/FileUploaderStore";
import {runInAction} from "mobx";
import {FileBlock} from "../../FileUploaderBlock";
import Loading from "../../../pages/Loading";
import {ArrowRepeat, Check, ClockFill} from "react-bootstrap-icons";

interface RecommendationBlockData {
  store: RecommendationBlockStore;
  fieldProps:  SkFieldProps;
  meta: SkFormMeta;
  modalShow: boolean;
  setModalShow(show: boolean): void;
  isInvalid: boolean;
  setReallyTouched(reallyTouched: boolean): void;
  disabled: boolean;
}

const RecommendationBlockContext = React.createContext<RecommendationBlockData>({} as RecommendationBlockData);

const ModalRecommendationErrorMessage:React.FC = observer(() => {
  const {store} = useContext(RecommendationBlockContext);

  if (store.errorMessage) {
    return <Modal.Body>
      <Alert variant={"danger"} onClose={() => store.clearErrorMessage()} dismissible>{store.errorMessage}</Alert>
    </Modal.Body>;
  } else {
    return null;
  }
});

const BlindRecommendationForm:React.FC<{close: () => void}> = observer((props) => {
  const {store, fieldProps} = useContext(RecommendationBlockContext);
  const localFormik = useFormik<ApiRecommenderModel>({
    initialValues: store.recommendation.recommender || {
      position: "",
      first_name: "",
      last_name: "",
      email: "",
    },
    onSubmit: ((values, helpers) => {
      store.recommendation.recommender = values;
      store.postRecommendation().then(freshRecommendationId => {
        if (freshRecommendationId) {
          fieldProps.formikProps.setFieldValue(fieldProps.name, freshRecommendationId);
        }
      }).finally(() => {
        helpers && helpers.setSubmitting(false);
      });
    }),
    validationSchema: yup.object().shape({
      position: yup.string().required("Please enter recommender's position"),
      first_name: yup.string().required("Please enter recommender's first name"),
      last_name: yup.string().required("Please enter recommender's last name"),
      email: yup.string().email().required("Please provide recommender's email"),
    })
  });

  return <>
    <h5>Recommender's data:</h5>
    <Form onSubmit={localFormik.handleSubmit}>
      <SkFieldText formikProps={localFormik} name={"position"} label={"Title"}/>
      <SkFieldText formikProps={localFormik} name={"first_name"} label={"First Name"}/>
      <SkFieldText formikProps={localFormik} name={"last_name"} label={"Last Name"}/>
      <SkFieldText formikProps={localFormik} name={"email"} label={"Email"} transform={["lowercase"]}/>
      <div className={"d-flex"}>
        <Button type={"submit"} className={"flex-grow-1"} disabled={localFormik.isSubmitting} >Request a Recommendation</Button>
        <Button variant={"outline-primary"} className={"flex-grow-1 ml-3"} onClick={() => props.close()}>Close</Button>
      </div>
    </Form>
    <p className={"mt-2"}>
      <small>
        Your recommender will receive an email with instructions on how to submit their recommendation.<br/>
        You will be able to see recommendation status, but not the recommendation letter itself.<br/>
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <a href="#" onClick={e => {
          e.preventDefault();
          store.showInstructions();
        }}>Click here</a> to show first-time instructions again.<b/>
      </small>
    </p>
  </>;
});

const BlindRecommendationButton:React.FC = observer(() => {
  const {store, setModalShow, disabled} = useContext(RecommendationBlockContext);

  const somethingHappening = store.status !== "ok";

  const handleClick = useCallback(() => {
    if (store.status === "ok" && !disabled) {
      setModalShow(true);
    }
  }, [disabled, setModalShow, store.status]);

  if (somethingHappening) {
    return <Button disabled={disabled}>Loading <ArrowRepeat className={"animation-spin"}/></Button>;
  } else {
    return <Button disabled={disabled} onClick={handleClick}>Choose recommendation type</Button>;
  }
});

const RecommendationCard:React.FC<{
  details: boolean;
}> = observer(({details}) => {
  const {store, setModalShow, isInvalid, disabled, fieldProps} = useContext(RecommendationBlockContext);
  const updateFieldValue = () => fieldProps.formikProps.setFieldValue(fieldProps.name, store.recommendation.id);

  const handleShowDetails = useCallback((e) => {
    e.preventDefault();
    if (store.status === "ok") setModalShow(true);
  }, [setModalShow, store.status]);

  const fileStore:FileStore|undefined = useMemo(() => {
    if (store.recommendation.mode === RecommendationMode.File && store.recommendation.upload) {
      return new FileStore(null, store.recommendation.upload);
    }
  }, [store.recommendation.mode, store.recommendation.upload]);

  const border = isInvalid
    ? "danger"
    : store.recommendation.received || store.recommendation.mode === RecommendationMode.File
      ? "success"
      : store.recommendation.pending
        ? "warning"
        : undefined;

  if (store.recommendation.id && store.recommendation.mode === RecommendationMode.Blind && store.recommendation.recommender) {
    return <Card className={"mt-2 mb-2"} border={border}>
      {store.recommendation.received ? <div style={{position: "absolute", top: 4, right: 4}}>
        <Check className={"text-success"} style={{fontSize: "32px"}}/>
      </div> : null}
      {store.recommendation.pending ? <div style={{position: "absolute", top: 8, right: 8}}>
        <OverlayTrigger
          placement="bottom"
          delay={{show: 100, hide: 0}}
          overlay={
            <Tooltip id={`${store.recommendation.id}_pending`}>
              You can't submit your application with pending recommendation
            </Tooltip>
          }
        >
          <ClockFill className={"text-warning"} style={{fontSize: "20px"}}/>
        </OverlayTrigger>
      </div> : null}
      <Card.Body>
        {store.recommendation.received ? (
          <Card.Text>{store.recommendation.response_sent_at ? `${store.recommendation.response_sent_at} recommendation received from:` : "Recommendation received from"}</Card.Text>
        ) : store.recommendation.request_sent_at ? (
          <Card.Text>{store.recommendation.request_sent_at} recommendation request sent to:</Card.Text>
        ) : (
          <Card.Text>Pending recommendation request to:</Card.Text>
        )}
        <Card.Title className="mb-2">
          <b>{store.recommendation.recommender.position} {store.recommendation.recommender.first_name} {store.recommendation.recommender.last_name}</b><br/>
          <small>{store.recommendation.recommender.email}</small>
        </Card.Title>
        {details && !disabled && store.recommendation.received ? null : <Card.Text>
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <a href="#" onClick={handleShowDetails}><small>Show Details</small></a>
        </Card.Text>}
      </Card.Body>
    </Card>;
  } else if (store.recommendation.id && store.recommendation.mode === RecommendationMode.File && store.recommendation.upload && fileStore) {
    return <Card className={"mt-2 mb-2"} border={border}>
      <div style={{position: "absolute", top: 4, right: 4}}>
        <Check className={"text-success"} style={{fontSize: "32px"}}/>
      </div>
      <Card.Body>
        <Card.Text>Uploaded recommendation letter:</Card.Text>
        <FileBlock
          fileStore={fileStore}
          canEdit={!fieldProps.disabled}
          onDelete={() => store.deleteRecommendation().finally(updateFieldValue)}
          download={false}
        />
        {details && !disabled ? (<Card.Text>
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <a href="#" onClick={handleShowDetails}><small>Show Details</small></a>
        </Card.Text>) : null}
      </Card.Body>
    </Card>;
  } else {
    return <Alert variant={"danger"}>An error occurred with recommendation data. Please, contact our support.</Alert>;
  }
});

const UploadRecommendationDetails:React.FC = observer((props) => {
  // const {store, fieldProps} = useContext(RecommendationBlockContext);
  // const updateFieldValue = () => fieldProps.formikProps.setFieldValue(fieldProps.name, store.recommendation.id);

  return <>
    <Modal.Header closeButton>
      <Modal.Title>Recommendation letter uploaded</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <RecommendationCard details={false}/>
      <p className={"mb-2 mt-4"}>
      Your recommendation letter is uploaded. You may delete it and upload a different letter if you wish. The Admissions Committee will see only the last file you’ve uploaded.
      </p>
      <p className={"mb-2 mt-2"}>
        {props.children}
      </p>
    </Modal.Body>
  </>;
});

const BlindRecommendationDetails:React.FC = observer((props) => {
  const {store, fieldProps} = useContext(RecommendationBlockContext);
  const updateFieldValue = () => fieldProps.formikProps.setFieldValue(fieldProps.name, store.recommendation.id);

  if (!store.recommendation.id || !store.recommendation.recommender) return <Alert variant={"danger"}>An error occurred with recommender data. Please, contact our support.</Alert>;

  if (store.recommendation.received) {
    return <>
      <Modal.Header closeButton>
        <Modal.Title>Recommendation recieved</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <RecommendationCard details={false}/>
      </Modal.Body>
    </>;
  } else {
    return <>
      <Modal.Header closeButton>
        <Modal.Title>Recommendation pending...</Modal.Title>
      </Modal.Header>
      <ModalRecommendationErrorMessage/>
      <Modal.Body>
        <p><small>You’ve requsted recommendation from</small></p>
        <p>
          <b>{store.recommendation.recommender.position} {store.recommendation.recommender.first_name} {store.recommendation.recommender.last_name}</b><br/>
          <small>{store.recommendation.recommender.email}</small>
        </p>
        <p>
          <small>
            <ul>
              <li>Don’t forget to ask them to add skoltech@skoltech.ru to their list of ‘safe’ contacts or to check their inbox and spam folders. It is very common for automatic emails to end up in the spam folder, so make sure your recommenders are waiting for this email.</li>
              <li>Remind them about the deadline for submitting their recommendation. Late recommendations will not be accepted.</li>
            </ul>
          </small>
        </p>
        <p className={"mb-2 mt-4"}>
          Also you can
          {store.status === "ok" ? (
            // eslint-disable-next-line jsx-a11y/anchor-is-valid
            <a href="#" className={"text-danger ml-1 mr-1"} onClick={(e) => {
              e.preventDefault();
              store.deleteRecommendation().finally(updateFieldValue);
            }}>
              cancel your request
            </a>
          ) : (
            // eslint-disable-next-line jsx-a11y/anchor-is-valid
            <a href="#" className={"text-danger ml-1 mr-1"} onClick={(e) => {
              e.preventDefault();
            }}>
              cancel your request <ArrowRepeat className={"animation-spin"}/>
            </a>
          )}
          and start over.
        </p>
        <p>
          Remember, your application can be submitted only when your recommender has submitted his/her recommendation via the link received.
        </p>
        <p className={"mb-2 mt-2"}>
          {props.children}
        </p>
      </Modal.Body>
    </>;
  }
});

const BlindRecommendationUpload:React.FC<{close: () => void}> = observer((props) => {
  const {store, fieldProps, meta} = useContext(RecommendationBlockContext);
  const fileUploaderStore:FileUploaderStore = useFileUploader();
  //eslint-disable-next-line react-hooks/exhaustive-deps
  const fileStore:FileStore|undefined = useMemo(() => fileUploaderStore.files.length ? fileUploaderStore.files[0] : undefined, [fileUploaderStore.files.length]);

  useEffect(() => {
    fileUploaderStore.setUpUploader(store.recommendation.upload ? [store.recommendation.upload] : [], meta, fieldProps.name, {max: 1});
    return () => fileUploaderStore.resetUploader();
  }, [fieldProps.name, fileUploaderStore, meta, store.recommendation.upload]);

  const fileInputRef = useRef<HTMLInputElement>(null);
  const handleUploadButton:MouseEventHandler = (e) => {
    e.preventDefault();
    if (fileUploaderStore.canUpload) fileInputRef.current?.click();
  };
  const handleUploadChange:ChangeEventHandler<HTMLInputElement> = (e) => {
    if (!fileUploaderStore.canUpload) return;

    // do nothing if there is no files
    if (!e.target.files || !e.target.files.length) return;

    // proceed
    runInAction(() => store.status = "uploading");
    Array.from(e.target.files).forEach((file:File) => {
      fileUploaderStore.postFile(file).then((fileStore) => {
        runInAction(() => {
          store.recommendation.upload = fileStore.id;
        });
        return store.postRecommendation().then(freshRecommendationId => {
          if (freshRecommendationId) {
            fieldProps.formikProps.setFieldValue(fieldProps.name, freshRecommendationId);
          }
        });
      }).catch(() => {
        // не очень оригинально, тут допущение что файл будет только один
        fileUploaderStore.files.clear();
      }).finally(() => runInAction(() => {
        store.status = "ok";
      }));
    });
  };

  if (fileUploaderStore.files.length && fileStore) {
    return <>
      <FileBlock
        fileStore={fileStore}
        onDelete={() => fileUploaderStore.delete(fileStore)}
        canEdit={false}
        download={false}
      />
    </>;
  } else {
    return <>
      <p>
        <small>
          If your recommender chooses not to use the online system and instead gives you a recommendation letter personally, you may upload it as a PDF file yourself.
        </small>
      </p>
      <p>
        <small>
          In that case, you must make sure the letter is signed and includes the recommender’s complete contact information, including their full name, email and phone number.
        </small>
      </p>
      {store.status === "ok" ? (
        <div className={"d-flex"}>
          <Button onClick={handleUploadButton} className={"flex-grow-1"}>Upload recommendation</Button>
          <Button variant={"outline-primary"} className={"flex-grow-1 ml-3"} onClick={() => props.close()}>Close</Button>
        </div>
      ) : (
        fileStore && fileStore?.state === "uploading" ? (
          <div className={"mt-4"}>
            <ProgressBar animated now={fileStore.progress} label={"Uploading"}/>
          </div>
        ) : null
      )}
      <input multiple type={"file"} ref={fileInputRef} className={"d-none"} onChange={handleUploadChange}/>
    </>;
  }
});

const BlindRecommendationInstructionsGate:React.FC<{close: () => void}> = observer((props) => {
  const {store} = useContext(RecommendationBlockContext);

  return <>
    {store.isInstructionsVisible ? (
      <>
        <p>
          <small>You can request your recommender to post a recommendation letter for you. Prior to submitting your recommenders’ contact information, please make sure to:</small>
        </p>
        <ul>
          <li><small>Get in touch with them personally and ask for their permission.</small></li>
          <li><small>Ask them to add skoltech@skoltech.ru to their list of "safe" contacts or to check their inbox and spam folders. It is very common for automatic emails to end up in the spam folder, so make sure your recommenders are waiting for this email.</small></li>
          <li><small>Give them a deadline for submitting their recommendation. Late recommendations will not be accepted.</small></li>
          <li><small>Thank them after they do it.</small></li>
        </ul>
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <p><small>Be sure to check <a href="#" onClick={(e) => {
          e.preventDefault();
          store.recommendation.switchToFile();
        }}>the Upload</a> option, too.</small></p>
        <div className={"d-flex"}>
          <Button onClick={() => store.hideInstructions()} className={"flex-grow-1"}>
            Request recommendation
          </Button>
          <Button onClick={() => props.close()} className={"flex-grow-1 ml-3"} variant={"outline-primary"}>
            Close
          </Button>
        </div>
      </>
    ) : (
      props.children
    )}
  </>;
});

const SkFieldRecommendation:React.FC<SkFieldProps> = observer((props) => {
  const recommendationId = props.formikProps.values[props.name];

  return <SkFormMetaChecker name={"recommendation field"}>
    {(meta) => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      const store = useMemo(() => new RecommendationBlockStore(meta, props.name || "", recommendationId), [meta, recommendationId]);
      const {isInvalid, setReallyTouched} = useSkFieldValidInvalid(props);
      const [modalShow, setModalShow] = useState(false);

      return <Observer>{() => <RecommendationBlockContext.Provider value={{
        store: store,
        fieldProps: props,
        meta: meta,
        modalShow: modalShow,
        setModalShow: setModalShow,
        isInvalid: isInvalid,
        setReallyTouched: setReallyTouched,
        disabled: props.disabled || false,
      }}>
        <Form.Group controlId={props.name}>
          <SkFieldLabel {...props}/>
          <div>
            {store.recommendation.id ? <RecommendationCard details/> : <BlindRecommendationButton/>}
          </div>
          <SkFieldDescriptionBlock
            description={props.description}
            isInvalid={isInvalid}
            errorText={`${props.formikProps.errors[props.name]}`}
          />
        </Form.Group>
        <Modal show={modalShow && !props.disabled} onHide={() => {
          setModalShow(false);
          setReallyTouched(true);
        }} size={"xl"}>
          <Modal.Header closeButton style={{border: "none", position: "absolute", top: 0, right: 0, zIndex: 1000}}/>
          <Loading loading={store.status === "fetching"} style={{height: 200}}>
            {store.recommendation.id ? (
              store.recommendation.mode === RecommendationMode.Blind ? (
                <BlindRecommendationDetails>
                  <Button variant={"outline-primary"} onClick={() => setModalShow(false)} className={"mt-4"}>
                    Close window
                  </Button>
                </BlindRecommendationDetails>
              ) : (
                <UploadRecommendationDetails>
                  <Button variant={"outline-primary"} onClick={() => setModalShow(false)} className={"mt-4"}>
                    Close window
                  </Button>
                </UploadRecommendationDetails>
              )
            ) : (
              <Modal.Body>
                <ModalRecommendationErrorMessage/>
                <Tabs activeKey={store.recommendation.mode} onSelect={(k) => {
                  if (k === RecommendationMode.File) {
                    store.recommendation.switchToFile();
                  } else {
                    store.recommendation.switchToBlind();
                  }
                }}>
                  <Tab eventKey="blind" title="Blind request">
                    <BlindRecommendationInstructionsGate close={() => setModalShow(false)}>
                      <BlindRecommendationForm close={() => setModalShow(false)}/>
                    </BlindRecommendationInstructionsGate>
                  </Tab>
                  <Tab eventKey="file" title="Upload">
                    <BlindRecommendationUpload close={() => setModalShow(false)}/>
                  </Tab>
                </Tabs>
              </Modal.Body>
            )}
          </Loading>
        </Modal>
      </RecommendationBlockContext.Provider>}</Observer>;
    }}
  </SkFormMetaChecker>;
});

export default SkFieldRecommendation;
