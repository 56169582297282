import {action, computed, makeObservable, observable, runInAction} from "mobx";
import ApiBackCampaignsActionsGet, {ApiBackCampaignsActionsGetResponse} from "../../../../services/api/back/campaigns/actions/ApiBackCampaignsActionsGet";
import StoreWithFormsFromData, {
  ApiApplicationFormStore,
  makeObservableForm
} from "../../../../helpers/factory/StoreWithFormsFromData";
import ApiApplicationAction from "../../../../services/api/back/campaigns/actions/ApiApplicationAction";
import ApiBackCampaignsActionsPost, {
  ApiBackCampaignsActionsPostError, ApiBackCampaignsActionsPostResponse,
  isApiBackCampaignsActionsPostError
} from "../../../../services/api/back/campaigns/actions/ApiBackCampaignsActionsPost";
import ApiAnswerPostModel from "../../../../services/api/applications/ApiAnswerModel";
import AxiosApiInstance, {ApiError, isApiError} from "../../../../services/api/AxiosApiInstance";
import BackApplicationsPageStore from "../../pages/BackApplicationsPageStore";
import ApiApplicationRenderedModel from "../../../../services/api/applications/ApiApplicationRenderedModel";
import {ApplicationPageModel} from "../../../pages/ApplicationPageStore";
import ApiApplicationFormModel from "../../../../services/api/forms/ApiApplicationFormModel";
import {AxiosResponse} from "axios";
import { saveAs } from "file-saver";
import toastsStore from "../../../singletones/ToastsStore";

interface ApiBackCampaignsActionsGetResponseStore extends ApiBackCampaignsActionsGetResponse {
  forms: ApiApplicationFormStore[];
  rendered_application?: ApplicationPageModel;
}

class ApplicationActionConfirmationModalStore {
  readonly modal = "ApplicationActionConfirmationModal";

  constructor(private root: BackApplicationsPageStore, public applicationAction: ApiApplicationAction, init = false, private applications: string = "") {
    makeObservable(this, {
      state: observable,
      error: observable,
      clearErrors: action,
      init: action,
      actionStore: observable,
      actionResponse: observable,
      setActionResponse: action,
      firstApplicationId: computed,
      renderedFormsMode: computed,
      decorateRenderedApplication: action,
    });

    if (init) {
      this.init();
    }
  }

  get firstApplicationId() {
    return this.applications.split(",")[0];
  }

  get firstApplication() {
    return this.root.applications.find(a => a.id === this.firstApplicationId);
  }

  state: "init" | "ok" | "error" | "posting" | "posted" = "init";
  error: unknown;
  clearErrors() {
    this.error = undefined;
  }

  actionResponse?: ApiBackCampaignsActionsPostResponse;
  actionStore?:ApiBackCampaignsActionsGetResponseStore;
  renderedUniForm?: ApiApplicationFormStore;
  questionIdsByFormId?: [string, string[]][];

  setActionResponse(success: ApiBackCampaignsActionsPostResponse) {
    this.actionResponse = success;
  }

  get renderedFormsMode() {
    return !!this.actionStore?.rendered_application;
  }

  decorateRenderedApplication() {
    if (this.actionStore?.rendered_application?.forms?.length) {
      const uniForm: ApiApplicationFormModel = {
        id: "uniform",
        name: "Application Form",
        sort: 0,
        questions: [],
        questionsBlocks: [],
      };

      const questionsMap: [string, string[]][] = [];

      this.actionStore?.rendered_application?.forms.forEach(f => {
        const questionsIds = f.questions.map(q => q.id);

        uniForm.questions = uniForm.questions.concat(f.questions);
        uniForm.questionsBlocks?.push({
          header: f.name,
          questionsIds: questionsIds,
        });
        questionsMap.push([f.id, questionsIds]);
      });
      this.renderedUniForm = makeObservableForm(uniForm, this.actionStore?.rendered_application);
      this.questionIdsByFormId = observable.array(questionsMap);
    }
  }

  async fetchConfirmation():Promise<AxiosResponse<ApiBackCampaignsActionsGetResponse>> {
    const axiosResponse = (await ApiBackCampaignsActionsGet(this.applicationAction.url, this.applications));
    const response:ApiBackCampaignsActionsGetResponse = axiosResponse.data;
    let store:ApiBackCampaignsActionsGetResponseStore;

    // decorations
    if (response.rendered_application) {
      response.rendered_application = StoreWithFormsFromData<ApiApplicationRenderedModel, ApplicationPageModel>(response.rendered_application);
      store = observable(response as ApiBackCampaignsActionsGetResponseStore);
    } else {
      store = StoreWithFormsFromData<ApiBackCampaignsActionsGetResponse, ApiBackCampaignsActionsGetResponseStore>(response);
    }

    runInAction(() => {
      this.actionStore = store;

      // IMPORTANT! 🔥🔥🔥 making unified form!
      this.decorateRenderedApplication();
    });

    return axiosResponse;
  }

  private async postAction(answers: ApiAnswerPostModel[] = []):Promise<AxiosResponse<ApiBackCampaignsActionsPostResponse|void>> {
    return await ApiBackCampaignsActionsPost(this.applicationAction.url, {
      ids: this.applications.split(","),
      answers: answers
    });
  }

  async processPostAction(answers: ApiAnswerPostModel[] = []):Promise<AxiosResponse<ApiBackCampaignsActionsPostResponse|void>|void> {
    this.state = "posting";

    let response;

    try {
      response = await this.postAction(answers);

      if (response.data && response.data.changed && response.data.changed?.length) {
        this.root.applyApplicationChanges(response.data.changed);
      }

      this.state = "posted";

      return response;
    } catch (e: ApiError|unknown) {
      //процессим системную ошибку
      try {
        if (isApiError(e)) {
          const apiError:ApiError = (e as ApiError);
          if (isApiBackCampaignsActionsPostError(apiError.response?.data)) {
            const apiBackCampaignsActionsPostError = (e as ApiError)?.response?.data as ApiBackCampaignsActionsPostError;

            runInAction(() => {
              this.error = apiBackCampaignsActionsPostError.message;
              this.state = "error";
            });
          }
        }
      } catch {
        runInAction(() => {
          this.error = "An unknown error occurred";
          this.state = "error";
        });
      }
      return;
    }
  }

  init() {
    try {
      this.fetchConfirmation().then((response) => {
        runInAction(() => this.state = "ok");

        if (response.status === 200 && response.data && response.data.type === "download") {
          AxiosApiInstance.post(this.applicationAction.url, {
            ids: this.applications.split(",")
          }, {
            responseType: "blob"
          }).then((response) => {
            const filename = response.headers && response.headers["content-disposition"] ? String(`${response.headers["content-disposition"]}`.split("\"")[1]) : "";
            saveAs(response.data, filename);
          }, reason => {
            console.error(reason);
            toastsStore.addToast({
              variant: "danger",
              body: "Error during file download, see browser console for details.",
              props: {
                delay: 5000,
                autohide: true,
              }
            });
          }).finally(() => this.root.hideModal());
        }
      }).catch(e => {
        runInAction(() => {
          this.error = e;
          this.state = "error";
        });
      });
    } catch (e) {
      runInAction(() => {
        this.error = e;
        this.state = "error";
      });
    }
  }
}

export default ApplicationActionConfirmationModalStore;
