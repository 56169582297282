import React from "react";
import {BadgeProps} from "react-bootstrap";
import {observer} from "mobx-react-lite";
import ReportStore from "../../../stores/backoffice/reports/ReportStore";

interface Props extends BadgeProps {
  report: ReportStore;
}

const ReportDates:React.FC<Props> = observer(({report}) => {
  const model = report?.model;
  if (!model) return <></>;

  const username = model.user.first_name ? `${model.user.first_name}${model.user.last_name ? " " + model.user.last_name : ""}` : model.user.username;

  return <>
    <div className={"d-flex"}>
      <div className={"d-flex flex-column mr-3 align-items-end"}>
        <b>Requested at:</b>
        <b>Finished at:</b>
      </div>
      <div className={"d-flex flex-column"}>
        <div>
          {model.created_at || "—"} by {username}
        </div>
        <div>
          {model.finished_at || "—"}
        </div>
      </div>
    </div>
  </>;
});

export default ReportDates;
