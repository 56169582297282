import {makeAutoObservable } from "mobx";
import { Log } from "../../helpers/log";
import * as jwt from "jsonwebtoken";
import {DateTime} from "luxon";
import Clock from "../../helpers/mobx/clock";
import JWTPayload, { JWTPayloadSchema } from "../../interfaces/jwt/JWTPayload";

const SECRET = process.env.REACT_APP_JWT_PUBLIC_KEY;
const logJWTVerify = new Log("TokenStore:JWTVerify");

export const JWTVerify = (token:string):JWTPayload => {
  logJWTVerify.log("Try to verify with secret", SECRET);
  const payload = jwt.verify(token, `${SECRET}`, {algorithms: ["HS256"]});
  // throws ValidationError
  JWTPayloadSchema.validateSync(payload);
  return payload as JWTPayload;
};

class TokenStore {
  constructor() {
    const token = window.localStorage.getItem("sk-apply-front-auth-token");

    if (token) {
      this.log.log (`Have auth token "${token}"`);
      this.token = token;
    } else {
      this.log.log ("DON'T have saved auth token");
    }

    makeAutoObservable(this);
  }

  log = new Log("TokenStore");

  token: string|null = null;

  clock = new Clock();

  get payload():null|JWTPayload {
    try {
      if (this.token) {
        // throws ValidationError
        return JWTVerify(this.token);
      } else {
        return null;
      }
    } catch (e) {
      this.log.log(`Error decoding saved JWT token! Error: ${e}`);
      return null;
    }
  }

  get isNeedRefresh() {
    try {
      if (this.payload) {
        const expiresAt = DateTime.fromSeconds(this.payload.exp);
        const secondsBeforeExpire = expiresAt.diff(DateTime.fromJSDate(this.clock.getTime())).as("seconds");
        return secondsBeforeExpire < 10800;
      } else {
        return false;
      }
    } catch (e) {
      return false;
    }
  }

  setToken(token: string) {
    this.token = token;
    window.localStorage.setItem("sk-apply-front-auth-token", token);
  }

  clearToken() {
    this.token = null;
    window.localStorage.removeItem("sk-apply-front-auth-token");
  }
}

const tokenStore = new TokenStore();

export default tokenStore;
