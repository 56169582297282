import React, {useEffect, useState} from "react";
import {Button, Spinner, Form} from "react-bootstrap";
import SkSubmitButtonProps from "./SkSubmitButtonProps";

const SkSubmitButton:React.FC<SkSubmitButtonProps> = (props) => {
  const [accepts, setAccepts] = useState<boolean[]>([]);
  useEffect(() => setAccepts(props.accepts && props.accepts.length ? Array(props.accepts.length).fill(false) : []), [props.accepts]);

  const accepted = accepts.reduce<boolean>((a, v) => v && a, true);

  return <>

    {props.accepts && props.accepts.map((v,i) =><Form.Group key={i}>

      <div className="form-check">
        <input
          id={`accepts_${i}`}
          className="form-check-input"
          type="checkbox"
          checked={accepts[i] || false}
          onChange={() => {
            const newAccepts = Array.from(accepts);
            newAccepts[i] = !newAccepts[i];
            setAccepts(newAccepts);
          }}/>
        <label className="form-check-label" htmlFor={`accepts_${i}`}>
          {v}
        </label>
      </div>
    </Form.Group>)}

    <Button variant="primary" type="submit" disabled={props.disabled || props.formikProps.isSubmitting || !accepted} block size="lg">
      {props.formikProps.isSubmitting
        ? <><Spinner
          as="span"
          animation="border"
          role="status"
          aria-hidden="true"
        /></>
        : props.caption
      }
    </Button>
  </>;
};

export default SkSubmitButton;