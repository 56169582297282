import React from "react";
import {Form, OverlayTrigger, Tooltip} from "react-bootstrap";
import SkFieldProps from "./SkFieldProps";
import {Asterisk} from "react-bootstrap-icons";

const SkFieldLabel:React.FC<SkFieldProps> = (props) => {
  const name = props.label || props.name;
  const mandatory = !!(props.min && props.min > 0);

  const cname = props.disabled ? "text-muted" : "";

  return <Form.Label className={cname}>{name} {mandatory && <OverlayTrigger
    overlay={
      <Tooltip id={`tooltip_mandatory_${props.name}`}>
        This field is mandatory
      </Tooltip>
    }
  >
    <Asterisk className={"align-middle text-danger"}/>
  </OverlayTrigger>}
  </Form.Label>;
};

export default SkFieldLabel;