import React from "react";
import ReactDOM from "react-dom";

import "./style/custom.scss";

import SkApplyFront from "./SkApplyFront";

import reportWebVitals from "./reportWebVitals";

import "./googleTranslateSafe";

ReactDOM.render(
  <SkApplyFront />,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
