import React, {useContext, useState} from "react";
import {observer} from "mobx-react-lite";
import NotificationsConfigsStore from "../../../stores/backoffice/configs/NotificationsConfigsStore";
import {Button, ButtonGroup, Card, Dropdown, Form, Modal, Spinner} from "react-bootstrap";
import NotificationsConfigItemStore from "../../../stores/backoffice/configs/NotificationsConfigItemStore";
import {Formik} from "formik";
import {ApiNotificationsConfigSchema} from "../../../services/api/back/campaigns/configs/notifications/ApiNotificationsConfig";
import CampaignNotificationStyle
  from "../../../services/api/back/campaigns/configs/notifications/CampaignNotificationStyle";
import {BackAppStoreContext} from "../../../pages/backoffice/BackApp";
import {Eye, EyeSlash, InfoCircle, Plus, Trash} from "react-bootstrap-icons";
import {runInAction} from "mobx";
import FormControlClosure from "../../FormControlClosure";
import {useToastsStore} from "../../../stores/singletones/ToastsStore";

const CampaignNotificationStyleNames = {
  success: "Success",
  warning: "Warning",
  error: "Error"
};

export const CampaignNotificationStyleVariants = {
  success: "success",
  warning: "warning",
  error: "danger"
};

const NotificationSettings:React.FC<{
  item: NotificationsConfigItemStore;
}> = observer(({item}) => {
  const toastsStore = useToastsStore();
  const backAppStore = useContext(BackAppStoreContext);
  const [showDelete, setShowDelete] = useState(false);

  return <>
    <div className={"d-flex align-items-baseline mt-3"}>
      <ButtonGroup className={"mr-4"}>
        <Button
          variant={item.data.hidden ? "light" : "light"}
          active={item.data.hidden}
          onClick={() => {
            runInAction(() => item.data.hidden = true);
          }}
        >
          <EyeSlash/> Hidden
        </Button>
        <Button
          variant={item.data.hidden ? "light" : "primary"}
          active={!item.data.hidden}
          onClick={() => {
            runInAction(() => item.data.hidden = false);
          }}
        >
          <Eye/> Visible
        </Button>
      </ButtonGroup>

      <div className={"mr-2"}>Notification color</div>
      <Dropdown className={"mr-4"}>
        <Dropdown.Toggle variant={CampaignNotificationStyleVariants[item.data.style]} id="dropdown-basic">
          {CampaignNotificationStyleNames[item.data.style]}
        </Dropdown.Toggle>
        <Dropdown.Menu>
          {Object.entries(CampaignNotificationStyleNames).map(([value,name]) =>
            <Dropdown.Item key={value} href="#" onClick={e => {
              e.preventDefault();
              // TODO хуевая типизация, мне этот enum уже не нравится вообще
              runInAction(() => item.data.style = value as CampaignNotificationStyle);
            }}>{name}</Dropdown.Item>
          )}
        </Dropdown.Menu>
      </Dropdown>

      <div className={"mr-2"}>Show to</div>
      <Dropdown className={"mr-4"}>
        <Dropdown.Toggle variant={"outline-dark"} id="dropdown-basic">
          {backAppStore.campaign?.education_types?.find(et => et.id === item.data.education_type)?.name}
        </Dropdown.Toggle>
        <Dropdown.Menu>
          {backAppStore.campaign?.education_types?.map((et) =>
            <Dropdown.Item key={et.id} href="#" onClick={e => {
              e.preventDefault();
              // TODO установка education_type
              runInAction(() => item.data.education_type = et.id);
            }}>{et.name}</Dropdown.Item>
          )}
        </Dropdown.Menu>
      </Dropdown>

      <div className={"flex-grow-1"}/>

      <Button variant={"outline-danger"} onClick={() => setShowDelete(true)}>
        <Trash/> Delete
      </Button>
    </div>
    <Modal
      show={showDelete}
      onHide={() => setShowDelete(false)}
      centered
    >
      <Modal.Body>
        Do you really want to delete notification?
      </Modal.Body>
      <Modal.Footer>
        <Button variant={"danger"} onClick={() => {
          setShowDelete(false);
          runInAction(() => item.state = "pending");
          item.delete().catch(e => {
            console.error(e);
            runInAction(() => item.state = "ok");
            toastsStore.addToast({
              props: {
                autohide: true,
                delay: 4300,
              },
              body: "An error occured during Notification delete",
              variant: "danger",
            });
          });
        }}>
          <Trash/> Delete
        </Button>
        <Button onClick={() => setShowDelete(false)}>Cancel</Button>
      </Modal.Footer>
    </Modal>
  </>;
});

const NotificationsConfigs:React.FC<{
  store: NotificationsConfigsStore
}> = observer(({store}) => {
  const backAppStore = useContext(BackAppStoreContext);

  const formikDefaults = {
    text: "",
    style: CampaignNotificationStyle.Warning,
    education_type: backAppStore.educationTypeId ? backAppStore.educationTypeId : "",
  };

  return <div className={"mt-5 mb-5"}>
    {store.notificationsConfigs.filter(i => i.state !== "deleted").map((item: NotificationsConfigItemStore, i) => {
      const className = `mb-4 card-loading-animation ${item.state === "pending" ? "card-loading" : ""}`;

      return <React.Fragment key={i}>
        <Card className={className}>
          <Card.Body>
            <FormControlClosure
              as={"textarea"}
              cols={4}
              name={"text"}
              value={item.data.text}
              onChange={({value, setValue}) => {
                // TODO такая себе валидация
                if (!value) {
                  setValue(item.data.text);
                } else {
                  runInAction(() => item.data.text = value);
                }
              }}
            />
            <NotificationSettings item={item} key={"edit"}/>
          </Card.Body>
        </Card>
      </React.Fragment>;
    })}
    <div className={"mb-4"}>
      <Formik
        initialValues={formikDefaults}
        validationSchema={ApiNotificationsConfigSchema}
        onSubmit={async (values, helpers) => {
          const campaign = backAppStore.campaign;

          if (!campaign) {
            console.error("no campaign");
            return;
          }

          const campaignId = campaign?.id;
          //TODO выбор!
          // const educationalProgramId = campaign?.education_programs?.length ? campaign?.education_programs[0].id : undefined;

          if (campaignId && backAppStore.educationTypeId) {
            store.notificationsConfigs.push(new NotificationsConfigItemStore(store, {
              text: values["text"],
              style: values["style"],
              campaign: campaignId,
              education_type: values["education_type"],
              hidden: true,
            }));
          } else {
            // TODO error
            console.error("Cant add notification!");
          }

          helpers.resetForm();
        }}
      >
        {(formik) => <Form onSubmit={formik.handleSubmit}>
          <h3 className={"mb-3 mt-5"}>Add new notification</h3>

          <Form.Group controlId={"newNotificationText"}>
            <Form.Control
              as={"textarea"}
              cols={3}
              name={"text"}
              value={formik.values["text"]}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              isInvalid={!!formik.errors["text"] && formik.submitCount > 0}
              disabled={formik.isSubmitting}
              placeholder={"Notification text..."}
            />
          </Form.Group>

          <div className={"d-flex align-items-baseline mt-3 mb-3"}>

            <div className={"mr-2"}>Notification color</div>
            <Dropdown className={"mr-3"}>
              <Dropdown.Toggle variant={CampaignNotificationStyleVariants[formik.values["style"]]} id="dropdown-basic">
                {CampaignNotificationStyleNames[formik.values["style"]]}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {Object.entries(CampaignNotificationStyleNames).map(([value,name]) =>
                  <Dropdown.Item key={value} href="#" onClick={e => {
                    e.preventDefault();
                    formik.setFieldValue("style", value);
                  }}>{name}</Dropdown.Item>
                )}
              </Dropdown.Menu>
            </Dropdown>

            <div className={"mr-2"}>Show to</div>
            <Dropdown className={"mr-3"}>
              <Dropdown.Toggle variant={"outline-dark"} id="dropdown-basic">
                {backAppStore.campaign?.education_types?.find(et => et.id === formik.values["education_type"])?.name}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {backAppStore.campaign?.education_types?.map((et) =>
                  <Dropdown.Item key={et.id} href="#" onClick={e => {
                    e.preventDefault();
                    formik.setFieldValue("education_type", et.id);
                  }}>{et.name}</Dropdown.Item>
                )}
              </Dropdown.Menu>
            </Dropdown>
            <div className={"flex-grow-1"}/>
            <div className={"mr-3 text-muted"}><InfoCircle/> All notifications are created hidden</div>
            <Button
              variant="primary"
              type="submit" disabled={formik.isSubmitting}
            >
              Add notification
              {formik.isSubmitting ? <Spinner as="span" animation="border" role="status" aria-hidden="true"/> : <Plus/>}
            </Button>
          </div>
        </Form>}
      </Formik>
    </div>
  </div>;
});

export default NotificationsConfigs;