import React, {ErrorInfo, PropsWithChildren} from "react";
import {Log} from "../helpers/log";
import {Button, Col, Container, Row} from "react-bootstrap";

type Props = PropsWithChildren<{
    errorLevel?: string;
  }>;

class ErrorBoundary extends React.Component<Props, {hasError: boolean, error?: Error, errorInfo?: ErrorInfo}> {
  log: Log;
  errorLevel?: string;

  constructor(props: Props) {
    super(props);
    this.state = { hasError: false };
    this.errorLevel = `error-boundary${props.errorLevel ? `-${props.errorLevel}` : ""}`;
    this.log = new Log(this.errorLevel || "ErrorBoundary");
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    // You can also log the error to an error reporting service
    this.log.error(error, errorInfo);
    this.setState({
      hasError: true,
      error: error,
      errorInfo: errorInfo
    });
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <Container>
        <Col>
          <Row>
            <div className={"d-flex align-items-center justify-content-center"} style={{minHeight: "50vh", margin: "100px 0"}}>
              <div>
                <h3>
                  Something went wrong.
                </h3>
                <p>
                  Please, reload page. If problem persists, please, take a screenshot of browser's window with developer console open and contact our support.
                </p>
                <Button variant={"primary"} size={"lg"} onClick={() => document.location.reload()} className={"mb-5 mt-4"}>
                  Reload
                </Button>
                {this.errorLevel ? <p className={"text-muted"}>
                  Error level: {this.errorLevel}
                </p> : null}
                {this.state.error ? <p className={"text-muted"}>
                  Error message:<br/>{this.state.error?.message}
                </p> : null}
                {this.state.errorInfo ? <p className={"text-muted"}>
                  componentStack:<br/>{this.state.errorInfo?.componentStack}
                </p> : null}
              </div>
            </div>
          </Row>
        </Col>
      </Container>;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;