import React from "react";
import {Col, Container, Row} from "react-bootstrap";
import LoginBlock from "../components/LoginBlock";
import LogoSquareWhite from "../components/LogoSquareWhite";
import WithDefaults from "./withs/WithDefaults";
import SkLogoContainer from "../components/SkLogoContainer";
import BtnProps from "../helpers/components/factories/BtnProps";
import {Link} from "react-router-dom";
import ErrorBoundary from "../components/ErrorBoundary";

const LoginPage: React.FC = () => (
  <ErrorBoundary errorLevel={"LoginPage"}>
    <WithDefaults chatra={false}>
      <SkLogoContainer className={"mb-5 ml-auto mr-auto"}>
        <LogoSquareWhite/>
      </SkLogoContainer>
      <Container>
        <Row>
          <Col>
            <SkLogoContainer className={"mb-5 ml-auto mr-auto"}>
              <LoginBlock />
              <div className={"mt-4 mb-5"}>
                <Link to={"/restore"} {...BtnProps({variant: "outline-primary", block: true})}>Restore access</Link>
                <Link to={"/"} {...BtnProps({variant: "outline-primary", block: true})}>Back to application form</Link>
              </div>
            </SkLogoContainer>
          </Col>
        </Row>
      </Container>
    </WithDefaults>
  </ErrorBoundary>
);

export default LoginPage;