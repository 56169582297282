import {useCallback, useMemo} from "react";
import {BagPasser} from "./useBag";

export interface Bag {
  [key: string]: any;
}

export type BagPasserFactory<B> = (key: string) => BagPasser<B>;

function useBags<B = Bag>():[Map<string, B|undefined>, BagPasserFactory<B>] {
  const bagsMap: Map<string, B|undefined> = useMemo(() => new Map(), []);
  const passBagFactory: (key: string) => BagPasser<B> = useCallback((key) => (passedBag: B|undefined) => bagsMap.set(key, passedBag), [bagsMap]);
  return [bagsMap, passBagFactory];
}

export default useBags;
