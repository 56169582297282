import React from "react";
import {observer} from "mobx-react-lite";
import BackApplicationsPageStore, {
  useBackApplicationsPageStore
} from "../../stores/backoffice/pages/BackApplicationsPageStore";
import BackAppStore from "../../stores/backoffice/pages/BackAppStore";
import {People} from "react-bootstrap-icons";
import {Col, Container, Row} from "react-bootstrap";
import ApplicationActionConfirmationModal
  from "../../components/backoffice/modals/applications/ApplicationActionConfirmationModal";
import ApplicationsTable from "../../components/backoffice/cp/applications/ApplicationsTable";
import SelectedApplications from "../../components/backoffice/SelectedApplications";
import ErrorBoundary from "../../components/ErrorBoundary";
import PageName from "../../components/backoffice/PageName";
import PageInfo from "../../interfaces/back/PageInfo";
import Help from "../../components/backoffice/cp/applications/Help";

export const pageStoreContext = React.createContext<BackApplicationsPageStore|undefined>(undefined);

export const pageInfo:PageInfo = {
  name: "Applicants",
  icon: <People/>
};

const BackApplicationsPage:React.FC<{
  backAppStore: BackAppStore
}> = observer(({
  backAppStore
}) => {
  const pageStore = useBackApplicationsPageStore(backAppStore, true);

  return <ErrorBoundary errorLevel={"BackApplicationsPage"}>
    <pageStoreContext.Provider value={pageStore}>
      {/*modals*/}
      {pageStore.modal && pageStore.modal.modal === "ApplicationActionConfirmationModal" && <ApplicationActionConfirmationModal store={pageStore.modal} hideModal={pageStore.hideModal}/>}

      <Container>
        <Row>
          <Col>
            <div className={"d-flex align-items-baseline"}>
              <PageName
                icon={pageInfo.icon}
                name={pageInfo.name}
                pageState={pageStore.state}
                className={"mr-4 mb-0"}
              />
              {backAppStore.campaign?.applications_count ? <h4 className={"mr-2 mb-0 text-muted"}>
                {backAppStore.campaign?.applications_count} total
              </h4> : null}
              <SelectedApplications store={pageStore}/>
              <h4>
                <Help/>
              </h4>
            </div>
          </Col>
        </Row>
      </Container>

      <ApplicationsTable pageStore={pageStore}/>

    </pageStoreContext.Provider>
  </ErrorBoundary>;
});

export default BackApplicationsPage;
