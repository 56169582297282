import {AxiosResponse} from "axios";
import AxiosApiInstance from "../../../AxiosApiInstance";
import ApiBackReportModel from "./ApiBackReportModel";

export interface ApiBackCampaignsReportsPostRequest {
  report_type: string;
  education_type?: string;
}

export const ApiBackCampaignsReportsPost: (url: string, payload: ApiBackCampaignsReportsPostRequest) => Promise<AxiosResponse<ApiBackReportModel>> = (url, payload) => {
  return AxiosApiInstance.post(url, payload);
};