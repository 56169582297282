import axios, {AxiosResponse, CancelTokenSource} from "axios";
import DefaultGetResponse from "../../../DefaultGetResponse";
import AxiosApiInstance, {APIBase} from "../../../AxiosApiInstance";
import {FieldRecommendation} from "./ApiApplicationField";

export interface ApiBackCampaignsApplicationsGetParams {
  [filterKey: string]: string | number | undefined;
  limit?: number;
  offset?: number;
  education_type?: string;
  search?: string;
  sort?: string;
}

export interface ApiBackCampaignsApplicationModel {
  id: string;
  answers: (string | FieldRecommendation)[];
}

export let ApiBackCampaignsApplicationsGetSource: CancelTokenSource | undefined;

export const ApiBackCampaignsApplicationsGet: (campaign_pk: string, params?: ApiBackCampaignsApplicationsGetParams) => Promise<AxiosResponse<DefaultGetResponse<ApiBackCampaignsApplicationModel>>> = (campaign_pk, params) => {
  ApiBackCampaignsApplicationsGetSource = axios.CancelToken.source();

  return AxiosApiInstance.get(`${APIBase}back/campaigns/${campaign_pk}/applications/`, {
    params: params,
    cancelToken: ApiBackCampaignsApplicationsGetSource.token,
  });

};
