import React from "react";

const SkLogoContainer:React.FC<React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>> = ({
  children,
  className,
  ...props
}) => (
  <div className={`sklogo-container ${className}`} {...props}>
    {children}
  </div>
);

export default SkLogoContainer;