import React, {ChangeEventHandler, MouseEventHandler, useRef} from "react";
import {Alert, Button, Card, Modal, ProgressBar} from "react-bootstrap";
import useFileUploader, {FileStore, FileUploaderStore} from "../stores/singletones/FileUploaderStore";
import {observer} from "mobx-react-lite";
import {FileEarmarkFill as FileIcon, X} from "react-bootstrap-icons";

export const FileBlock:React.FC<{
  fileStore: FileStore;
  onDelete?: () => void;
  download?: boolean;
  canEdit?: boolean;
}> = observer(({
  fileStore,
  onDelete,
  download= false,
  canEdit = true
}) => {
  return <>
    <Card className={"mb-3"}>
      <Card.Body className={"p-3"}>
        {fileStore.state !== "error" &&  (
          <div className="d-flex align-items-baseline">
            <div>
              <FileIcon className={"mr-1"}/>
            </div>

            <div className={"flex-grow-1"} style={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis"
            }}>
              {download && fileStore.url ? <a href={fileStore.url} target={"_blank"} rel={"noreferrer"}>{fileStore.filename_origin}</a> : fileStore.filename_origin}
            </div>

            {canEdit ? (
              <div>
                <Button className={"ml-1"} size={"sm"} variant={"danger"} onClick={e => {
                  e.preventDefault();
                  onDelete && onDelete();
                }}>
                  <X/>
                </Button>
              </div>
            ) : null}
          </div>
        )}

        {fileStore.state === "error" &&  (
          <div className="d-flex align-items-baseline" style={{maxWidth: "100%"}}>
            <Alert variant={"danger"} className={"mb-0 mr-2"} style={{maxWidth: "90%"}}>
              {fileStore.error?.message}
            </Alert>

            {canEdit ? (
              <div>
                <Button className={"ml-1"} size={"sm"} variant={"danger"} onClick={e => {
                  e.preventDefault();
                  onDelete && onDelete();
                }}>
                  <X/>
                </Button>
              </div>
            ) : null}
          </div>
        )}

        {fileStore.state === "uploading" &&  <div className={"mt-4"}>
          <ProgressBar animated now={fileStore.progress} />
        </div>}

      </Card.Body>
    </Card>
  </>;
});

const FileUploaderBlock:React.FC = observer(() => {
  const store:FileUploaderStore = useFileUploader();
  const fileInputRef = useRef<HTMLInputElement>(null);
  const handleUploadButton:MouseEventHandler = (e) => {
    e.preventDefault();
    if (store.canUpload) fileInputRef.current?.click();
  };
  const handleUploadChange:ChangeEventHandler<HTMLInputElement> = (e) => {
    if (!store.canUpload) return;

    // do nothing if there is no files
    if (!e.target.files || !e.target.files.length) return;

    // proceed
    Array.from(e.target.files).forEach((file:File) => {
      store.postFile(file).catch(e => console.log(e));
    });
  };

  const handleDoneButton:MouseEventHandler = (e) => {
    e.preventDefault();
    store.closeFileUploader();
  };

  const canUploadMore = store.options?.max ? store.files.length < store.options?.max : true;

  return <>
    <Modal
      show={store.show}
      backdrop="static"
      keyboard={false}
      centered
      className={"sk-filesdialog"}
    >
      <Modal.Header>
        <Modal.Title>{store.canUpload ? "Add attachments" : "Attachments"}</Modal.Title>
      </Modal.Header>
      <Modal.Body>

        {store.files.map(fileStore =>
          <FileBlock
            key={fileStore.key}
            fileStore={fileStore}
            onDelete={() => store.delete(fileStore)}
            canEdit={store.canUpload}
            download={store.download}
          />
        )}

        {store.canUpload && canUploadMore ? (<>
          <Button variant={"dark"} block onClick={handleUploadButton}>{store.files.length > 0 ? "Add more files" : "Select file"}</Button>
          <input multiple type={"file"} ref={fileInputRef} className={"d-none"} onChange={handleUploadChange}/>
        </>) : null}

      </Modal.Body>

      <Modal.Footer>
        <Button variant="primary" onClick={handleDoneButton} disabled={store.uploadInProgress}>
          {store.uploadInProgress ? "Uploading..." : "Done and Close"}
        </Button>
      </Modal.Footer>
    </Modal>
  </>;
});

export default FileUploaderBlock;