/* eslint-disable react-hooks/rules-of-hooks */
import React, {useMemo, useState} from "react";
import {Alert, Button, Card, Col, Container, Form, Row} from "react-bootstrap";
import LogoSquareWhite from "../components/LogoSquareWhite";
import WithDefaults from "./withs/WithDefaults";
import SkLogoContainer from "../components/SkLogoContainer";
import BtnProps from "../helpers/components/factories/BtnProps";
import {Link} from "react-router-dom";
import ErrorBoundary from "../components/ErrorBoundary";
import { Formik } from "formik";
import SkFieldDescriptionBlock from "../components/SkForms/questionTypes/SkFieldDescriptionBlock";
import useSkFieldValidInvalid from "../components/SkForms/useSkFieldValidInvalid";
import ApiUsersResetPasswordPost from "../services/api/users/ApiUsersResetPasswordPost";
import * as yup from "yup";
import axios, {AxiosError} from "axios";
import {setFormikEmailValue} from "../helpers/utils";

const RestorePage: React.FC = () => {
  const storedEmail = useMemo(() => window.localStorage.getItem("lastLoginEmail") || "", []);
  const [success, setSuccess] = useState(false);

  return <ErrorBoundary errorLevel={"RestorePage"}>
    <WithDefaults chatra={false}>
      <SkLogoContainer className={"mb-5 ml-auto mr-auto"}>
        <LogoSquareWhite/>
      </SkLogoContainer>
      <Container>
        <Row>
          <Col>
            <SkLogoContainer className={"mb-5 ml-auto mr-auto"}>

              {success ? (
                <Card>
                  <Card.Body>
                    <Card.Title>Password successfully reset</Card.Title>
                    <Card.Text>
                      Check your email — there will be a letter from us, containing all the instructions you're needed to restore your access.
                    </Card.Text>
                  </Card.Body>
                </Card>
              ) : (
                <Formik
                  initialValues={{
                    email: storedEmail
                  }}
                  validationSchema={yup.object().shape({
                    email: yup.string().email("Enter valid email, please").required("Email is required")
                  })}
                  onSubmit={async (values, helpers) => {
                    helpers.setStatus("");
                    try {
                      const response = await ApiUsersResetPasswordPost(values["email"] || "");

                      if (response.status === 200 && response.data.status === "ok") {
                        // all fine!
                        helpers.resetForm();
                        setSuccess(true);
                      } else {
                        // noinspection ExceptionCaughtLocallyJS
                        throw new Error("Unknown error occurred during password reset");
                      }
                    } catch (e) {
                      let message = "Unknown error occurred during password reset";
                      if (axios.isAxiosError(e)) {
                        const ae = (e as AxiosError);
                        message = ae.message;
                      }
                      helpers.setStatus(message);
                      helpers.setSubmitting(false);
                    }
                  }}
                >
                  {((props) => {
                    const {
                      handleSubmit, values, isSubmitting, errors, status, setStatus, setFieldValue
                    } = props;
                    const {isInvalid, setReallyTouched} = useSkFieldValidInvalid({formikProps: props, name: "email"});

                    return <>
                      <h1>Restore access</h1>
                      <Form onSubmit={handleSubmit}>
                        <Form.Group controlId={"email"}>
                          <Form.Control
                            type="text"
                            name={"email"}
                            value={values["email"] || ""}
                            onChange={e => setFormikEmailValue(e, setFieldValue)}
                            isInvalid={isInvalid}
                            disabled={isSubmitting}
                            onBlur={() => setReallyTouched(true)}
                            placeholder={"Email"}
                          />
                          <SkFieldDescriptionBlock
                            description={"Enter your account's email address"}
                            isInvalid={isInvalid}
                            errorText={`${errors["email"]}`}
                          />
                        </Form.Group>
                        {status ? <Alert variant={"danger"} onClose={() => setStatus("")} dismissible>
                          {status}
                        </Alert> : null}
                        <Button disabled={isSubmitting} type={"submit"}>
                          Restore
                        </Button>
                      </Form>
                    </>;
                  })}
                </Formik>
              )}

              <div className={"mt-5 mb-5"}>
                <Link to={"/login"} {...BtnProps({variant: "outline-primary", block: true})}>Sign in</Link>
                <Link to={"/"} {...BtnProps({variant: "outline-primary", block: true})}>Back to application form</Link>
              </div>
            </SkLogoContainer>
          </Col>
        </Row>
      </Container>
    </WithDefaults>
  </ErrorBoundary>;
};

export default RestorePage;
