import {makeAutoObservable, runInAction, toJS} from "mobx";
import {ApiRecommendationsGet} from "../services/api/recommendations/ApiRecommendationsGet";
import axios, {AxiosError} from "axios";
import {
  ApiRecommendationModel,
  ApiRecommenderModel,
  RecommendationMode,
  RecommendationRequestState,
  RecommendationResponseState
} from "../services/api/recommendations/ApiRecommendationModel";
import {ApiRecommendationsPost} from "../services/api/recommendations/ApiRecommendationsPost";
import {SkFormMeta} from "../components/SkForms/SkFormContexts";
import {ApiRecommendationsDelete} from "../services/api/recommendations/ApiRecommendationsDelete";

class RecommendationStore implements ApiRecommendationModel {
  constructor(data?: Partial<ApiRecommendationModel>) {
    if (data) Object.assign(this, data);
    makeAutoObservable(this);
  }

  id = "";
  application = "";
  question = "";

  mode: RecommendationMode = RecommendationMode.Blind;
  switchToFile() {
    if (!this.id) {
      this.mode = RecommendationMode.File;
    }
  }
  switchToBlind() {
    if (!this.id) {
      this.mode = RecommendationMode.Blind;
    }
  }

  recommender: ApiRecommenderModel | undefined = {
    position: "",
    first_name: "",
    last_name: "",
    email: "",
  };
  upload: string | undefined = "";
  request_state: RecommendationRequestState = RecommendationRequestState.NotSent;
  response_state: RecommendationResponseState = RecommendationResponseState.NotReceived;
  request_sent_at = "";
  response_sent_at = "";

  get received():boolean {
    return this.response_state === RecommendationResponseState.Received;
  }

  get pending():boolean {
    return this.mode === RecommendationMode.Blind && !this.received;
  }

  get postModel():Omit<ApiRecommendationModel, "id"> {
    return {
      application: toJS(this.application),
      question: toJS(this.question),
      mode: toJS(this.mode),
      recommender: this.mode === RecommendationMode.Blind ? toJS(this.recommender) : undefined,
      upload: this.mode === RecommendationMode.File ? toJS(this.upload) : undefined,
      request_state: toJS(this.request_state),
      response_state: toJS(this.response_state),
      request_sent_at: toJS(this.request_sent_at),
      response_sent_at: toJS(this.response_sent_at),
    };
  }
}

class RecommendationBlockStore {
  constructor(private meta: SkFormMeta, public questionId: string, public id?:string) {
    makeAutoObservable(this);

    this.recommendation = new RecommendationStore({
      application: this.meta.application,
      question: this.questionId,
    });

    if (this.id) this.init();
  }

  resetRecommendation() {
    this.recommendation = new RecommendationStore({
      application: this.meta.application,
      question: this.questionId,
    });
  }

  status: "ok" | "fetching" | "updating" | "deleting" | "uploading" = "ok";

  errorMessage = "";
  clearErrorMessage() {
    this.errorMessage = "";
  }

  init() {
    this.status = "fetching";
    this.fetchRecommendation().then(() => runInAction(() => this.status = "ok"));
  }

  async deleteRecommendation() {
    if (!this.id) return;
    this.status = "deleting";

    try {
      const response = await ApiRecommendationsDelete(this.id);
      if (response.status === 204) {
        // удалилось
        this.resetRecommendation();
        this.status = "ok";
        return;
      }
    } catch (e) {
      if (axios.isAxiosError(e)) {
        const ae = (e as AxiosError);

        if (ae.response?.status === 404) {
          // такая рекомендация не найдена, удоляемся
          runInAction(() => this.resetRecommendation());
          return;
        }

        console.error(ae);
        return;
      } else {
        runInAction(() => this.errorMessage = "Error during deleting recommendation request. Please contact our support. Sorry for the inconveniences.");
        console.error(e);
        return;
      }
    }

    runInAction(() => this.errorMessage = "Error during deleting recommendation request. Please contact our support. Sorry for the inconveniences.");
  }

  async fetchRecommendation() {
    if (!this.id) return;

    try {
      const response = await ApiRecommendationsGet(this.id);
      runInAction(() => this.recommendation = new RecommendationStore(response.data));
    } catch (e) {
      if (axios.isAxiosError(e)) {
        const ae = (e as AxiosError);

        if (ae.response?.status === 404) {
          // такая рекомендация не найдена
          this.resetRecommendation();
          return;
        }

        console.error(ae);
      } else {
        runInAction(() => this.errorMessage = "Unknown error occurred, please contact our support. Sorry for the inconveniences.");
        console.error(e);
      }
    }
  }

  async postRecommendation():Promise<string|undefined> {
    try {
      const response = await ApiRecommendationsPost(this.recommendation.postModel);
      if (response.status === 201) {
        runInAction(() => this.recommendation = new RecommendationStore(response.data));
        return response.data.id;
      } else {
        throw response;
      }
    } catch (e) {
      if (axios.isAxiosError(e)) {
        const ae = (e as AxiosError);
        runInAction(() => {
          let errorMessage = "Error, check the form and try again"
          if (ae.response && ae.response.data && ae.response.data[0]) {
            errorMessage = ae.response.data[0]
          }
          this.errorMessage = errorMessage
        });
        console.error(ae.response);
      } else {
        runInAction(() => this.errorMessage = "Unknown error occurred during posting recommendation request, please contact our support. Sorry for the inconveniences.");
        console.error(e);
      }
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  recommendation: RecommendationStore;

  isInstructionsVisible = true;
  showInstructions() {
    this.isInstructionsVisible = true;
  }
  hideInstructions() {
    this.isInstructionsVisible = false;
  }
}

export default RecommendationBlockStore;