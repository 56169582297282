import React, {useCallback, useEffect, useState} from "react";
import {Form} from "react-bootstrap";
import NumberFormat from "react-number-format";
import useSkFieldValidInvalid from "../useSkFieldValidInvalid";
import SkFieldProps from "./SkFieldProps";
import isSkFieldSubmitted from "./IsSkFieldSubmitted";
import SkFieldDescriptionBlock from "./SkFieldDescriptionBlock";
import SkFieldLabel from "./SkFieldLabel";
import {numberFormatProps} from "../../../const/numberFormatProps";

const SkFieldOutOf:React.FC<SkFieldProps> = (props) => {
  const {isInvalid, setReallyTouched} = useSkFieldValidInvalid(props);

  const value = `${props.formikProps.values[props.name]}`;
  const valueArray = value.split("/");
  const valueOut = parseFloat(`${valueArray[0]}`.replace(",","."));
  const valueOf = parseFloat(`${valueArray[1]}`.replace(",","."));
  const valueIsSafe = !isNaN(valueOut) && !isNaN(valueOf);

  const [outOf, setOutOf] = useState<[number,number]>(valueIsSafe ? [valueOut,valueOf] : [0,0]);

  const innerValue = `${outOf[0]}/${outOf[1]}`.replace(".",",");

  const changeOut = useCallback((newOutOf: [number, number]) => {
    if (newOutOf[1] < newOutOf[0]) {
      newOutOf[1] = newOutOf[0];
    }
    setOutOf(newOutOf);
  }, []);

  // Fix misformat once
  useEffect(() => {
    if (innerValue !== value) setTimeout(() => props.formikProps.setFieldValue(props.name, innerValue), 0);
  }, []);

  useEffect(() => {
    if (innerValue !== value) {
      if (!isNaN(valueOut) && !isNaN(valueOf)) {
        setOutOf([valueOut, valueOf]);
      }
    }
  }, [value]);

  // Got new inner value and need to pass it up
  useEffect(() => {
    if (innerValue !== value) props.formikProps.setFieldValue(props.name, innerValue);
  }, [innerValue]);

  return (
    <Form.Group controlId={props.name}>
      <SkFieldLabel {...props}/>
      <div className={"d-flex align-items-baseline"}>
        <div className={"flex-grow-1"}>
          <NumberFormat
            customInput={Form.Control}
            as={"input"}
            type="tel"
            name={`${props.name}_out`}
            value={outOf[0]}
            onValueChange={v => {
              if (v.floatValue) {
                changeOut([v.floatValue || 0, outOf[1]]);
              }
            }}
            isValid={isSkFieldSubmitted(props)}
            isInvalid={isInvalid}
            disabled={props.disabled || props.formikProps.isSubmitting}
            onBlur={() => {
              setReallyTouched(true);
              // триггерим перерисовку значения в жтом компоненте, если нажали бекспейс
              // да, жрёт ресурсы, можно по-другому как-то
              changeOut([outOf[0], outOf[1]]);
            }}
            {...numberFormatProps}
          />
        </div>
        <div className={"ml-2 mr-2"}>
          <span>out of</span>
        </div>
        <div className={"flex-grow-1"}>
          <NumberFormat
            customInput={Form.Control}
            as={"input"}
            type="tel"
            name={`${props.name}_of`}
            value={outOf[1]}
            onValueChange={v => {
              if (v.floatValue) {
                setOutOf([outOf[0], v.floatValue || 0]);
              }
            }}
            isValid={isSkFieldSubmitted(props)}
            isInvalid={isInvalid}
            disabled={props.disabled || props.formikProps.isSubmitting}
            onBlur={() => {
              setReallyTouched(true);
              // триггерим перерисовку значения в жтом компоненте, если нажали бекспейс
              // да, жрёт ресурсы, можно по-другому как-то
              setOutOf([outOf[0], outOf[1]]);
            }}
            {...numberFormatProps}
          />
        </div>
      </div>

      <SkFieldDescriptionBlock
        description={props.description}
        isInvalid={isInvalid}
        errorText={`${props.formikProps.errors[props.name]}`}
      />
    </Form.Group>
  );
};

export default SkFieldOutOf;