import {AxiosResponse} from "axios";
import AxiosApiInstance from "../../../AxiosApiInstance";
import ApiAnswerPostModel from "../../../applications/ApiAnswerModel";
import {ApiBackCampaignsApplicationModel} from "../applications/ApiBackCampaignsApplicationsGet";
import {ResponseWithErrors} from "../../../../../components/SkForms/processErrors";

export interface ApiBackCampaignsActionsPostRequest {
  ids: string[]; // айдишники applications
  answers?: ApiAnswerPostModel[];
}

export interface ApiBackCampaignsActionsPostError extends ResponseWithErrors {
  name: string;
  status: "error";
  message: string; // кажется это то, что надо отобразить в модале
  message_format: "plaintext" | string;
  changed?: ApiBackCampaignsApplicationModel[] | null;
}

export const isApiBackCampaignsActionsPostError = (some: unknown) => {
  return (some as ApiBackCampaignsActionsPostError)?.status === "error";
};

export interface ApiBackCampaignsActionsPostSuccess {
  name: string;
  status: "success" | "warning";
  message: string; // кажется это то, что надо отобразить в модале
  message_format: "plaintext" | "html" | string;
  changed?: ApiBackCampaignsApplicationModel[];
}

export type ApiBackCampaignsActionsPostResponse = ApiBackCampaignsActionsPostSuccess | ApiBackCampaignsActionsPostError;

export const ApiBackCampaignsActionsPost: (url: string, payload?: ApiBackCampaignsActionsPostRequest) => Promise<AxiosResponse<ApiBackCampaignsActionsPostResponse|void>> = (url, payload) => {
  return AxiosApiInstance.post(url, payload);
};
export default ApiBackCampaignsActionsPost;