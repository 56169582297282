import React from "react";
import {SkApplyFrontRouter} from "./components/routes/SkApplyFrontRouter";
import "date-input-polyfill-react";
import ErrorBoundary from "./components/ErrorBoundary";

const SkApplyFront:React.FC = () => (
  <ErrorBoundary errorLevel={"top"}>
    <SkApplyFrontRouter />
  </ErrorBoundary>
);

export default SkApplyFront;
