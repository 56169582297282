import {AxiosResponse} from "axios";
import AxiosApiInstance, {APIBase} from "../../../AxiosApiInstance";
import ApiBackReportModel from "../reports/ApiBackReportModel";
import {ApiBackCampaignsApplicationsGetParams} from "./ApiBackCampaignsApplicationsGet";

export const ApiBackCampaignsApplicationsExportPost: (campaign_pk: string, params: ApiBackCampaignsApplicationsGetParams) => Promise<AxiosResponse<ApiBackReportModel>> = (campaign_pk, params) => {
  return AxiosApiInstance.post(`${APIBase}back/campaigns/${campaign_pk}/applications/export/`, undefined, {
    params: params
  });
};
