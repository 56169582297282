import {extendObservable, observable} from "mobx";
import {SkFormValues} from "../../components/SkForms/SkForm";
import initialValuesFromAnswersOrValue from "./initialValuesFromAnswersOrValue";
import {ApplicationPageModel} from "../../stores/pages/ApplicationPageStore";
import ApiApplicationFormModel from "../../services/api/forms/ApiApplicationFormModel";
import {IObservableArray} from "mobx/dist/internal";
import {ApiQuestionRenderedModel} from "../../services/api/questions/ApiQuestionModel";

export interface ApiApplicationFormStore extends ApiApplicationFormModel {
  root: ApplicationPageModel;
  questionsTotal: number;
  changedQuestions: IObservableArray<string>;
  questionsChanged: number;
  questionsSubmitted: number;
  questionsMandatory: number;
  initialValues: SkFormValues;
  fulfilled: boolean;
  completed: boolean;
}

export interface DataWithForms {
  forms: ApiApplicationFormModel[] | null;
}

export interface StoreWithForms {
  forms: ApiApplicationFormStore[];
}

export const makeObservableForm = (f: ApiApplicationFormModel, model: StoreWithForms):ApiApplicationFormStore => {
  const form = f as ApiApplicationFormStore;
  extendObservable(form, {
    root: model,
    get questionsTotal () {
      return form.questions.length;
    },
    changedQuestions: observable.array<string>([]),
    questionsChanged: 0,
    get questionsSubmitted () {
      const qids = form.questions.map(q => q.id);
      return form.root.questions_accepted.filter(qaid => qids.indexOf(qaid) !== -1).length;
    },
    get questionsMandatory () {
      return form.questions.reduce<number>((a:number, q: ApiQuestionRenderedModel) => q.answers_count_min > 0 ? a + 1 : a, 0);
    },
    get initialValues () {
      return initialValuesFromAnswersOrValue(form.questions, form.root.answers || []);
    },
    get fulfilled () {
      return form.root.verified_forms.some(fid => fid === form.id);
    },
    get completed () {
      return form.questionsSubmitted === form.questionsTotal;
    },
  });

  return form;
};

function StoreWithFormsFromData<D extends DataWithForms, M extends StoreWithForms>(data: D):M {
  // to be extended manually BELOW!
  const model = observable(data as unknown as M);

  // TODO ПИЗДЕЦ не нравится что за типизацией приходится следить руками! что-то с этим надо сделать
  model.forms && model.forms.sort((a,b) => a.sort - b.sort).forEach(f => makeObservableForm(f, model));

  return model;
}

export default StoreWithFormsFromData;
