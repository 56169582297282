import React, {useMemo} from "react";
import {Alert, Card, Col, Container, Row} from "react-bootstrap";
import LogoSquareWhite from "../components/LogoSquareWhite";
import WithDefaults from "./withs/WithDefaults";
import Loading from "./Loading";
import ApplyPageStore from "../stores/pages/ApplyPageStore";
import {observer} from "mobx-react-lite";
import ErrorDisplay from "./ErrorDisplay";
import SkForm, {SkFormValues} from "../components/SkForms/SkForm";
import {
  ApiApplicationsPost,
  ApiApplicationsPostRequest,
} from "../services/api/applications/ApiApplicationsPost";
import {FormikHelpers} from "formik/dist/types";
import AnswersFromFormValues from "../helpers/factory/AnswersFromFormValues";
import {useAuth} from "../stores/AuthStore";
import SkLogoContainer from "../components/SkLogoContainer";
import processErrors from "../components/SkForms/processErrors";
import {Link} from "react-router-dom";
import ErrorBoundary from "../components/ErrorBoundary";
import {SkFormMetaContext, SkFormPropsContext} from "../components/SkForms/SkFormContexts";


const ApplyPage: React.FC = observer(() => {
  const applyPageStore = useMemo(() => {
    return new ApplyPageStore(true);
  }, []);

  const authStore = useAuth();

  const loading = applyPageStore.state === "init" || applyPageStore.state === "pending";

  const handleFormSubmit = (values: SkFormValues, formikHelpers: FormikHelpers<SkFormValues>) => {
    formikHelpers.setErrors({});
    formikHelpers.setStatus("");

    if (applyPageStore?.currentCampaign?.id) {
      const currentCampaign = applyPageStore.currentCampaign;

      const payload: ApiApplicationsPostRequest = {
        // sending empty application thus this is creation
        answers: AnswersFromFormValues(values, ""),
        campaign: currentCampaign.id,
        utms: authStore.utmStore.hasUTM ? authStore.utmStore.UTMs : undefined,
      };

      ApiApplicationsPost(payload).then(response => {

        const apiApplicationsPostResponse = response.data;
        authStore.processToken(apiApplicationsPostResponse.auth_token);
      }).catch((e) => {
        const [noneFieldError, fieldErrors] = processErrors(e, values, currentCampaign.reg_form.questions, "An error occurred during applying");
        formikHelpers.setErrors(fieldErrors);
        formikHelpers.setStatus(noneFieldError);
        formikHelpers.setSubmitting(false);
      });
    } else {
      formikHelpers.setSubmitting(false);
    }
  };

  return <ErrorBoundary errorLevel={"ApplyPage"}>
    <WithDefaults chatra={false}>
      <SkLogoContainer className={"mb-5 ml-auto mr-auto"}>
        <LogoSquareWhite/>
      </SkLogoContainer>
      <Container>
        <Row>
          <Col>
            <Loading loading={loading}>
              <SkLogoContainer className={"mb-5 ml-auto mr-auto"}>
                {applyPageStore.state === "empty" ? (
                  <>
                    <Card>
                      <Card.Body>
                        <h1>
                          Sorry 😞
                        </h1>
                        <p>No active campaigns at the moment.</p>
                      </Card.Body>
                    </Card>
                  </>
                ) : (
                  <ErrorDisplay error={applyPageStore.error} errorLogger={applyPageStore.log.error}>
                    <h1>
                      Application Form
                    </h1>
                    <Alert variant={"secondary"}>
                      Already applied? <Link to={"/login"}>Sign in</Link>.
                    </Alert>
                    {applyPageStore.currentCampaign?.reg_form?.questions ? (
                      <SkFormPropsContext.Provider value={{
                        questions: applyPageStore.currentCampaign.reg_form.questions,
                        questionsDisabled: [],
                        reactiveInitialValues: {}
                      }}>
                        <SkFormMetaContext.Provider value={{
                          form: applyPageStore.currentCampaign?.reg_form.id,
                          application: ""
                        }}>
                          <SkForm
                            className={"mb-5"}
                            onSubmit={handleFormSubmit}
                            submitCaption={"Continue"}
                            accepts={[
                              <>I have read and agree to the <a href="https://box.skoltech.ru/index.php/s/MbMbNWqLT3ZrsBM" target={"_blank"} rel={"noreferrer"}>Terms</a></>
                            ]}
                          />
                        </SkFormMetaContext.Provider>
                      </SkFormPropsContext.Provider>
                    ) : null}
                  </ErrorDisplay>
                )}
              </SkLogoContainer>
            </Loading>
            <SkLogoContainer className={"mb-5 ml-auto mr-auto"}>
              <div className="footer"><a className="small" href="https://box.skoltech.ru/index.php/s/dI9uoKolWX9McDE">Skoltech Selection Regulations</a></div>
            </SkLogoContainer>
          </Col>
        </Row>
      </Container>
    </WithDefaults>
  </ErrorBoundary>;

});

export default ApplyPage;
