import React, {useEffect, useMemo} from "react";
import {Alert, Col, Container, Row} from "react-bootstrap";
import BackAppStore from "../../stores/backoffice/pages/BackAppStore";
import {useAuth} from "../../stores/AuthStore";
import {observer} from "mobx-react-lite";
import {Redirect, Switch, useRouteMatch} from "react-router-dom";
import BackofficeRoute from "../../components/routes/BackofficeRoute";
import BackApplicationsPage from "./BackApplicationsPage";
import {runInAction} from "mobx";
import CampaignsSelector from "../../components/backoffice/CampaignsSelector";
import Navigation from "../../components/backoffice/cp/Navigation";
import WithDefaults from "../withs/WithDefaults";
import BackReportsPage from "./BackReportsPage";
import Loading from "../Loading";
import {useAppName, useCPYear} from "../../helpers/utils";
import ErrorBoundary from "../../components/ErrorBoundary";
import BackSettingsPage from "./BackSettingsPage";

export const BackAppStoreContext = React.createContext<BackAppStore>({} as BackAppStore);

const BackApp: React.FC = observer(() => {
  const cpy = useCPYear();
  const appName = useAppName();

  const match = useRouteMatch<{id?: string, eduId?: string}>("/admin/:id?/:eduId?/:page?");
  const backAppStore = useMemo(() => new BackAppStore(), []);

  //set campaignId and educationTypeId every time route changed
  useEffect(() => {
    runInAction(() => {
      backAppStore.campaignId = match?.params?.id;
      backAppStore.educationTypeId = match?.params?.eduId;
    });
  }, [match?.params?.id, match?.params?.eduId]);

  const authStore = useAuth();

  return <ErrorBoundary errorLevel={"BackApp"}>
    <div className={"sk-page"}>
      <WithDefaults chatra={false} gtm={false}>
        <BackAppStoreContext.Provider value={backAppStore}>
          <Loading loading={backAppStore.state === "pending"}>
            {backAppStore.campaignId && backAppStore.educationTypeId ? <>
              <Navigation/>
              <Switch>
                <BackofficeRoute path="/admin/:id/:eduId/applicants">
                  <BackApplicationsPage backAppStore={backAppStore}/>
                </BackofficeRoute>
                <BackofficeRoute path="/admin/:id/:eduId/reports">
                  <BackReportsPage backAppStore={backAppStore}/>
                </BackofficeRoute>
                <BackofficeRoute path="/admin/:id/:eduId/settings">
                  <BackSettingsPage backAppStore={backAppStore}/>
                </BackofficeRoute>
                <BackofficeRoute path="*">
                  <Redirect to={`/admin/${backAppStore.campaignId}/${backAppStore.educationTypeId}/applicants`} />
                </BackofficeRoute>
              </Switch>
            </> : <ErrorBoundary errorLevel={"BackApp-campaignSelectorBlock"}>
              <Navigation/>
              <Container>
                <Col>
                  <Row>
                    <div className={"d-flex justify-content-center align-items-center"} style={{minHeight: "60vh", width: "100%"}}>
                      <div className={"d-flex flex-column"}>
                        <h2 className={"mb-4"}>Please, select campaign</h2>
                        {backAppStore.state === "error" && <Alert variant={"danger"} dismissible={true} onClose={() => runInAction(() => backAppStore.state = "ok")}>
                          <p>Can't find campaign.</p>
                          <p className={"mb-0"}>Please, select the correct one from the list below.</p>
                        </Alert>}
                        <CampaignsSelector campaigns={authStore.user?.campaigns}/>
                      </div>
                    </div>
                  </Row>
                </Col>
              </Container>
            </ErrorBoundary>}
          </Loading>
        </BackAppStoreContext.Provider>
      </WithDefaults>
      <div className="sk-footer">
        <div className={"text-muted"}>
          {appName}, {cpy}
        </div>
      </div>
    </div>
  </ErrorBoundary>;
});

export default BackApp;