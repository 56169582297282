import React, {useMemo} from "react";
import {Form} from "react-bootstrap";
import PhoneInput from "react-phone-input-2";
import useSkFieldValidInvalid from "../useSkFieldValidInvalid";
import isSkFieldSubmitted from "./IsSkFieldSubmitted";
import SkFieldProps from "./SkFieldProps";
import SkFieldDescriptionBlock from "./SkFieldDescriptionBlock";
import SkFieldLabel from "./SkFieldLabel";
import cx from "classnames";

const SkFieldPhone:React.FC<SkFieldProps> = (props) => {
  const {isInvalid, setReallyTouched} = useSkFieldValidInvalid(props);
  const value = useMemo(() => props.formikProps.values[props.name] || "", [props.formikProps.values, props.name]);

  //eslint-disable-next-line react-hooks/exhaustive-deps
  const isValid = useMemo(() => isSkFieldSubmitted(props), [props.submittedValue, props.formikProps.values, props.formikProps.values[props.name], props.name]);

  return (
    <Form.Group controlId={props.name}>
      <SkFieldLabel {...props}/>

      <PhoneInput
        inputProps={{
          name: props.name
        }}
        inputClass={cx({
          "is-invalid": isInvalid,
          "is-valid": isValid,
        })}
        country={"ru"}
        value={value}
        onChange={(value, country, e, formattedValue) => props.formikProps.setFieldValue(props.name, formattedValue)}
        disabled={props.disabled || props.formikProps.isSubmitting}
        onBlur={() => setReallyTouched(true)}
      />

      <SkFieldDescriptionBlock
        description={props.description}
        isInvalid={isInvalid}
        errorText={`${props.formikProps.errors[props.name]}`}
      />
    </Form.Group>
  );
};

export default SkFieldPhone;
